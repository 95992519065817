//react
import { Dispatch, SetStateAction } from "react";

const closePopup = (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  className: string,
  setIsShow: Dispatch<SetStateAction<boolean>> | undefined
) => {
  const target = e.target as HTMLElement;
  if (target.className === className) {
    setIsShow?.(false);
  }
};

export default closePopup;
