//react
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface DefaultFilterType {
  filterFormData: {
    purpose: string;
    region: string[];
    houseType: string[];
    price: string[];
    layout: string[];
    propertyAge: string[];
  };
  searchKey: number;
  postCotent: string;
  selectedHashtag: string;
  cleanData: boolean;
  setPurpose?: Dispatch<SetStateAction<string>>;
  setRegion?: Dispatch<SetStateAction<string[]>>;
  setHouseType?: Dispatch<SetStateAction<string[]>>;
  setPrice?: Dispatch<SetStateAction<string[]>>;
  setLayout?: Dispatch<SetStateAction<string[]>>;
  setPropertyAge?: Dispatch<SetStateAction<string[]>>;
  setSearchKey?: Dispatch<SetStateAction<number>>;
  setPostContent?: Dispatch<SetStateAction<string>>;
  setSelectedHashTag?: Dispatch<SetStateAction<string>>;
  setCleanData?: Dispatch<SetStateAction<boolean>>;
}

const defaultFilterContext: DefaultFilterType = {
  filterFormData: {
    purpose: "",
    region: [""],
    houseType: [""],
    price: [""],
    layout: [""],
    propertyAge: [""],
  },
  searchKey: 0,
  postCotent: "",
  selectedHashtag: "",
  cleanData: false,
};

const FilterContext = createContext<DefaultFilterType>(defaultFilterContext);
export const useFilter = () => useContext(FilterContext);

interface FilterProviderProps {
  children: ReactNode; // This is the type for children
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [purpose, setPurpose] = useState("sell");
  const [region, setRegion] = useState([""]);
  const [houseType, setHouseType] = useState([""]);
  const [price, setPrice] = useState([""]);
  const [layout, setLayout] = useState([""]);
  const [propertyAge, setPropertyAge] = useState([""]);
  const [searchKey, setSearchKey] = useState(0);
  const [postCotent, setPostContent] = useState("All");
  const [selectedHashtag, setSelectedHashTag] = useState("");
  const [cleanData, setCleanData] = useState(false);

  const filterFormData = {
    purpose,
    region,
    houseType,
    price,
    layout,
    propertyAge,
  };

  return (
    <FilterContext.Provider
      value={{
        filterFormData,
        searchKey,
        postCotent,
        selectedHashtag,
        cleanData,
        setPurpose,
        setRegion,
        setHouseType,
        setPrice,
        setLayout,
        setPropertyAge,
        setSearchKey,
        setPostContent,
        setSelectedHashTag,
        setCleanData,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
