import { useEffect } from "react";

const usePreventScrolling = () => {
  useEffect(() => {
    document.body.classList.add("no-scroll");

    // Clean up the effect when the component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);
};

export default usePreventScrolling;
