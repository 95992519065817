export const fqaData = [
  {
    question: "1. 我無法登入，該如何處理？",
    answer: "您可以聯繫我們的平台，或是透過下方電話與LINE聯繫客服人員。",
  },
  {
    question: "2. 已經登入過，突然無法再次登入？",
    answer:
      "請先確認網路連線是否正常，重新整理後如果還是無法正常登入使用，您可以聯繫我們的平台，或是透過下方電話與LINE聯繫客服人員進行協助。",
  },
  {
    question: "3.請問FB與LINE登入，使用上兩邊是否會有衝突或是問題？",
    answer:
      "本平台一次僅可登入一個帳號，因此不同的登入方式可歸類於不同使用者進行使用。",
  },
  {
    question: "3.請問FB與LINE登入，使用上兩邊是否會有衝突或是問題？",
    answer:
      "本平台一次僅可登入一個帳號，因此不同的登入方式可歸類於不同使用者進行使用。【如果使用FB登入，則為使用者A；如果使用LINE登入，則為使用者B，A與B使用者資料都是分開且獨立的互不干涉】",
  },
  {
    question: "4.請問上傳圖片與影片是否有容量或限制？",
    answer:
      "平台提供MP4、MOV兩種影片格式上傳，照片提供JEPG、PNG上傳；請注意同一篇文章內影片與圖片尺寸需一致方可上傳。上傳時間需控制在120秒內、容量500MB內方可完成發送或是串接。",
  },
  {
    question: "5. 請問為何我的帳號突然被封鎖無法使用了？",
    answer: "請聯繫官方客服進行確認與解鎖。",
  },
  {
    question: "6. 我如何開始購買或承租有興趣的案件？",
    answer:
      "首先，您需要確定您的預算和地點。然後，您可以在我們的平台上經過篩選搜索符合您需求的產品物件，進一步獲取物件資訊及聯繫方式。",
  },
  {
    question: "7. 我可以如何查看房產套件或是預約諮詢？",
    answer: "您可以通過我們的平台訊息預約看房，將有專人為您服務。",
  },
  {
    question: "8. 租約的最短租期是多久?",
    answer:
      "最短租期通常為六個月或一年，具體取決於房東的要求。在簽署租約前，請務必確認租期條款。",
  },
  {
    question: "9. 如果我想在平台上出售或出租房產，應該怎麼做？",
    answer:
      "您可以參考會員方案，選購後即可進行刊登；或聯繫我們的平台，將有專人為您提供服務。",
  },
  {
    question: "10. 如果功能使用上有異常，我該如何處理？",
    answer:
      "請先確認版本是否更新到最新版，關閉後重新開啟；如果還是無法正常使用，請透過最下方電話與LINE聯繫客服人員即可。",
  },
];
