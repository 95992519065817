import axios from "axios";
import { baseUrl } from "./axiosInstance";

// api - third-party login
export const thirdPartyLogin = async (
  accessToken: string,
  type: number,
  isAppleWebResponse: boolean
) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${baseUrl}/app/member/create-member?accessToken=${accessToken}&type=${type}&isAppleWebResponse=${isAppleWebResponse}`
    );

    return data;
  } catch (error) {
    return { success: false, error };
  }
};

// api to know if a user is banned from entry
export const getCheckUserStatus = async (memberId: string) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/app/member/check-member-status?memberId=${memberId}`
    );

    return data.data;
  } catch (error) {
    return error;
  }
};
