//type
import { TypeBlockedUser } from "../../types/user";
import { useNavigate } from "react-router-dom";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
// context
import { useAuth } from "../../context/AuthContext";

interface BlockListPopupModelProps {
  setIsShow: (show: boolean) => void;
  listOfBlock: TypeBlockedUser[];
}

const BlockListPopupModel: React.FC<BlockListPopupModelProps> = (props) => {
  const navigate = useNavigate();

  // context - memberId
  const { currentMember } = useAuth();

  return (
    <div className="follower-popup-modal">
      <div className="follower-popup-container">
        <div className="close-btn" onClick={() => props.setIsShow(false)}>
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="popup-title sb-24">{`封鎖 (${props?.listOfBlock?.length})`}</div>
        <div className="follower-list-container">
          {props?.listOfBlock?.map((user) => {
            return (
              <div
                key={user?.blockedMemberId}
                className="followr-card"
                onClick={() => {
                  if (
                    user?.blockedMemberId !== currentMember?.currentMemberId
                  ) {
                    navigate(
                      `/userinfo/${user?.blockedMemberId?.substring(0, 16)}`,
                      {
                        state: { userId: user?.blockedMemberId },
                      }
                    );
                  }

                  props.setIsShow(false);
                }}
              >
                <div className="follower-img">
                  <img src={user?.blockedMemberAvatar} alt="follower-img" />
                </div>
                <div>
                  <span className="sb-14">{user?.blockedMemberName}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlockListPopupModel;
