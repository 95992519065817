//components
import Notification from "../Notification/Notification";
//context
import { useAuth } from "../../context/AuthContext";
//style
import "./TitleHeader.scss";

interface TitleHeaderProps {
  title: string;
}

const TitleHeader: React.FC<TitleHeaderProps> = (props) => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="title-header-container">
      <div className="sb-24">{props.title}</div>
      {isAuthenticated && <Notification />}
    </div>
  );
};

export default TitleHeader;
