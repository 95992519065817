//react
import { useState, useEffect } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./BackToTopArrow.scss";

const BackToTopArrow: React.FC = () => {
  const [showButton, setShowButton] = useState(false);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!showButton) {
    return null;
  }

  return (
    <div className="back-to-top-arrow">
      <div
        className="rush-to-top-btn"
        onClick={scrollToTop}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover ? (
          <img src={TemplateCardImg.srcScroll_down_icon} alt="rush-down" />
        ) : (
          <img
            src={TemplateCardImg.srcScroll_down_opacity_icon}
            alt="rush-down"
          />
        )}
      </div>
    </div>
  );
};

export default BackToTopArrow;
