//react
import { KeyboardEvent, useState } from "react";
import { useQueryClient } from "react-query";
//context
import { useComment } from "../../context/CommentProvider";
//api
import { postComment } from "../../api/posts";
import { useAuth } from "../../context/AuthContext";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./CommentInput.scss";

interface CommentInputProps {
  postId: string;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent<Element>) => void;
}

const CommentInput: React.FC<CommentInputProps> = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isIME, setIsIME] = useState(false); //監測輸入法行為
  const [isSending, setIsSending] = useState(false); // prevent multiple sendings

  //context
  const { tagUser, replyToCmntId, setTagUser, inputRef, setReplyToCmntId } =
    useComment();
  // useQuery
  const queryClient = useQueryClient();

  // context - memberId
  const { currentMember, isAuthenticated, setShowAuth } = useAuth();

  const handleCreateComment = async () => {
    if (props?.value) {
      setIsSending(true); // disable button
      inputRef?.current?.blur(); // Blur the textarea

      try {
        const res = await postComment({
          ParentId: replyToCmntId, // id of comment that user is replying to(dont get perplexed by the name of parameter)
          MemberId: currentMember?.currentMemberId, // current user
          PostId: props?.postId, // id of this post
          Content: props?.value,
        });

        if (res) {
          setIsFocused(false);
          props.onChange("");
          setIsSending(false); // re-enable button
          setTagUser?.("");
          setReplyToCmntId?.("");
          queryClient.invalidateQueries("/comments");
          queryClient.invalidateQueries("postContent");
        }
      } catch (error) {
        return error;
      }
      // finally {
      //   setIsSending(false); // re-enable button
      // }
    }
  };

  const handleRemoveFocus = () => {
    setIsFocused(false);
    props.onChange("");
    setTagUser?.("");
    setReplyToCmntId?.("");
  };

  return (
    <div
      className={`comment-input-box ${isFocused && "state-focused"} ${
        isFocused && tagUser && "state-focused-height"
      }`}
    >
      {isFocused && tagUser && (
        <div className="tag-user sb-14">{`回覆 @${tagUser}`}</div>
      )}
      <textarea
        ref={inputRef}
        className={`comment-input r-16 ${isFocused && "comment-input-focused"}`}
        value={props.value}
        placeholder="留言..."
        onChange={(e) => props.onChange(e.target.value)}
        onCompositionStart={() => setIsIME(true)}
        onCompositionEnd={() => setIsIME(false)}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === "Enter" && !isIME) {
            e.preventDefault();
            if (props.value.trim() !== "") {
              !isSending && handleCreateComment();
            }
          }
        }}
        onFocus={(e) => {
          isAuthenticated ? setIsFocused(true) : setShowAuth?.(true);
        }}
      />
      {isFocused && (
        <div className="comment-close-btn" onClick={handleRemoveFocus}>
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
      )}
      {isFocused && (
        <div
          className={`comment-send-btn ${
            !props.value || isSending ? "disable-comment" : ""
          }`}
          onClick={(event) => {
            !isSending && handleCreateComment();
          }}
        >
          <img src={TemplateCardImg.srcDeliver_icon} alt="deliver-icon" />
        </div>
      )}
    </div>
  );
};

export default CommentInput;
