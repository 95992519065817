//react
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "react-query";
//conponents
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import ChatListCard from "../../components/ChatListCard/ChatListCard";
import ChatRoomMessages from "../../components/ChatRoomMessages/ChatRoomMessages";
//api
import { getChatRoomList } from "../../api/chat";
import { postMsgIsRead } from "../../api/chat";
//signalR
import { SignalRService } from "../../signalR/SignalRService";
//context
import { useChat } from "../../context/ChatProvider";
import { useNotifi } from "../../context/NotifiProvider";
import { useAuth } from "../../context/AuthContext";
//type
import { TypeChatRoom } from "../../types/chats";
import { TypeMessage } from "../../types/chats";
//img
import { TemplateOtherImg } from "../../assets/data/imagesData";
//style
import "./ChatListPage.scss";

const ChatListPage = () => {
  const [roomList, setRoomList] = useState<TypeChatRoom[]>([]);
  const [connection, setConnection] = useState<SignalRService | null>(null);
  const location = useLocation();
  const queryClient = useQueryClient();

  //context
  const {
    setSelectedRoomId,
    selectedRoomId,
    fetchAgain,
    isReconnected,
    setFetchAgain,
    setReceiverId,
    setAllFiles,
    setChat,
    setMessages,
    setStopScrolling,
    setIsReconnected,
  } = useChat();

  // context - memberId
  const { currentMember } = useAuth();

  // mark message as read message
  const handleMsgIsRead = async () => {
    if (selectedRoomId && currentMember?.currentMemberId) {
      try {
        await postMsgIsRead({
          chatroomId: selectedRoomId,
          memberId: currentMember?.currentMemberId, // current user
        });
      } catch (error) {
        return error;
      }
    }
  };

  useEffect(() => {
    const getRoomListAsync = async () => {
      try {
        const list = await getChatRoomList(
          currentMember?.currentMemberId //current user id
        );
        setRoomList([...list]);
        //console.log("refetch chatroom list");
      } catch (error) {
        return error;
      }
    };
    getRoomListAsync();
  }, [fetchAgain, currentMember?.currentMemberId]);

  // check the connection every 5 seconds
  useEffect(() => {
    const reconnect = async () => {
      if (!connection?.isConnected()) {
        console.log("connection is lost!");

        // refetch api when loosing connection
        setFetchAgain?.(!fetchAgain);
        if (selectedRoomId) {
          setStopScrolling?.(true);
          setMessages?.([]);
          queryClient.invalidateQueries("/messages");
          handleMsgIsRead();
        }
      } else {
        console.log("connection is still active");
      }
    };

    // Set up an interval to call the function every 5 seconds
    const interval = setInterval(reconnect, 5000);

    // Clean up the interval when the component is unmounted or dependencies change
    return () => clearInterval(interval);
  }, [connection, selectedRoomId]);

  // retch chat data as reconnected
  useEffect(() => {
    setFetchAgain?.(!fetchAgain);
    setStopScrolling?.(true);
    setMessages?.([]);
    queryClient.invalidateQueries("/messages");
    handleMsgIsRead();
  }, [isReconnected]);

  // Notification
  const {
    notifiUsed,
    chatroom,
    opponent,
    setNotifiUsed,
    setChatroom,
    setOpponent,
  } = useNotifi();

  useEffect(() => {
    if (notifiUsed) {
      setSelectedRoomId?.(chatroom);
      setReceiverId?.(opponent);
      setNotifiUsed?.(false);
    }

    return () => {
      setNotifiUsed?.(false);
      setChatroom?.(""); // 應該可以不需要
      setOpponent?.(""); // 應該可以不需要
    };
  }, [notifiUsed]);

  // Clean up data when component unmounts
  useEffect(() => {
    return () => {
      setSelectedRoomId?.(""); // Clean up selectedRoomId
      setReceiverId?.(""); // Clean up receiverId
      setChat?.(""); // Clean up input of replying
      setAllFiles?.([]); // Clean up uploaded files
      setStopScrolling?.(false);
    };
  }, []);

  useEffect(() => {
    // set SelectedRoomId if user enter by clicking message button on other user's profile page
    // if (selectedRoomId === "" && location?.state?.chatroomIds) {
    //   setSelectedRoomId?.(location?.state?.chatroomIds?.chatroomId);
    // }
    // if (receiverId === "" && location?.state?.chatroomIds) {
    //   setReceiverId?.(location?.state?.chatroomIds?.receiverId);
    // }

    if (location?.state?.chatroomIds) {
      setSelectedRoomId?.(location?.state?.chatroomIds?.chatroomId);
      setReceiverId?.(location?.state?.chatroomIds?.receiverId);
    }
  }, []);

  // sate to control refetch data as reconnecting
  const isReconnectedToFetch = () => {
    setIsReconnected?.(!isReconnected);
  };

  // Setup SignalR service
  useEffect(() => {
    const signalRService = new SignalRService(
      "https://api.highlight11.com/chathub",
      currentMember?.currentMemberId,
      isReconnectedToFetch
    );
    setConnection(signalRService);

    // Clean up the connection when the component unmounts
    return () => {
      signalRService.stopConnection();
    };
  }, [currentMember?.currentMemberId]);

  // Start connection with ID
  useEffect(() => {
    if (connection && currentMember?.currentMemberId) {
      const connectWithId = async () => {
        try {
          const hasConnected = await connection.startConnection();

          if (hasConnected) {
            await connection.receiveConnectionIds();

            await connection.startConnectionWithId();
          }
        } catch (error) {
          return error;
        }
      };

      connectWithId();
    }
  }, [connection, currentMember?.currentMemberId]);

  // Receive opponent's Message to update chatroom list before enter a room
  const getOpponentMessage = async () => {
    try {
      if (!selectedRoomId) {
        const newMessage = (await connection?.receiveMessage()) as TypeMessage;

        if (newMessage) {
          setFetchAgain?.(!fetchAgain);
        }
      }
    } catch (error) {
      return error;
    }
  };

  getOpponentMessage();

  // Receive message sent by current user to update chatroom list (in a senario where current user sends message in mobile version of app with same account)
  const getSelfMessage = async () => {
    try {
      if (!selectedRoomId) {
        const newMessage =
          (await connection?.receiveSelfMessage()) as TypeMessage;

        if (newMessage) {
          setFetchAgain?.(!fetchAgain);
        }
      }
    } catch (error) {
      return error;
    }
  };

  getSelfMessage();

  // refetch chatroom list when user deletes or recalls message
  const refetchChatRoomList = async () => {
    try {
      const toUpdate = await connection?.UpdateChatroomListOn();

      if (toUpdate) {
        setFetchAgain?.(!fetchAgain);
      }
    } catch (error) {
      return error;
    }
  };

  refetchChatRoomList();

  // 從signalR獲取有被聯絡中的使用者封鎖
  const getBlockedRoomId = async () => {
    try {
      const id = await connection?.getBlockedChatroomIdOn(); // 被封鎖的聊天室id

      const isblocked = roomList?.some((room) => room?.chatroomId === id);

      //如果是當前清單中有封鎖聊過室id就refetch清單
      if (isblocked) {
        setFetchAgain?.(!fetchAgain);
      }

      //如果是當前在封鎖聊天室聊天
      if (selectedRoomId === id) {
        setSelectedRoomId?.(""); // Clean up selectedRoomId
        setReceiverId?.(""); // Clean up receiverId
        setChat?.(""); // Clean up input of replying
        setMessages?.([]); // Clean up realtime messages stored
        setAllFiles?.([]); // Clean up uploaded files in the current room
        setStopScrolling?.(false); // enable to trigger mechanism of stopping at the first latest message as entering a chatroom
      }
    } catch (error) {
      return error;
    }
  };

  getBlockedRoomId();

  return (
    <>
      <TitleHeader title="訊息列表" />
      <div className="chatlist-main-container main-body-spacing">
        <div className="chat-main-sec">
          <div className="shared-link-reminder r-14">
            * 提醒：站內文章請善用"分享功能"，其餘可透過網址進行外部連線
          </div>
          <div className="disconnection-reminder r-14">
            * 如閒置超過15分鐘，請手動刷新頁面，謝謝
          </div>
          <div className="chatlist-group">
            {roomList?.map(
              (room) =>
                room?.oppositeUserId !==
                  "fb7d07d7-2125-ee48-e161-3a12b8c34640" && (
                  <ChatListCard key={room?.chatroomId} room={room} />
                )
            )}
          </div>
          <div className="chatbox-sec">
            {selectedRoomId ? (
              <>
                <ChatRoomMessages
                  connection={connection}
                  handleMsgIsRead={handleMsgIsRead}
                />
              </>
            ) : (
              <div className="unselected-box">
                <div className="unselected-state">
                  <img
                    src={TemplateOtherImg.srcChatroom_icon}
                    alt="chatroom-icon"
                  />
                  <div className="sb-24">未選擇任何聊天室</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatListPage;
