//react
import { useEffect } from "react";
//context
import { useNotifi } from "../../context/NotifiProvider";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./SystemAnnouncePopup.scss";

interface SystemAnnouncePopupPorps {
  systemTitle: string;
  systemContent: string;
}

const SystemAnnouncePopup: React.FC<SystemAnnouncePopupPorps> = (props) => {
  const { setSystemShow, setPostError } = useNotifi();

  return (
    <div className="system-announce-popup">
      <div className="system-announce-box">
        <div
          className="close-btn"
          onClick={() => {
            setSystemShow?.(false);
            setPostError?.(false);
          }}
        >
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="announce-title sb-24">{props?.systemTitle}</div>
        <div className="announce-body r-16">{props?.systemContent}</div>
        <button
          className="primary-btn"
          onClick={() => {
            setSystemShow?.(false);
            setPostError?.(false);
          }}
        >
          關閉
        </button>
      </div>
    </div>
  );
};

export default SystemAnnouncePopup;
