//react
import { useRef, useCallback, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
//components
import ChatBox from "../ChatBox/ChatBox";
//signalR
import { SignalRService } from "../../signalR/SignalRService";
//context
import { useChat } from "../../context/ChatProvider";
import { useAuth } from "../../context/AuthContext";
//api
import { getChatRoomMsg } from "../../api/chat";
//type
import { TypeMessage } from "../../types/chats";

interface ChatRoomMessagesProps {
  connection: SignalRService | null;
  handleMsgIsRead: () => void;
}

const ChatRoomMessages: React.FC<ChatRoomMessagesProps> = (props) => {
  //context
  const { selectedRoomId } = useChat();
  // memberId
  const { currentMember } = useAuth();

  const messageRef = useRef<HTMLDivElement>(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  // Implement infinite scroll with react query
  const {
    fetchNextPage, //function
    hasNextPage, // boolean
    isFetchingNextPage, // boolean
    data, // response from api
    status,
    error,
  } = useInfiniteQuery<TypeMessage[], Error>(
    ["/messages", selectedRoomId],
    ({ pageParam = 0 }) =>
      getChatRoomMsg({
        chatroomId: selectedRoomId,
        currentMemberId: currentMember?.currentMemberId,
        skipCount: pageParam === 0 ? pageParam : (pageParam - 1) * 15,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.length ? allPages.length + 1 : undefined;
      },
      //refetchOnWindowFocus: true, // Refetch on window focus
      refetchOnWindowFocus: false, // Prevents refetching when the window regains focus
      refetchOnReconnect: false, // Prevents refetching when reconnecting to the network
    }
  );

  // const intObserver = useRef<IntersectionObserver | null>(null);
  // const firstPostRef = useCallback(
  //   (node: Element | null) => {
  //     if (isFetchingNextPage) return;

  //     if (intObserver.current) intObserver.current.disconnect();

  //     intObserver.current = new IntersectionObserver((messages) => {
  //       if (messages[0].isIntersecting && hasNextPage) {
  //         fetchNextPage();
  //       }
  //     });

  //     if (node) intObserver.current.observe(node);
  //   },
  //   [isFetchingNextPage, fetchNextPage, hasNextPage]
  // );

  // useEffect(() => {
  //   return () => {
  //     if (intObserver.current) {
  //       intObserver.current.disconnect();
  //     }
  //   };
  // }, []);

  const handleScroll = useCallback(() => {
    const msgCur = messageRef.current;
    if (!msgCur) return;

    const scrollTop = msgCur.scrollTop;
    const scrollHeight = msgCur.scrollHeight;
    const clientHeight = msgCur.clientHeight;

    if (scrollTop === 0 && hasNextPage && !isFetchingMore) {
      setIsFetchingMore(true);
      fetchNextPage().finally(() => setIsFetchingMore(false));
    }
  }, [fetchNextPage, hasNextPage, isFetchingMore]);

  useEffect(() => {
    const msgCur = messageRef.current;

    msgCur?.addEventListener("scroll", handleScroll);

    return () => {
      msgCur?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  // To know error
  if (status === "error" && error) {
    //console.log(error.message);
  }

  // make sure viwepoint stop at the first message of previous page when fetching next page
  useEffect(() => {
    const prevTop = data?.pages[data?.pages?.length - 2]?.[0]?.id;

    const element = prevTop && document.getElementById(prevTop);
    if (element) {
      element.scrollIntoView({ block: "start" });
    }
  }, [data?.pages]);

  return (
    <>
      <ChatBox
        connection={props?.connection}
        ref={messageRef}
        data={data}
        handleMsgIsRead={props?.handleMsgIsRead}
      />
    </>
  );
};

export default ChatRoomMessages;
