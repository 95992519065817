// react
import { Dispatch, SetStateAction, KeyboardEvent } from "react";
//components
import Input from "../Input/Input";
import SearchFilter from "../SearchFilter/SearchFilter";
import Notification from "../Notification/Notification";
//context
import { useFilter } from "../../context/HomeFilterContext";
import { useAuth } from "../../context/AuthContext";
//img
import { TemplateOtherImg } from "../../assets/data/imagesData";
//style
import "./MainHeader.scss";

interface MainHeaderProps {
  keyWord: string;
  setKeyword: Dispatch<SetStateAction<string>>;
}

const MainHeader: React.FC<MainHeaderProps> = (props) => {
  const { setSearchKey, setPostContent } = useFilter();

  const { isAuthenticated } = useAuth();

  // Number here doesnt really has a purpose but triggering rerender(<FilterPosts />) by increment to change state of searchKey
  const handleSearch = () => {
    setSearchKey?.((prevKey: number) => prevKey + 1);
    setPostContent?.("Search");
  };

  return (
    <div className="header-primary-container">
      <div className="search-bar">
        <Input
          type="text"
          name="keyword"
          value={props?.keyWord}
          placeholder="搜尋"
          onChange={(keywordInputValue) => props?.setKeyword(keywordInputValue)}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === "Enter") {
              setSearchKey?.((prevKey: number) => prevKey + 1);
              setPostContent?.("Search");
            }
          }}
        />
        <button className="search-btn" onClick={handleSearch}>
          <img src={TemplateOtherImg.srcSearch_icon} alt="search-icon" />
        </button>
      </div>
      <div className="right-action-container">
        <div className="search-filter">
          <SearchFilter />
        </div>
        {isAuthenticated && (
          <div className="notification">
            <Notification />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainHeader;
