//react
import { useState, useRef, useEffect, SetStateAction, Dispatch } from "react";
import { useNavigate } from "react-router-dom";
//components
import BlockUserPopup from "../BlockUserPopup/BlockUserPopup";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
//context
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//api
import {
  postBlockUser,
  postUnblockUser,
  getIsblokedUser,
  postIsFollowing,
  postFollowOrUnfollow,
} from "../../api/users";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./PopoverMenu.scss";

interface PopoverMenuProps {
  idToBlock: string;
  setIsBlocked: Dispatch<SetStateAction<boolean>>;
  isBlocked: boolean;
  setBeingBlocked: Dispatch<SetStateAction<boolean>>;
}

const PopoverMenu: React.FC<PopoverMenuProps> = (props) => {
  const [show, setShow] = useState(false); // menu
  const [popupShow, setPopupShow] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();

  const { currentMember } = useAuth();
  const { refetchPost, setRefetchPost } = usePost();

  const handleBlockUser = async () => {
    try {
      const res = await postBlockUser({
        currentMemberId: currentMember?.currentMemberId,
        blockMemberId: props?.idToBlock,
      });

      if (res?.success === true) {
        const isFollowing = await postIsFollowing({
          FollowerId: currentMember?.currentMemberId,
          FollowedId: props?.idToBlock, //要追蹤對象
        });

        const isFollowed = await postIsFollowing({
          FollowerId: props?.idToBlock,
          FollowedId: currentMember?.currentMemberId, //要追蹤對象
        });

        isFollowing &&
          (await postFollowOrUnfollow({
            FollowerId: currentMember?.currentMemberId,
            FollowedId: props?.idToBlock, //要追蹤對象
          }));

        isFollowed &&
          (await postFollowOrUnfollow({
            FollowerId: props?.idToBlock,
            FollowedId: currentMember?.currentMemberId, //要追蹤對象
          }));

        navigate("/");
      }
    } catch (error) {
      return error;
    }
  };

  const handleUnblockUser = async () => {
    try {
      const res = await postUnblockUser({
        currentMemberId: currentMember?.currentMemberId,
        blockedMemberId: props?.idToBlock,
      });

      if (res) {
        setRefetch(!refetch);
        setShow(false);
        setRefetchPost?.(!refetchPost);
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  //check if user of profile is blocked or not
  useEffect(() => {
    const getIsBlockedUserAsync = async () => {
      try {
        const isBlocked = await getIsblokedUser({
          currentMemberId: currentMember?.currentMemberId,
          opponentMemberId: props?.idToBlock,
        });

        const beingBlocked = await getIsblokedUser({
          currentMemberId: props?.idToBlock,
          opponentMemberId: currentMember?.currentMemberId,
        });

        props?.setIsBlocked(isBlocked);
        props?.setBeingBlocked(beingBlocked);
      } catch (error) {
        return error;
      }
    };

    getIsBlockedUserAsync();
  }, [currentMember?.currentMemberId, refetch, props]);

  return (
    <>
      <div className="popover-menu-box" ref={ref}>
        <div
          className="popover-btn"
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target as HTMLElement);
          }}
        >
          <img src={TemplateCardImg?.srcDots_menu_icon} alt="" />
        </div>

        <Overlay show={show} target={target} placement="bottom" container={ref}>
          <Popover>
            <Popover.Body>
              <div className="popover-menu-main sb-14">
                <div
                  className="popover-option"
                  onClick={() =>
                    props?.isBlocked ? handleUnblockUser() : setPopupShow(true)
                  }
                >
                  <img
                    src={
                      props?.isBlocked
                        ? TemplateCardImg?.srcUnblock_icon
                        : TemplateCardImg?.srcBlock_icon
                    }
                    alt=""
                  />
                  <span>{props?.isBlocked ? "解除封鎖" : "封鎖"}</span>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
      {popupShow && (
        <BlockUserPopup
          setConfirmPopup={setPopupShow}
          onClick={handleBlockUser}
        />
      )}
    </>
  );
};

export default PopoverMenu;
