//react
import { Dispatch, SetStateAction } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";

interface BlockUserPopupProps {
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
  onClick: () => void;
}

const BlockUserPopup: React.FC<BlockUserPopupProps> = (props) => {
  return (
    <div className="assure-popup-model">
      <div className="assure-popup-main">
        <div
          className="close-btn"
          onClick={() => props?.setConfirmPopup(false)}
        >
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="popup-content">
          <div className="title sb-24">封鎖使用者</div>
          <div className="context r-16">
            <div className="emphasize-down-space" style={{ color: "red" }}>
              !!!!請注意!!!!!
            </div>
            {`點選後將會封鎖並屏蔽該使用者，與該
使用者的聊天功能將同時被封鎖，未來
也不會顯示該使用者的貼文或消息。請
謹慎評估後再進行操作，如有任何
疑問，請聯繫客服人員，謝謝。`}
          </div>
        </div>
        <div className="btn-group">
          <button
            className="primary-btn"
            onClick={() => props?.setConfirmPopup(false)}
          >
            取消
          </button>
          <button
            className="white-btn block-confirm-btn"
            onClick={() => props?.onClick()}
          >
            確定封鎖
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlockUserPopup;
