//react
import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  KeyboardEvent,
} from "react";
//api
import { postSendMessage } from "../../api/chat";
import { getIsblokedUser } from "../../api/users";
//context
import { useChat } from "../../context/ChatProvider";
import { useAuth } from "../../context/AuthContext";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//sginalR
import { SignalRService } from "../../signalR/SignalRService";
//components
import SystemPopup from "../SystemPopup/SystemPopup";
import ReplyInput from "../ReplyInput/ReplyInput";
import AssurePopupModal from "../AssurePopupModel/AssurePopupModel";
//img
import { TemplateOtherImg } from "../../assets/data/imagesData";
//style
import "./ReplyControl.scss";

interface ReplyControlProps {
  connection: SignalRService | null;
  rushDown: () => void;
}

const ReplyControl: React.FC<ReplyControlProps> = (props) => {
  const [fileWarning, setFileWarning] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isIME, setIsIME] = useState(false); //監測輸入法行為
  const [isSending, setIsSending] = useState(false); // prevent multiple sendings
  const [isBlocked, setIsBlocked] = useState(false); // 封鎖對方
  const [beingBlocked, setBeingBlocked] = useState(false); // 被對方封鎖
  const [isShow, setIsShow] = useState(false);

  //context
  const { selectedRoomId, receiverId, allFiles, setAllFiles, setChat, chat } =
    useChat();

  // memberId
  const { currentMember } = useAuth();

  // Ref to cache file URLs
  const fileUrlsRef = useRef<Map<string, string>>(new Map());

  const handleSendMsg = async () => {
    if (isBlocked || beingBlocked) {
      setIsShow(true);
    }

    if (!isBlocked && !beingBlocked) {
      const formData = new FormData();
      formData.append("ChatroomId", selectedRoomId);
      formData.append("SenderId", currentMember?.currentMemberId);
      formData.append("ReceiverId", receiverId);
      formData.append("Content", chat);
      allFiles?.forEach((file) => formData.append("Files", file));

      setIsSending(true); // disable button

      try {
        const res = await postSendMessage(formData);
        // SignalR - Sending message
        if (res) {
          setChat?.("");
          setIsFocused(false);
          setIsSending(false); // re-enable button

          // Ensure connection is established
          // while (!props?.connection?.isConnected()) {
          //   await props?.connection?.startConnectionToServer(
          //     currentMember?.currentMemberId
          //   );
          // }

          if (receiverId === "fb7d07d7-2125-ee48-e161-3a12b8c34640") {
            //isReceiverSupport needs to be set true if sending message to service of support
            await props?.connection?.sendMessage(res, receiverId, true);
            props?.rushDown();
          } else {
            //isReceiverSupport needs to be set false if sending message to user
            await props?.connection?.sendMessage(res, receiverId, false);
            props?.rushDown();
          }
        }

        fileUrlsRef.current.clear(); // Clear cache after sending
        setAllFiles?.([]);
      } catch (error) {
        return error;
      }
      // finally {
      //   setIsSending(false); // re-enable button
      // }
    }
  };

  // click to upload image - handler
  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      const target = e.target as HTMLInputElement;
      const files = target.files;
      const maxSizeBytes = 50 * 1024 * 1024; // 50MB

      if (files) {
        const uploadFiles = Array.from(files);

        // Control error if files are above 50 MB
        const existingFileSize =
          allFiles?.reduce((acc, file) => acc + file.size, 0) || 0;

        const validFilesUnderMaxSize = uploadFiles.filter((file) => {
          const newTotalSize = existingFileSize + file.size;
          return newTotalSize <= maxSizeBytes;
        });

        if (validFilesUnderMaxSize.length !== uploadFiles.length) {
          setFileWarning(true);
        }

        // Cache URLs for valid files
        validFilesUnderMaxSize.forEach((file) => {
          if (!fileUrlsRef.current.has(file.name)) {
            fileUrlsRef.current.set(file.name, URL.createObjectURL(file));
          }
        });

        // Push files into state to store images
        setAllFiles?.([...allFiles, ...validFilesUnderMaxSize]);
      }

      // clean value to enable same files to be uploaded again
      target.value = "";
    },
    [allFiles, setAllFiles]
  );

  useEffect(() => {
    const getIsBlockedUserAsync = async () => {
      try {
        const isBlocked = await getIsblokedUser({
          currentMemberId: currentMember?.currentMemberId,
          opponentMemberId: receiverId,
        });

        const beingBlocked = await getIsblokedUser({
          currentMemberId: receiverId,
          opponentMemberId: currentMember?.currentMemberId,
        });

        setIsBlocked(isBlocked);
        setBeingBlocked(beingBlocked);
      } catch (error) {
        return error;
      }
    };

    getIsBlockedUserAsync();
  }, [currentMember?.currentMemberId, receiverId]);

  useEffect(() => {
    // Cleanup cached URLs on component unmount
    const fileUrls = fileUrlsRef.current;
    return () => {
      fileUrls.forEach((url) => URL.revokeObjectURL(url));
      fileUrls.clear();
    };
  }, []);

  return (
    <>
      <div
        className={`reply-control-container ${
          isFocused && "reply-control-container-focused"
        }`}
      >
        {isFocused && (
          <div
            className="comment-close-btn"
            onClick={() => {
              setIsFocused(false);
              setChat?.("");
            }}
          >
            <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
          </div>
        )}
        {allFiles.length > 0 && (
          <div className="files-popup-box">
            <div className="files-popup-main">
              {allFiles?.map((file, i) => (
                <div key={`upload-${i}`} className="upload-file">
                  <div className="delete-box">
                    <img
                      src={TemplateCardImg.srcClose_icon}
                      alt="delete-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAllFiles?.((prev) =>
                          prev.filter((_, index) => i !== index)
                        );
                        //fileUrlsRef.current.delete(file.name); // Remove URL from cache
                      }}
                    />
                  </div>
                  {!file?.type.includes("image") &&
                    !file?.type.includes("video") && (
                      <div className="app-format">
                        <span className="r-14">
                          {file?.name?.length > 20
                            ? `...${file?.name?.slice(-20)}`
                            : file?.name}
                        </span>
                      </div>
                    )}
                  {file?.type.includes("image") && (
                    <img
                      src={fileUrlsRef.current.get(file.name) || ""}
                      alt={`Uploaded ${file.name}`}
                    />
                  )}
                  {file?.type.includes("video") && (
                    <video src={fileUrlsRef.current.get(file.name) || ""} />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
        <ReplyInput
          value={chat}
          placeholder="回覆..."
          onChange={(msgInputValue) => setChat?.(msgInputValue)}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          setIsIME={setIsIME}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === "Enter" && !isIME) {
              e.preventDefault();
              if (chat.trim() !== "" || allFiles.length !== 0) {
                !isSending && handleSendMsg();
              }
            }
          }}
        />
        <div className="upload-file-action action">
          <label
            htmlFor="files-msg"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <img src={TemplateCardImg.srcUpload_file_icon} alt="upload-file" />
          </label>
        </div>
        <button
          className={`b-16 action ${
            (chat.length === 0 && allFiles.length === 0) || isSending
              ? "disabled-btn"
              : ""
          }`}
          disabled={(chat.length === 0 && allFiles.length === 0) || isSending}
          onClick={handleSendMsg}
        >
          送出
        </button>
        <input
          type="file"
          id="files-msg"
          style={{ display: "none" }}
          multiple
          onChange={handleFileChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
      {fileWarning && (
        <SystemPopup
          popupMsg={"上限為50MB"}
          popupIcon={TemplateOtherImg.srcFail_icon}
          setShow={setFileWarning}
        />
      )}
      {isShow && (
        <AssurePopupModal
          title="訊息封鎖"
          context={
            isBlocked
              ? "此用戶已被封鎖，無法傳送訊息"
              : "您已被封鎖，無法傳送訊息"
          }
          setConfirmPopup={setIsShow}
          onClick={() => {}}
        />
      )}
    </>
  );
};

export default ReplyControl;
