//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./AssureLeavePopup.scss";

interface AssureLeavePopupProps {
  title: string;
  context: string;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
}

const AssureLeavePopup: React.FC<AssureLeavePopupProps> = ({
  title,
  context,
  confirmNavigation,
  cancelNavigation,
}) => {
  return (
    <div className="assure-leave-popup">
      <div className="assure-popup-main">
        <div className="close-btn" onClick={cancelNavigation}>
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="popup-content">
          <div className="title sb-24">{title}</div>
          <div className="context r-16">{context}</div>
        </div>
        <div className="btn-group">
          <button className="primary-btn" onClick={cancelNavigation}>
            取消
          </button>
          <button className="white-btn" onClick={confirmNavigation}>
            確定
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssureLeavePopup;
