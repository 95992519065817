//react
import { useState, useRef, useEffect, Dispatch, SetStateAction } from "react";
//components
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
//context
import { useFilter } from "../../context/HomeFilterContext";
//type
import { TypePostFilter } from "../../types/posts";
//img
import { TemplateNavBarImg } from "../../assets/data/imagesData";
//style
import "./FilterWithMutipleOpts.scss";

interface FilterWithMutipleOptsProps {
  title: string;
  options: TypePostFilter[];
  setOption: Dispatch<SetStateAction<string[]>> | undefined;
  optionSet: string[];
}

const FilterWithMutipleOpts: React.FC<FilterWithMutipleOptsProps> = ({
  title,
  options,
  setOption,
  optionSet,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [checkedOptions, setCheckedOptions] = useState<string[]>(optionSet);

  // context
  const { cleanData } = useFilter();

  // filter out option of 其他 for homepage filter
  const dropdownData = options?.filter((option) => option.option !== "其他");

  // Make dorpdown disappear when cliking outside the dropown
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  const handleCheckboxChange = (option: string) => {
    if (checkedOptions.includes(option)) {
      setCheckedOptions(checkedOptions.filter((item) => item !== option));
    } else {
      setCheckedOptions([...checkedOptions, option]);
    }
  };

  useEffect(() => {
    setOption?.(checkedOptions);
  }, [checkedOptions, setOption]);

  useEffect(() => {
    setCheckedOptions(optionSet);
  }, [cleanData]);

  return (
    <div className="filter-dropdown-box">
      <div ref={ref}>
        <Button
          className="drowpdown-btn r-16"
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target as HTMLElement);
          }}
        >
          {checkedOptions.length > 1 &&
            title !== "開價" &&
            `${checkedOptions?.[0]}、${checkedOptions?.[1]}...`}
          {checkedOptions.length === 1 &&
            title !== "開價" &&
            `${checkedOptions?.[0]}...`}
          {checkedOptions.length >= 1 &&
            title === "開價" &&
            `${checkedOptions?.[0]}...`}
          {checkedOptions?.length === 0 && title}
          <div className={`trigger-icon ${show === true ? "rotate" : ""}`}>
            <img src={TemplateNavBarImg.srcArrow_down_icon} alt="arrow-icon" />
          </div>
        </Button>

        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref}
          //containerPadding={20}
        >
          <Popover>
            <Popover.Body>
              <Form>
                {dropdownData?.map((option) => {
                  return (
                    <div key={option.key} className="checkbox-content">
                      <input
                        type="checkbox"
                        id={option.key}
                        checked={checkedOptions.includes(option.option)}
                        onChange={() => handleCheckboxChange(option.option)}
                      />
                      <label
                        className="checkbox-label r-14"
                        htmlFor={option.key}
                      >
                        {option.option}
                      </label>
                      <span
                        className={`checkmark ${
                          checkedOptions.includes(option.option)
                            ? "checked"
                            : ""
                        }`}
                      ></span>
                    </div>
                  );
                })}
              </Form>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </div>
  );
};

export default FilterWithMutipleOpts;
