//react
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
//api
import { getRecommendedTag } from "../../api/hashtag";
//style
import "./TextAreaWithHashtagProps.scss";

interface TextAreaWithHashtagProps {
  value: string;
  placeholder: string;
  setHashtags: Dispatch<SetStateAction<string[]>>;
  onChange: (value: string) => void;
}

const TextAreaWithHashtagFunction: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaWithHashtagProps
> = (props, ref) => {
  return (
    <>
      <textarea
        ref={ref}
        className="textarea-with-hashtag r-16"
        value={props.value}
        placeholder={props.placeholder}
        onChange={async (e) => {
          props.onChange(e.target.value);
          //string最後一段包含#的部分
          const hashtag = e.target.value.slice(e.target.value.lastIndexOf("#"));
          //確定#sting包含其他字樣,不能包含space後的字樣
          if (hashtag.length > 1 && !hashtag.includes(" ")) {
            const tags = await getRecommendedTag(hashtag.split(" ")[0]);
            props?.setHashtags(tags);
          } else {
            props?.setHashtags([]);
          }
        }}
      />
    </>
  );
};

const TextAreaWithHashtag = forwardRef(TextAreaWithHashtagFunction);

export default TextAreaWithHashtag;
