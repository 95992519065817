//components
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import TrendingPosts from "../../components/TrendingPosts/TrendingPosts";
import BackToTopArrow from "../../components/BackToTopArrow/BackToTopArrow";
//style
import "./PopularPostsPage.scss";

const PopularPostsPage = () => {
  return (
    <>
      <TitleHeader title="熱門貼文" />
      <div className="popular-posts-main-container main-body-spacing">
        <div className="popular-posts-main">
          <TrendingPosts />
          <BackToTopArrow />
        </div>
      </div>
    </>
  );
};

export default PopularPostsPage;
