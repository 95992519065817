//react
import {
  useEffect,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useRef,
} from "react";
import { InfiniteData, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
//components
import SenderChatBubble from "../SenderChatBubble/SenderChatBubble";
import ChatBubble from "../ChatBubble/ChatBubble";
import ReplyControl from "../ReplyControl/ReplyControl";
//signalR
import { SignalRService } from "../../signalR/SignalRService";
//api
import { getUserInfo } from "../../api/users";
import { postMsgIsRead } from "../../api/chat";
import { postUpdateIsReadByMessageId } from "../../api/chat";
//context
import { useChat } from "../../context/ChatProvider";
import { useAuth } from "../../context/AuthContext";
//type
import { TypeMessage } from "../../types/chats";
import { TypeUserInfo } from "../../types/user";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./ChatBox.scss";

interface ChatBoxProps {
  connection: SignalRService | null;
  data: InfiniteData<TypeMessage[]> | undefined;
  handleMsgIsRead: () => void;
}

const ChatBoxFunction: ForwardRefRenderFunction<
  HTMLDivElement,
  ChatBoxProps
> = (props, ref) => {
  const [userInfo, setUserInfo] = useState<TypeUserInfo>();
  const [justEnter, setJustEnter] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [isHover, setIsHover] = useState(false);
  const [hasNewMsg, setHasNewMsg] = useState(false);
  let currentDate = "";

  const navigate = useNavigate();

  //useQuery
  const queryClient = useQueryClient();

  // context - memberId
  const { currentMember } = useAuth();

  // context - chatroom
  const {
    selectedRoomId,
    setFetchAgain,
    fetchAgain,
    receiverId,
    setMessages,
    messages,
    stopScrolling,
    setStopScrolling,
  } = useChat();

  // sroll down to the bottom of thhe chatroom
  const handleScrollToBottom = () => {
    if (ref && "current" in ref && ref.current) {
      const { scrollHeight, clientHeight } = ref.current;
      ref.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  };

  // hdie button of scrolling down
  const handleScroll = () => {
    if (ref && "current" in ref && ref.current) {
      const { scrollTop, scrollHeight, clientHeight } = ref.current;

      // part to be improved later - start
      if (scrollHeight - scrollTop < clientHeight * 1.1) {
        setIsAtBottom(true);
        setIsHover(false);
        setHasNewMsg(false);
      } else {
        setIsAtBottom(false);
      }
      // part to be improved later - end

      // part to be used after improvement
      //setIsAtBottom(scrollHeight - scrollTop === clientHeight);

      // if (scrollHeight - scrollTop === clientHeight) {
      //   setIsHover(false);
      //   setHasNewMsg(false);
      // }
    }
  };

  // mark message as read message
  // const handleMsgIsRead = async () => {
  //   try {
  //     await postMsgIsRead({
  //       chatroomId: selectedRoomId,
  //       memberId: currentMember?.currentMemberId, // current user
  //     });
  //   } catch (error) {
  //     return error;
  //   }
  // };

  // process messages when first enter the room
  useEffect(() => {
    // make sure viewpoint is at latest messages when first enter the room
    const firstUnread = props?.data?.pages[0]?.filter(
      (msg) =>
        msg?.isRead === false &&
        msg?.senderId !== currentMember?.currentMemberId // current uer
    )?.[0]?.id;

    const element = firstUnread && document.getElementById(firstUnread);
    if (element) {
      element.scrollIntoView({ block: "start" });
    }

    // viewpoint at the bottom when there has no unread massages
    if (!firstUnread) {
      if (ref && "current" in ref && ref.current) {
        const { scrollHeight, clientHeight } = ref.current;
        ref.current.scrollTo({
          left: 0,
          top: scrollHeight - clientHeight,
          //behavior: "smooth",
        });
      }
    }

    // fire api to make meesges in room read
    props?.handleMsgIsRead();
  }, [justEnter]);

  // make sure process message after first enter the room and already get data of props?.data?.pages
  useEffect(() => {
    if (props?.data?.pages[0] && !stopScrolling) {
      setJustEnter(!justEnter);
    }
  }, [props?.data?.pages[0]]);

  useEffect(() => {
    const getUserInfoAsync = async () => {
      try {
        const info = await getUserInfo(receiverId);
        setUserInfo(info);
      } catch (error) {
        return error;
      }
    };

    getUserInfoAsync();
  }, [receiverId]);

  ////////////// SignalR - code below /////////////////

  // use selectedRoomIdRef to make sure holding the latest selectedRoomId
  const selectedRoomIdRef = useRef(selectedRoomId);
  useEffect(() => {
    selectedRoomIdRef.current = selectedRoomId;
  }, [selectedRoomId]);

  // Mark single message sent by sender as read
  const getStatusOfRead = async () => {
    try {
      const msg =
        (await props?.connection?.markMessageAsIsRead()) as TypeMessage;

      if (msg) {
        //const res = await postUpdateIsReadByMessageId(msg?.id); // api to update status of read in db

        setMessages?.((msgs) => {
          return msgs?.map((message) => {
            if (message?.id === msg?.id) {
              return {
                ...message,
                isRead: true,
              };
            } else {
              return message;
            }
          });
        });
      }
    } catch (error) {
      return error;
    }
  };

  getStatusOfRead();

  // Receive opponent's Message
  const getOpponentMessage = async () => {
    try {
      const newMessage =
        (await props?.connection?.receiveMessage()) as TypeMessage;

      if (newMessage) {
        setFetchAgain?.(!fetchAgain);
        if (newMessage?.chatroomId === selectedRoomIdRef.current) {
          setMessages?.([...messages, newMessage]);
          setHasNewMsg(true);

          const res = await postUpdateIsReadByMessageId(newMessage?.id);

          if (res) {
            // isRead implementation - this will trigger markMessageAsIsRead function
            props?.connection?.messageIsSeen(newMessage?.id, false); // need to make sure received message is for current room to trigger messageIsSeen function
          }
        }

        // rush down at the bottom of chatroom while getting new message
        if (ref && "current" in ref && ref.current) {
          const { scrollTop, scrollHeight, clientHeight } = ref.current;

          if (scrollHeight - scrollTop < clientHeight * 1.2) {
            handleScrollToBottom();
          }
        }
      }
    } catch (error) {
      return error;
    }
  };

  getOpponentMessage();

  // Receive message sent by current user
  const getSelfMessage = async () => {
    try {
      const newMessage =
        (await props?.connection?.receiveSelfMessage()) as TypeMessage;

      if (newMessage) {
        setFetchAgain?.(!fetchAgain);
        newMessage?.chatroomId === selectedRoomIdRef.current &&
          setMessages?.([...messages, newMessage]);
      }
    } catch (error) {
      return error;
    }
  };

  getSelfMessage();

  // Know meassage has been read as receiver just joins the room
  const messageHasSeenByReceiver = async () => {
    try {
      const msg =
        (await props?.connection?.MessageSeenAsReceiverIn()) as TypeMessage;
      if (msg) {
        // 在剛進聊天室, state目前沒有即時訊息的狀況
        if (!messages.length) {
          queryClient.invalidateQueries("/messages");
          setStopScrolling?.(true);
        }

        // state目前有即時訊息的狀況
        setMessages?.((msgs) => {
          return msgs?.map((message) => {
            if (message?.id === msg?.id) {
              return {
                ...message,
                isRead: true,
              };
            } else {
              return message;
            }
          });
        });
      }
    } catch (error) {
      return error;
    }
  };

  messageHasSeenByReceiver();

  return (
    <>
      <div className="chatbox-main-container">
        <div className="talker-title">
          <div
            className="talker-pic"
            onClick={() =>
              navigate(`/userinfo/${receiverId.substring(0, 16)}`, {
                state: { userId: receiverId },
              })
            }
          >
            <img src={userInfo?.personalAvatar} alt="sender-pic" />
          </div>
          <span
            className="talker-name b-16"
            onClick={() =>
              navigate(`/userinfo/${receiverId.substring(0, 16)}`, {
                state: { userId: receiverId },
              })
            }
          >
            {userInfo?.userName}
          </span>
        </div>
        <div className="chatbox-main-area" ref={ref} onScroll={handleScroll}>
          {hasNewMsg && (
            <div className="new-msg-tag r-14" onClick={handleScrollToBottom}>
              您有新訊息
            </div>
          )}
          {props?.data?.pages
            ?.map((messages) => {
              return messages?.map((msg) => {
                const msgDate =
                  msg?.senderId === currentMember?.currentMemberId &&
                  msg?.isSenderDeleted
                    ? ""
                    : msg?.creationTime?.split("T")[0];

                // Turn string of files into array
                const msgFiles = msg?.fileContent?.split(",");

                if (currentDate !== msgDate && msgDate !== "") {
                  currentDate = msgDate;
                  return (
                    <div id={msg?.id} key={msg?.id}>
                      {msg?.senderId === currentMember?.currentMemberId &&
                      msg?.isSenderDeleted ? (
                        ""
                      ) : (
                        <div className="record-date r-12">
                          <span>{msgDate?.replaceAll("-", "/")}</span>
                        </div>
                      )}
                      {msg?.senderId !== currentMember?.currentMemberId && (
                        <SenderChatBubble
                          id={msg?.id}
                          msg={msg?.content}
                          msgFiles={msgFiles}
                          avatar={msg?.senderAvatar}
                          timestamp={msg?.creationTime
                            ?.split("T")[1]
                            .substring(0, 5)}
                          ownerId={msg?.senderId}
                        />
                      )}
                      {msg?.senderId === currentMember?.currentMemberId &&
                        msg?.isSenderDeleted === false && (
                          <ChatBubble
                            id={msg?.id}
                            msg={msg?.content}
                            msgFiles={msgFiles}
                            timestamp={msg?.creationTime
                              ?.split("T")[1]
                              .substring(0, 5)}
                            isRead={msg?.isRead}
                            connection={props?.connection}
                          />
                        )}
                    </div>
                  );
                } else {
                  return (
                    <div id={msg?.id} key={msg?.id}>
                      {msg?.senderId !== currentMember?.currentMemberId && (
                        <SenderChatBubble
                          id={msg?.id}
                          msg={msg?.content}
                          msgFiles={msgFiles}
                          avatar={msg?.senderAvatar}
                          timestamp={msg?.creationTime
                            ?.split("T")[1]
                            .substring(0, 5)}
                          ownerId={msg?.senderId}
                        />
                      )}
                      {msg?.senderId === currentMember?.currentMemberId &&
                        msg?.isSenderDeleted === false && (
                          <ChatBubble
                            id={msg?.id}
                            msg={msg?.content}
                            msgFiles={msgFiles}
                            timestamp={msg?.creationTime
                              ?.split("T")[1]
                              .substring(0, 5)}
                            isRead={msg?.isRead}
                            connection={props?.connection}
                          />
                        )}
                    </div>
                  );
                }
              });
            })
            .reverse()}
          {/* render real-time messages  */}
          {messages?.map((msg) => {
            const msgDate =
              msg?.senderId === currentMember?.currentMemberId &&
              msg?.isSenderDeleted
                ? ""
                : msg?.creationTime?.split("T")[0];

            // Turn string of files into array
            const msgFiles = msg?.fileContent?.split(",");

            if (currentDate !== msgDate && msgDate !== "") {
              currentDate = msgDate;
              return (
                <div id={msg?.id} key={msg?.id}>
                  {msg?.senderId === currentMember?.currentMemberId &&
                  msg?.isSenderDeleted ? (
                    ""
                  ) : (
                    <div className="record-date r-12">
                      <span>{msgDate}</span>
                    </div>
                  )}
                  {msg?.senderId !== currentMember?.currentMemberId && (
                    <SenderChatBubble
                      id={msg?.id}
                      msg={msg?.content}
                      msgFiles={msgFiles}
                      avatar={msg?.senderAvatar}
                      timestamp={msg?.creationTime
                        ?.split("T")[1]
                        .substring(0, 5)}
                      ownerId={msg?.senderId}
                    />
                  )}
                  {msg?.senderId === currentMember?.currentMemberId &&
                    msg?.isSenderDeleted === false && (
                      <ChatBubble
                        id={msg?.id}
                        msg={msg?.content}
                        msgFiles={msgFiles}
                        timestamp={msg?.creationTime
                          ?.split("T")[1]
                          .substring(0, 5)}
                        isRead={msg?.isRead}
                        connection={props?.connection}
                      />
                    )}
                </div>
              );
            } else {
              return (
                <div id={msg?.id} key={msg?.id}>
                  {msg?.senderId !== currentMember?.currentMemberId && (
                    <SenderChatBubble
                      id={msg?.id}
                      msg={msg?.content}
                      msgFiles={msgFiles}
                      avatar={msg?.senderAvatar}
                      timestamp={msg?.creationTime
                        ?.split("T")[1]
                        .substring(0, 5)}
                      ownerId={msg?.senderId}
                    />
                  )}
                  {msg?.senderId === currentMember?.currentMemberId &&
                    msg?.isSenderDeleted === false && (
                      <ChatBubble
                        id={msg?.id}
                        msg={msg?.content}
                        msgFiles={msgFiles}
                        timestamp={msg?.creationTime
                          ?.split("T")[1]
                          .substring(0, 5)}
                        isRead={msg?.isRead}
                        connection={props?.connection}
                      />
                    )}
                </div>
              );
            }
          })}
          {!isAtBottom && (
            <div
              className="rush-to-bottom-btn"
              onClick={handleScrollToBottom}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <img src={TemplateCardImg.srcScroll_down_icon} alt="rush-down" />
            </div>
          )}
        </div>
      </div>
      <ReplyControl
        connection={props?.connection}
        rushDown={handleScrollToBottom}
      />
    </>
  );
};

const ChatBox = forwardRef(ChatBoxFunction);

export default ChatBox;
