import React from "react";
//hooks
import usePreventScrolling from "../../hooks/usePreventScrolling";
//img
import { TemplateOtherImg } from "../../assets/data/imagesData";
//style
import "./DownloadAppPage.scss";

const DownloadAppPage = () => {
  usePreventScrolling();
  return (
    <div className="download-main-container">
      <div className="download-main">
        {/* <img src={TemplateOtherImg?.srcDownload_page_bg} alt="page-bg" /> */}
        <div className="download-sec">
          <img src={TemplateOtherImg?.srcLogo_transparent} alt="logo" />
          <div className="brand-description r-14">
            「你」還在用照片呈現房屋室內嗎 「你」還在看這一成不變的圖文嗎
            「你」是不是需要多點時間做廣告 HIGHLIGHT
            就是你最好的幫手最好的賞屋平台
          </div>
          <div className="store-btn-group">
            <a href="https://play.google.com/store/apps/details?id=com.highlight.highlightapp&hl=zh_TW">
              <img
                src={TemplateOtherImg?.srcGoogle_play_btn}
                alt="google-play-link"
              />
            </a>
            <a href="https://apps.apple.com/tw/app/highlight-house/id6503441039">
              <img
                src={TemplateOtherImg?.srcApple_store_btn}
                alt="google-play-link"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppPage;
