//react
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
//api
import { getPostDetail } from "../../api/posts";
//style
import "./NavigateToPostPage.scss";

const NavigateToPostPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [dimension, setDimension] = useState<{ width: string; height: string }>(
    { width: "", height: "" }
  );
  const [postId, setPostId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const currentPgaeUrl = window.location.href;

    const id = currentPgaeUrl?.includes("/post/")
      ? currentPgaeUrl
          ?.slice(currentPgaeUrl?.lastIndexOf("/") + 1)
          ?.substring(0, 36)
      : "";

    setPostId(id);

    const getUserInfoAsync = async () => {
      try {
        const res = await getPostDetail({
          postId: id,
          currentMember: "",
        });

        if (res) {
          setTitle(res?.title);
          setDescription(res?.content);
          setImage(
            res?.extraProperties?.CoverImage
              ? res?.extraProperties?.CoverImage
              : res?.extraProperties?.Images?.[0]
          );

          setDimension({
            width: String(res?.width),
            height: String(res?.height),
          });

          setTimeout(() => {
            navigate("/", {
              state: { postId: id },
            });
          }, 3000);

          //   const titleMeta = document
          //     .querySelector("meta[name='twitter:title']")
          //     ?.getAttribute("content");

          //   const descriptionMeta = document
          //     .querySelector("meta[name='twitter:description']")
          //     ?.getAttribute("content");

          //   const imageMeta = document
          //     .querySelector("meta[name='twitter:image]")
          //     ?.getAttribute("content");

          //   if (titleMeta && descriptionMeta && imageMeta) {
          //     navigate("/", {
          //       state: { postId: id },
          //     });
          //   }
        }
      } catch (error) {
        return error;
      }
    };

    getUserInfoAsync();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* Facebook Meta Tags */}
        <meta
          property="og:url"
          content={`https://highlight11.com/post/${postId}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="fb:app_id" content="1142240723451522" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="highlight11.com" />
        <meta
          property="twitter:url"
          content={`https://highlight11.com/post/${postId}`}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <div className="loading-spinner"></div>
    </div>
  );
};

export default NavigateToPostPage;
