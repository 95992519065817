//react
import { useState, Dispatch, SetStateAction } from "react";
//components
import Input from "../Input/Input";
//type
import { TypeMemberPlan } from "../../types/membership";
//context
import { useAuth } from "../../context/AuthContext";
//api
import { postTransaction } from "../../api/membership";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./TransactionPopupModel.scss";

interface TransactionPopupModelProps {
  setShowTXN: Dispatch<SetStateAction<boolean>>;
  planId: string;
  plans: TypeMemberPlan[];
}

const handleECPayForm = (resForm: string) => {
  // Create a temporary DOM element to parse the response
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = resForm;

  // Get the form element from the parsed response
  const form = tempDiv.querySelector("form") as HTMLFormElement;
  if (form) {
    // Set the target attribute to open in a new tab
    form.setAttribute("target", "_blank");

    // Append the form to the body to ensure it can be submitted
    document.body.appendChild(form);

    // Submit the form
    form.submit();

    // Remove the form from the body after submission
    document.body.removeChild(form);
  }
};

const TransactionPopupModel: React.FC<TransactionPopupModelProps> = (props) => {
  const [invoiceType, setInvoiceType] = useState("個人發票");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [invoiceTitle, setInvoiceTitle] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [address, setAddress] = useState("");
  const [carrierNum, setCarrierNum] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [identifierErr, setIdentifierErr] = useState("");

  // context - memberId
  const { currentMember } = useAuth();

  const handleSubmmitTXN = async () => {
    let valid = true;

    const emailRegex =
      /^((([A-Za-z]|\d|[!#\$%&’\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([A-Za-z]|\d|[!#\$%&’\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;

    if (!emailRegex.test(email)) {
      setEmailErr("請輸入有效電電子郵件地址");
      valid = false;
    } else {
      setEmailErr("");
    }

    if (invoiceType === "統一編號") {
      if (identifier.length !== 8 || !/^\d+$/.test(identifier)) {
        setIdentifierErr("請輸入8碼數字");
        valid = false;
      } else {
        setIdentifierErr("");
      }
    }

    if (!valid) return;

    const currentPrice = props?.plans?.filter(
      (plan) => plan?.id === props?.planId
    )?.[0]?.currentPrice;
    const originalPrice = props?.plans?.filter(
      (plan) => plan?.id === props?.planId
    )?.[0]?.originalPrice;
    const itemName = props?.plans?.filter(
      (plan) => plan?.id === props?.planId
    )?.[0]?.orderType;

    let purchsedItem;

    switch (invoiceType) {
      case "個人發票":
        purchsedItem = {
          invoiceType: "2",
          CustomerEmail: email,
          Items: [
            {
              ItemName: itemName,
              ItemAmount: String(currentPrice),
              ItemPrice: String(currentPrice),
              ItemCount: "1",
              ItemWord: itemName,
            },
          ],
        };
        break;
      case "手機載具":
        purchsedItem = {
          invoiceType: "3",
          CarrierType: "3",
          CarrierNum: carrierNum,
          CustomerEmail: email,
          Items: [
            {
              ItemName: itemName,
              ItemAmount: String(currentPrice),
              ItemPrice: String(currentPrice),
              ItemCount: "1",
              ItemWord: itemName,
            },
          ],
        };
        break;
      case "統一編號":
        purchsedItem = {
          invoiceType: "1",
          CustomerName: invoiceTitle,
          CustomerIdentifier: identifier,
          CustomerAddr: address,
          CustomerEmail: email,
          Items: [
            {
              ItemName: itemName,
              ItemAmount: String(currentPrice),
              ItemPrice: String(currentPrice),
              ItemCount: "1",
              ItemWord: itemName,
            },
          ],
        };
        break;
      default:
        return;
    }

    try {
      const res = await postTransaction({
        orderId: props?.planId,
        memberId: currentMember?.currentMemberId,
        invoiceDetailsJSON: JSON.stringify(purchsedItem),
      });

      if (res?.message) {
        handleECPayForm(res?.message);
        props?.setShowTXN(false);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="transaction-popup-model">
      <div className="transaction-popup-main">
        <div className="close-btn" onClick={() => props?.setShowTXN(false)}>
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="transaction-popup-title sb-24">電子發票</div>
        <div className="input-top">
          <Input
            type="text"
            name=""
            value={name}
            placeholder={"姓名"}
            onChange={(nameInputValue) => setName(nameInputValue)}
          />
          <Input
            type="text"
            name=""
            value={email}
            placeholder={"電子信箱"}
            onChange={(emailInputValue) => setEmail(emailInputValue)}
          />
          {emailErr !== "" && (
            <div className="error r-14" style={{ color: "red" }}>
              {emailErr}
            </div>
          )}
        </div>
        <div className="radio-group r-14">
          <label className="radio-label">
            <input
              type="radio"
              className="radio-input"
              name="invoiceType"
              checked={invoiceType === "個人發票"}
              onChange={() => setInvoiceType("個人發票")}
            />
            <span className="checkmark"></span>
            個人發票
          </label>
          <label className="radio-label">
            <input
              type="radio"
              className="radio-input"
              name="invoiceType"
              checked={invoiceType === "手機載具"}
              onChange={() => setInvoiceType("手機載具")}
            />
            <span className="checkmark"></span>
            手機載具
          </label>
          <label className="radio-label">
            <input
              type="radio"
              className="radio-input"
              name="invoiceType"
              checked={invoiceType === "統一編號"}
              onChange={() => setInvoiceType("統一編號")}
            />
            <span className="checkmark"></span>
            統一編號
          </label>
        </div>
        <div className="input-bottom">
          {invoiceType !== "個人發票" && (
            <div className="input-group">
              {invoiceType === "統一編號" ? (
                <>
                  <Input
                    type="text"
                    name=""
                    value={invoiceTitle}
                    placeholder={"發票抬頭"}
                    onChange={(titleInputValue) =>
                      setInvoiceTitle(titleInputValue)
                    }
                  />
                  <Input
                    type="text"
                    name=""
                    value={identifier}
                    placeholder={"統編號碼"}
                    onChange={(identifierInputValue) =>
                      setIdentifier(identifierInputValue)
                    }
                  />
                  {identifierErr !== "" && (
                    <div className="error r-14" style={{ color: "red" }}>
                      {identifierErr}
                    </div>
                  )}
                  <Input
                    type="text"
                    name=""
                    value={address}
                    placeholder={"地址"}
                    onChange={(addressInputValue) =>
                      setAddress(addressInputValue)
                    }
                  />
                </>
              ) : (
                <Input
                  type="text"
                  name=""
                  value={carrierNum}
                  placeholder={"/O4N+ZS2"}
                  onChange={(carrierInputValue) =>
                    setCarrierNum(carrierInputValue)
                  }
                />
              )}
            </div>
          )}
        </div>
        <button
          className="transaction-submit-btn white-btn"
          onClick={handleSubmmitTXN}
        >
          進入付款
        </button>
      </div>
    </div>
  );
};

export default TransactionPopupModel;
