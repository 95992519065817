//react
import { Dispatch, SetStateAction } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./CompulsoryLogoutPopup.scss";

interface CompulsoryLogoutPopupProps {
  setTimeout: Dispatch<SetStateAction<boolean>> | undefined;
  title: string;
  context: string;
}

const CompulsoryLogoutPopup: React.FC<CompulsoryLogoutPopupProps> = (props) => {
  return (
    <div className="compulsory-logout-popup">
      <div className="compulsory-logout-main">
        <div className="close-btn" onClick={() => props?.setTimeout?.(false)}>
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="content">
          <div className="title sb-24">{props?.title}</div>
          <div className="r-16 context">{props?.context}</div>
        </div>
        <button
          className="primary-btn"
          onClick={() => props?.setTimeout?.(false)}
        >
          關閉
        </button>
      </div>
    </div>
  );
};

export default CompulsoryLogoutPopup;
