//react
import { useEffect, Dispatch, SetStateAction } from "react";
//style
import "./SystemPopup.scss";

interface SystemPopupProps {
  setShow: Dispatch<SetStateAction<boolean>> | undefined;
  popupMsg: string;
  popupIcon: string;
}

const SystemPopup: React.FC<SystemPopupProps> = (props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.setShow?.(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [props]);

  return (
    <div className="system-popup-model">
      <div className="system-popup-container">
        {props.popupIcon && (
          <div className="status-icon">
            <img src={props.popupIcon} alt="status-icon" />
          </div>
        )}
        <div className="main-msg sb-16">
          <div>{props.popupMsg}</div>
        </div>
      </div>
    </div>
  );
};

export default SystemPopup;
