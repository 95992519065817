//components
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import HashtagPosts from "../../components/HashtagPosts/HashtagPosts";
//context
import { useFilter } from "../../context/HomeFilterContext";
//style
import "./HashTagPostsPage.scss";

const HashTagPostsPage = () => {
  const { selectedHashtag } = useFilter();

  return (
    <>
      <TitleHeader title={selectedHashtag} />
      <div className="hashtag-posts-main-container main-body-spacing">
        <div className="hashtag-posts-main">
          <HashtagPosts />
        </div>
      </div>
    </>
  );
};

export default HashTagPostsPage;
