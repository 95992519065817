//react
import { useEffect, Dispatch, SetStateAction } from "react";
//type
import { TypeNotifi } from "../../types/user";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./NewNotifiPopup.scss";

interface NewNotifiPopupProps {
  setHasNewNotifi: Dispatch<SetStateAction<boolean>>;
  newNotification: TypeNotifi;
}

const NewNotifiPopup: React.FC<NewNotifiPopupProps> = (props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.setHasNewNotifi?.(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [props]);

  return (
    <div className="new-notifi-popup">
      <div className="new-notif-card-box">
        <div className="new-notif-user-img">
          <img
            src={
              props?.newNotification?.notificationType === "System Notify" ||
              props?.newNotification?.body === "貼文影片已串接完成"
                ? TemplateCardImg.srcService_logo
                : props?.newNotification?.senderMemberAvatar
            }
            alt="user-img"
          />
        </div>
        <div className="new-notif-content">
          <span className="user-name sb-14">
            {props?.newNotification?.userName}
          </span>
          <span className="r-14">{props?.newNotification?.body}</span>
        </div>
      </div>
    </div>
  );
};

export default NewNotifiPopup;
