//react
import { useEffect, useState, SetStateAction, Dispatch } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//components
import FollowerPopupModel from "../FollowerPopupModel/FollowerPopupModel";
import PopoverMenu from "../PopoverMenu/PopoverMenu";
import BlockListPopupModel from "../BlockListPopupModel/BlockListPopupModel";
//context
import { useAuth } from "../../context/AuthContext";
//api
import {
  getListOfFollowers,
  postIsFollowing,
  postFollowOrUnfollow,
  postListOfBlockedUsers,
} from "../../api/users";
import { postGetOrCreateRoomId } from "../../api/chat";
//type
import {
  TypeUserInfo,
  TypeFollower,
  TypeCountsInfo,
  TypeBlockedUser,
} from "../../types/user";
//style
import "./ProfileCard.scss";

interface ProfileCardProps {
  userInfo: TypeUserInfo;
  userId: string;
  countsInfo: TypeCountsInfo;
}

const ProfileCard: React.FC<ProfileCardProps> = (props) => {
  const [followers, setFollowers] = useState<TypeFollower[]>([]);
  const [isFollowing, setIsFollowing] = useState();
  const [fetchAgain, setFetchAgain] = useState(false);
  const [listOfBlock, setListOfBlock] = useState<TypeBlockedUser[]>([]);
  const [isBlocked, setIsBlocked] = useState(false); // 封鎖對方
  const [beingBlocked, setBeingBlocked] = useState(false); // 被對方封鎖

  // context - memberId
  const { currentMember, isAuthenticated, setShowAuth } = useAuth();

  const location = useLocation();
  const { pathname } = location;

  const [isShow, setIsShow] = useState(false);
  const [showListOfBlock, setShowListOfBlock] = useState(false);

  // check/create chatroom Id and navigate to chatroom
  const navigate = useNavigate();

  const handleLaunchRoom = async () => {
    try {
      const ids = await postGetOrCreateRoomId({
        senderId: currentMember?.currentMemberId, // current user
        receiverId: props?.userId,
      });

      if (ids) {
        navigate("/message-main", {
          state: { chatroomIds: ids },
        });
      }
    } catch (error) {
      return error;
    }
  };

  const handleFollowOrUnFollow = async () => {
    try {
      const res = await postFollowOrUnfollow({
        FollowerId: currentMember?.currentMemberId, //自己
        FollowedId: props?.userId, //要追蹤對象
      });

      if (res?.success) {
        setFetchAgain(!fetchAgain);
      }
    } catch (error) {
      return error;
    }
  };

  // List of Followers and Blocked Users
  useEffect(() => {
    const getFollowersAsync = async () => {
      try {
        const list = await getListOfFollowers(props?.userId);
        setFollowers(list);
      } catch (error) {
        return error;
      }
    };

    const getBlockedUserAsync = async () => {
      try {
        const list = await postListOfBlockedUsers(
          currentMember?.currentMemberId
        );

        setListOfBlock(list);
      } catch (error) {
        return error;
      }
    };

    getFollowersAsync();
    props?.userId === currentMember?.currentMemberId && getBlockedUserAsync();
  }, [fetchAgain, props?.userId, currentMember?.currentMemberId]);

  // Check if current user is following the id of the user
  useEffect(() => {
    // make sure its not on current user's profile page
    if (props?.userId !== currentMember?.currentMemberId) {
      const postIsFollowingAsync = async () => {
        try {
          const isFollowing = await postIsFollowing({
            FollowerId: currentMember?.currentMemberId, //自己
            FollowedId: props?.userId, //要追蹤對象
          });
          setIsFollowing(isFollowing);
        } catch (error) {
          return error;
        }
      };

      postIsFollowingAsync();
    }
  }, [fetchAgain, props?.userId, currentMember?.currentMemberId]);

  return (
    <>
      <div className="profile-card-container">
        <img
          className="user-bg"
          src={props?.userInfo?.backgroundImage}
          alt="profile-bg"
        />
        <div className="backdrop"></div>
        <div className="card-content">
          <div className="user-pic">
            <img src={props?.userInfo?.personalAvatar} alt="user-pic" />
          </div>
          <div className="content-group">
            <div className="user-detail">
              <span className="user-name sb-24">
                {props?.userInfo?.userName}
              </span>
              <div className="decription r-14">
                {props?.userInfo?.personalIntroduction}
              </div>
            </div>
            <div className="social-counts">
              <div className="post-count">
                <span className="b-14">{props?.countsInfo?.memberPosts}</span>
              </div>
              <div className="vertical-bar"></div>
              <div className="follower-count" onClick={() => setIsShow(true)}>
                <span className="b-14">{props?.countsInfo?.memberFans}</span>
              </div>
              <div className="vertical-bar"></div>
              <div className="like-count">
                <span className="b-14">{props?.countsInfo?.postLikes}</span>
              </div>
              {props?.userId === currentMember?.currentMemberId && (
                <>
                  {" "}
                  <div className="vertical-bar"></div>
                  <div
                    className="block-count"
                    onClick={() => setShowListOfBlock(true)}
                  >
                    <span className="b-14">{listOfBlock?.length}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {props?.userId !== currentMember?.currentMemberId && (
          <div className="profile-btn-group">
            {!isBlocked && !beingBlocked && (
              <button
                className="sb-16"
                onClick={() =>
                  isAuthenticated ? handleLaunchRoom() : setShowAuth?.(true)
                }
              >
                訊息
              </button>
            )}
            {!isBlocked && !beingBlocked && (
              <button
                className={`sb-16 ${!isFollowing && "to-follow"}`}
                onClick={() =>
                  isAuthenticated
                    ? handleFollowOrUnFollow()
                    : setShowAuth?.(true)
                }
              >
                {isFollowing ? "取消關注" : "關注"}
              </button>
            )}
            <PopoverMenu
              idToBlock={
                props?.userId !== currentMember?.currentMemberId
                  ? props?.userId
                  : ""
              }
              setIsBlocked={setIsBlocked}
              isBlocked={isBlocked}
              setBeingBlocked={setBeingBlocked}
            />
          </div>
        )}
      </div>
      {isShow && (
        <FollowerPopupModel
          followers={followers}
          setIsShow={(close: boolean) => setIsShow(close)}
        />
      )}
      {showListOfBlock && (
        <BlockListPopupModel
          listOfBlock={listOfBlock}
          setIsShow={(close: boolean) => setShowListOfBlock(close)}
        />
      )}
    </>
  );
};

export default ProfileCard;
