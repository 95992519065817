//react
import { useNavigate } from "react-router-dom";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./NoPlanPopupModel.scss";

const NoPlanPopupModel: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="plan-popup-model">
      <div className="assure-popup-main">
        <div className="popup-top-bg">
          <img src={TemplateCardImg?.srcReport_bg} alt="popup-bg" />
        </div>
        <div className="popup-content">
          <div className="title sb-24">付費功能</div>
          <div className="context r-16">
            需付費開通發佈功能 即將前往會員方案
          </div>
        </div>
        <div className="btn-group">
          <button className="primary-btn" onClick={() => navigate("/")}>
            稍微考慮
          </button>
          <button className="white-btn" onClick={() => navigate("/membership")}>
            付費開通
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoPlanPopupModel;
