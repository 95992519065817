//react
import { Dispatch, SetStateAction } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";

interface DeleteAccountPopupProps {
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
  onClick: () => void;
}

const DeleteAccountPopup: React.FC<DeleteAccountPopupProps> = (props) => {
  return (
    <div className="assure-popup-model">
      <div className="assure-popup-main">
        <div
          className="close-btn"
          onClick={() => props?.setConfirmPopup(false)}
        >
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="popup-content">
          <div className="title sb-24">帳號刪除</div>
          <div className="context r-16">
            <div className="emphasize-down-space" style={{ color: "red" }}>
              !!!!請注意!!!!!
            </div>
            {`點選後將視為同意移除
使用者帳號資料
平台將不會保留下列資訊`}
            <div className="emphasize-top-space" style={{ color: "red" }}>
              {`亦不提供任何退款服務與紀錄留存
(使用者資料、發文、愛心、留言、
訊息記錄、購買的方案等)
請謹慎評估後再點選，謝謝。`}
            </div>
          </div>
        </div>
        <div className="btn-group">
          <button
            className="primary-btn"
            onClick={() => props?.setConfirmPopup(false)}
          >
            取消
          </button>
          <button className="white-btn" onClick={() => props?.onClick()}>
            確定刪除
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountPopup;
