//react
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//context
import { usePost } from "../../context/PostProvider";
//components
import AssureLeavePopup from "../AssureLeavePopup/AssureLeavePopup";

const NavigationGuard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const confirmNavigation = () => {
    setShowModal(false);
    path !== "prev" ? navigate(path) : window.history.go(-1);
    setPath?.("");
    setHasContent?.(false);
  };

  const cancelNavigation = () => {
    setShowModal(false);
    //window.history.pushState(null, "", window.location.pathname);
  };

  // manipulate popup
  const { path, setPath, setHasContent, showPopup } = usePost();

  useEffect(() => {
    path && setShowModal(true);
    path?.includes("publish") &&
      location.pathname.includes("publish") &&
      setShowModal(false);
  }, [showPopup]);

  return (
    <>
      {showModal && (
        <AssureLeavePopup
          title="離開編輯"
          context="你會失去所進行的編輯內容。此動作無法復原！"
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
      )}
    </>
  );
};

export default NavigationGuard;
