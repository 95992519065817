//react
import React, { useState, useRef, useEffect } from "react";
//components
//import FilterDropdown from "../FilterDropdown/FilterDropdown";
import FilterWithMutipleOpts from "../FilterWithMutipleOpts/FilterWithMutipleOpts";
//data
import { filterData } from "../../assets/data/filterData";
//context
import { useFilter } from "../../context/HomeFilterContext";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
//img
import {
  TemplateOtherImg,
  TemplateNavBarImg,
} from "../../assets/data/imagesData";
//style
import "./SearchFilter.scss";

const SearchFilter: React.FC = () => {
  const [show, setShow] = useState(false);
  const [advancedShow, setAdvancedShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  // Context to store data for request api
  const {
    filterFormData,
    setPurpose,
    setRegion,
    setHouseType,
    setPrice,
    setLayout,
    setPropertyAge,
    setSearchKey,
    setPostContent,
    cleanData,
    setCleanData,
  } = useFilter();

  // Number here doesnt really has a purpose but triggering rerender(<FilterPosts />) by increment to change state of searchKey
  const handleSearch = () => {
    setSearchKey?.((prevKey: number) => prevKey + 1);
    setPostContent?.("Filter");
  };

  const handleCleanFilter = () => {
    setPurpose?.("sell");
    setRegion?.([]);
    setHouseType?.([]);
    setPrice?.([]);
    setLayout?.([]);
    setPropertyAge?.([]);
    setCleanData?.(!cleanData);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  return (
    <>
      <div className="search-filter-box" ref={ref}>
        <Button
          className="filter-btn"
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target as HTMLElement);
          }}
        >
          <img src={TemplateOtherImg.srcFilter_icon} alt="filter-icon" />
        </Button>

        <Overlay show={true} target={target} placement="bottom" container={ref}>
          <Popover
            className={`filter-popover-body ${show && "popover-body-show"}`}
          >
            <Popover.Body>
              <div className="title sb-24">搜尋物件類別</div>
              <div className="search-filter-btn">
                <button
                  className={`primary-btn ${
                    filterFormData?.purpose === "sell" ? "secondary-btn" : ""
                  }`}
                  onClick={() => {
                    setPurpose?.("sell");
                    setPrice?.([]);
                  }}
                >
                  我要買屋
                </button>
                <button
                  className={`primary-btn ${
                    filterFormData?.purpose === "rent" ? "secondary-btn" : ""
                  }`}
                  onClick={() => {
                    setPurpose?.("rent");
                    setPrice?.([]);
                  }}
                >
                  我要租屋
                </button>
              </div>
              <div className="search-filter-dropdown">
                <FilterWithMutipleOpts
                  title="地區"
                  options={filterData.regions}
                  setOption={setRegion}
                  optionSet={filterFormData?.region}
                />
                <div
                  className={`advanced-btn r-16 ${
                    advancedShow === true ? "transparent" : ""
                  }`}
                  onClick={() => setAdvancedShow(!advancedShow)}
                >
                  {"進階搜尋設定"}
                  <img
                    src={TemplateNavBarImg.srcArrow_down_icon}
                    alt="arrow-icon"
                  />
                </div>

                <div
                  className={`advanced-filter ${
                    advancedShow && "advanced-filter-show"
                  }`}
                >
                  <FilterWithMutipleOpts
                    title="類型"
                    options={filterData.houseType}
                    setOption={setHouseType}
                    optionSet={filterFormData?.houseType}
                  />
                  <FilterWithMutipleOpts
                    title="開價"
                    options={
                      filterFormData?.purpose === "sell"
                        ? filterData.sellPrice
                        : filterData.rentPrice
                    }
                    setOption={setPrice}
                    optionSet={filterFormData?.price}
                  />
                  <FilterWithMutipleOpts
                    title="格局"
                    options={filterData.houseLayout}
                    setOption={setLayout}
                    optionSet={filterFormData?.layout}
                  />
                  <FilterWithMutipleOpts
                    title="屋齡"
                    options={filterData.houseAge}
                    setOption={setPropertyAge}
                    optionSet={filterFormData?.propertyAge}
                  />
                </div>
              </div>
              <div className="search-or-clean">
                <button className="clean-btn sb-16" onClick={handleCleanFilter}>
                  清空
                </button>
                <button
                  className="all-btn sb-16"
                  onClick={() => setPostContent?.("All")}
                >
                  全部
                </button>
              </div>
              <button className="white-btn sb-16" onClick={handleSearch}>
                搜尋
              </button>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </>
  );
};

export default SearchFilter;
