//react
import { Dispatch, SetStateAction } from "react";
//data
import { servicePolicy } from "../../assets/data/policyData";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//utility
import closePopup from "../../utils/closePopup";
//style
import "./ServicePolicyPopupModel.scss";

interface ServicePolicyPopupModelProps {
  setShowPolicyOne: Dispatch<SetStateAction<boolean>> | undefined;
}

const ServicePolicyPopupModel: React.FC<ServicePolicyPopupModelProps> = (
  props
) => {
  return (
    <div
      className="policy-popup-model"
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        closePopup(e, "policy-popup-model", props.setShowPolicyOne);
      }}
    >
      <div className="policy-main">
        <div
          className="close-btn"
          onClick={() => props.setShowPolicyOne?.(false)}
        >
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="sec-title">
          <h6 className="sb-24">服務條款 - Highlight 最精采的影音房地產交易</h6>
        </div>
        <div className="update-date r-14">
          最後更新時間： 2023 年 12 月 4 日
        </div>
        <div className="policy-list">
          {servicePolicy.map((policy, i) => {
            return (
              <div key={`policy-${i}`} className="sb-16">
                <div className="policy-title">{policy.title}</div>
                <ul className="article-list">
                  {policy.policies.map((article, i) => {
                    return (
                      <li key={`article-${i}`} className="r-14">
                        {article}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServicePolicyPopupModel;
