//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//hooks
import usePreventScrolling from "../../hooks/usePreventScrolling";
//style
import "./MaintenancePopupModel.scss";

interface MaintenancePopupModelProps {
  message: string;
}

const MaintenancePopupModel: React.FC<MaintenancePopupModelProps> = (props) => {
  //hook
  usePreventScrolling();

  return (
    <div className="maintenance-popup-modal">
      <div className="maintenance-popup-container">
        <div className="logo-bg">
          <img
            src={TemplateCardImg.srcLogin_img}
            alt="brand-img"
            draggable="false"
          />
        </div>
        <div className="maintenance-main-right">
          <div className="sb-24">系統維護公告</div>
          <div className="maintenance-content">
            <span className="r-14 message">{props?.message}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenancePopupModel;
