//react
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface DefaultNotifiType {
  showPost: boolean;
  postId: string;
  commentId: string;
  notifiUsed: boolean;
  followerId: string;
  chatroom: string;
  opponent: string;
  systemShow: boolean;
  systemTitle: string;
  systemContent: string;
  postProcessing: boolean;
  postError: boolean;
  setShowPost?: Dispatch<SetStateAction<boolean>>;
  setPostId?: Dispatch<SetStateAction<string>>;
  setCommentId?: Dispatch<SetStateAction<string>>;
  setNotifiUsed?: Dispatch<SetStateAction<boolean>>;
  setFollowerId?: Dispatch<SetStateAction<string>>;
  setChatroom?: Dispatch<SetStateAction<string>>;
  setOpponent?: Dispatch<SetStateAction<string>>;
  setSystemShow?: Dispatch<SetStateAction<boolean>>;
  setSystemTitle?: Dispatch<SetStateAction<string>>;
  setSystemContent?: Dispatch<SetStateAction<string>>;
  setPostProcessing?: Dispatch<SetStateAction<boolean>>;
  setPostError?: Dispatch<SetStateAction<boolean>>;
}

const defaultNotifiContext: DefaultNotifiType = {
  showPost: false,
  postId: "",
  commentId: "",
  notifiUsed: false,
  followerId: "",
  chatroom: "",
  opponent: "",
  systemShow: false,
  systemTitle: "",
  systemContent: "",
  postProcessing: false,
  postError: false,
};

const NotifiContext = createContext<DefaultNotifiType>(defaultNotifiContext);

export const useNotifi = () => useContext(NotifiContext);

interface NotifiProviderProps {
  children: ReactNode; // This is the type for children
}

export const NotifiProvider: React.FC<NotifiProviderProps> = ({ children }) => {
  const [showPost, setShowPost] = useState(false);
  const [postId, setPostId] = useState("");
  const [commentId, setCommentId] = useState("");
  const [notifiUsed, setNotifiUsed] = useState(false); // notification是否點被點擊開啟對應頁面
  const [followerId, setFollowerId] = useState("");
  const [chatroom, setChatroom] = useState("");
  const [opponent, setOpponent] = useState("");
  const [systemShow, setSystemShow] = useState(false);
  const [systemTitle, setSystemTitle] = useState("");
  const [systemContent, setSystemContent] = useState("");
  const [postProcessing, setPostProcessing] = useState(false);
  const [postError, setPostError] = useState(false);

  return (
    <NotifiContext.Provider
      value={{
        showPost,
        setShowPost,
        postId,
        setPostId,
        commentId,
        setCommentId,
        notifiUsed,
        setNotifiUsed,
        followerId,
        setFollowerId,
        chatroom,
        setChatroom,
        opponent,
        setOpponent,
        systemShow,
        setSystemShow,
        systemTitle,
        setSystemTitle,
        systemContent,
        setSystemContent,
        postProcessing,
        setPostProcessing,
        postError,
        setPostError,
      }}
    >
      {children}
    </NotifiContext.Provider>
  );
};
