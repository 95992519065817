//react
import { useState, useRef, useEffect, Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
//components
import Input from "../Input/Input";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Form from "react-bootstrap/Form";
//type
import { TypePostFilter } from "../../types/posts";
//img
import { TemplateNavBarImg } from "../../assets/data/imagesData";
//style
import "./FilterDropdown.scss";

interface FilterDropdownProps {
  title: string;
  options: TypePostFilter[];
  setOption: Dispatch<SetStateAction<string>> | undefined;
  optionSet: string;
}

const FilterDropdown: React.FC<FilterDropdownProps> = (props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [checkedId, setCheckedId] = useState("");
  const [other, setOther] = useState("");
  const [currentCheck, setCurrentCheck] = useState("");

  const location = useLocation();
  const { pathname } = location;

  // filter out option of 其他 for homepage filter
  const dropdownData = props?.options?.filter((option) =>
    pathname === "/editpost" ? option : option.option !== "其他"
  );

  // const handleCheck = (id: string) => {
  //   if (checkedId === id) {
  //     setCheckedId(""); // allows unchecking
  //   } else {
  //     setCheckedId(id);
  //   }
  // };

  // Make dorpdown disappear when cliking outside the dropown
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  // Set checkId to id of current clicked item
  useEffect(() => {
    const currentId = props?.options?.filter(
      (option) => option.option === props.optionSet
    )[0]?.key;

    // To know other option's id
    const otherId = props?.options?.filter(
      (option) => option.option === "其他"
    )[0]?.key;

    // To make sure other checkbox is checked when !currentId && currentCheck === "其他"
    // currentId === undefined && currentCheck === "其他" - 出問題換回這個
    if (!currentId && currentCheck === "其他") {
      setCheckedId(otherId);
    } else {
      setCheckedId(currentId);
    }
  }, [props?.optionSet]);

  return (
    <div className="filter-dropdown-box">
      <div ref={ref}>
        <Button
          className="drowpdown-btn r-16"
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target as HTMLElement);
          }}
        >
          {props?.optionSet !== "null" && props?.optionSet
            ? props?.optionSet
            : props.title}
          <div className={`trigger-icon ${show === true ? "rotate" : ""}`}>
            <img src={TemplateNavBarImg.srcArrow_down_icon} alt="arrow-icon" />
          </div>
        </Button>

        <Overlay
          show={show}
          target={target}
          placement="bottom"
          container={ref}
          //containerPadding={20}
        >
          <Popover>
            <Popover.Body>
              <Form>
                {dropdownData?.map((option) => {
                  return (
                    <div key={option.key} className="checkbox-content">
                      <input
                        type="checkbox"
                        id={option.key}
                        checked={checkedId === option.key}
                        onChange={() => {
                          //handleCheck(option.key);
                          // if (checkedId !== option.key) {
                          //   option.option !== "其他"
                          //     ? props.setOption?.(option.option)
                          //     : props.setOption?.("");
                          //   setCurrentCheck(option.option);
                          //   setOther("");
                          // } else {
                          //   props.setOption?.("");
                          //   setCurrentCheck("");
                          // }

                          if (checkedId !== option.key) {
                            option.option !== "其他"
                              ? props.setOption?.(option.option)
                              : props.setOption?.("");
                            setOther?.("");
                            setCurrentCheck(option.option);
                            setCheckedId(option.key);
                          } else {
                            props.setOption?.("");
                            setCurrentCheck("");
                            setCheckedId("");
                          }
                        }}
                      />
                      <label
                        className="checkbox-label r-14"
                        htmlFor={option.key}
                      >
                        {option.option}
                      </label>
                      <span
                        className={`checkmark ${
                          checkedId === option.key ? "checked" : ""
                        }`}
                      ></span>
                    </div>
                  );
                })}
                {pathname === "/editpost" && currentCheck === "其他" && (
                  <Input
                    type="text"
                    name="other"
                    value={other}
                    placeholder="其他"
                    onChange={(titleInputValue) => {
                      setOther?.(titleInputValue);
                      props.setOption?.(titleInputValue);
                    }}
                  />
                )}
              </Form>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </div>
  );
};

export default FilterDropdown;
