//react
import { Dispatch, SetStateAction, useEffect } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//api
import { thirdPartyLogin } from "../../api/auth";
//context
import { useAuth } from "../../context/AuthContext";
//utility
import closePopup from "../../utils/closePopup";
//hooks
import usePreventScrolling from "../../hooks/usePreventScrolling";
//types
import { FbAuthRes } from "../../types/fbLogin";
//style
import "./AuthPopupModel.scss";

declare global {
  interface Window {
    AppleID: any; // Replace `any` with a more specific type if available
  }
}

interface AuthPopupModalProps {
  setShowLogin: Dispatch<SetStateAction<boolean>> | undefined;
  setShowPolicyOne: Dispatch<SetStateAction<boolean>> | undefined;
  setShowPolicyTwo: Dispatch<SetStateAction<boolean>> | undefined;
}

const AuthPopupModal: React.FC<AuthPopupModalProps> = (props) => {
  //context
  const { storeToken } = useAuth();

  //hook
  usePreventScrolling();

  //Facebook login handler
  const handleFBLogin = () => {
    window.FB.login(function (response: FbAuthRes) {
      if (response.authResponse) {
        //facebookLogin(response?.authResponse?.accessToken, "1");
        const thirdPartyLoginAsync = async () => {
          const res = await thirdPartyLogin(
            response?.authResponse?.accessToken,
            1,
            false
          );
          if (res) {
            props.setShowLogin?.(false);
            storeToken({ token: res?.token });
          }
        };

        thirdPartyLoginAsync();
      } else {
        console.log("User cancelled login or did not fully authorize.");
      }
    });
  };

  //LINE login
  const LINE_LOGIN_CHANNEL_ID = "2003791987";
  const LINE_LOGIN_REDIRECT_URI = "https://highlight11.com/";

  const handleLineLogin = () => {
    const state = Math.random().toString(36).substring(2); // Generate a random state

    // Construct the authorization URL
    const authorizationUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_LOGIN_CHANNEL_ID}&redirect_uri=${encodeURIComponent(
      LINE_LOGIN_REDIRECT_URI
    )}&state=${state}&scope=profile%20openid%20email`;

    // Redirect the user to the authorization URL
    window.location.href = authorizationUrl;
  };

  //Apple login
  useEffect(() => {
    // Ensure the AppleID object exists on the window
    if (window.AppleID) {
      // Initialize Apple ID
      window.AppleID.auth.init({
        clientId: "com.highlight11.highlightweb", // Service ID
        scope: "name email", // Requested scopes
        redirectURI: "https://highlight11.com/",
        state: "state_parameter", // Optional state parameter
        usePopup: true, // Set to true to use a popup window
      });
    }
  }, []);

  const handleAppleLogin = () => {
    if (window.AppleID) {
      window.AppleID.auth
        .signIn()
        .then(async (response: any) => {
          //console.log("Apple response:");

          const res = await thirdPartyLogin(JSON.stringify(response), 3, true);

          if (res) {
            props.setShowLogin?.(false);
            storeToken({ token: res?.token });
          }
        })
        .catch((error: any) => {
          console.error("Apple Sign In Error");
        });
    } else {
      console.error("AppleID is not available on the window object");
    }
  };

  return (
    <div
      className="auth-popup-modal"
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        closePopup(e, "auth-popup-modal", props.setShowLogin);
      }}
    >
      <div className="auth-main-container">
        <div className="close-btn" onClick={() => props.setShowLogin?.(false)}>
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="logo-bg">
          <img
            src={TemplateCardImg.srcLogin_img}
            alt="brand-img"
            draggable="false"
          />
        </div>
        <div className="auth-main-right">
          <div className="sb-24">登入</div>
          <button className="auth-btn primary-btn" onClick={handleFBLogin}>
            <img src={TemplateCardImg.srcFb_log_bg} alt="fb-logo" />
            使用 Facebook 登入
          </button>
          <button className="auth-btn primary-btn" onClick={handleLineLogin}>
            <img src={TemplateCardImg.srcLine_logo_bg} alt="line-logo" />
            使用 LINE 登入
          </button>
          <button className="auth-btn primary-btn" onClick={handleAppleLogin}>
            <img src={TemplateCardImg.srcApple_logo_bg} alt="apple-logo" />
            使用 Apple ID 登入
          </button>
          <div className="service-privacy-policy r-12">
            <div>按下登入表示</div>
            <div className="policy-link">
              <div>您已經閱讀並同意</div>
              <div
                className="link"
                onClick={() => {
                  props.setShowPolicyOne?.(true);
                }}
              >
                服務條款
              </div>
              <div>、</div>
              <div
                className="link"
                onClick={() => {
                  props.setShowPolicyTwo?.(true);
                }}
              >
                隱私權政策
              </div>
              <div>。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthPopupModal;
