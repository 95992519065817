//react
import { useEffect, useState, useRef } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
//components
import AssurePopupModal from "../AssurePopupModel/AssurePopupModel";
import SystemPopup from "../SystemPopup/SystemPopup";
import MediaPopupModel from "../MediaPoupupModel/MediaPopupModel";
//signalR
import { SignalRService } from "../../signalR/SignalRService";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
//context
import { useChat } from "../../context/ChatProvider";
import { useAuth } from "../../context/AuthContext";
//api
import { postUnsendMsg, postDeleteMsg } from "../../api/chat";
// file formats
import { imageFormats, videoFormats } from "../../assets/data/fileFormats";
//img
import {
  TemplateCardImg,
  TemplateOtherImg,
} from "../../assets/data/imagesData";
//style
import "./ChatBubble.scss";

interface FileDisplayProps {
  file: string;
}

const FileDisplay: React.FC<FileDisplayProps> = ({ file }) => {
  const [mediaShow, setMediaShow] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [url, setUrl] = useState("");

  // Determine how to render based on the file type
  const renderFile = () => {
    if (imageFormats.some((format) => file.endsWith(format))) {
      return (
        <div
          onClick={() => {
            setMediaShow(true);
            setUrl(file);
            setIsVideo(false);
          }}
        >
          <img src={file} alt="embeded-img" />
        </div>
      );
    } else if (videoFormats.some((format) => file.endsWith(format))) {
      return (
        <div
          className="msg-file-video"
          onClick={() => {
            setMediaShow(true);
            setUrl(file);
            setIsVideo(true);
          }}
        >
          <video>
            <source src={file} />
            Your browser does not support the video tag.
          </video>
          <img src={TemplateCardImg.srcPlayhead_icon} alt="playhead-icon" />
        </div>
      );
    } else {
      return (
        <div className="file-to-download r-14">
          <a href={file} download={file}>
            {file?.length > 20 ? `...${file?.slice(-20)}` : file}
          </a>
        </div>
      );
    }
  };

  return (
    <>
      <div className="file-content">{renderFile()}</div>
      {mediaShow && (
        <MediaPopupModel
          url={url}
          isVideo={isVideo}
          setMediaShow={setMediaShow}
        />
      )}
    </>
  );
};

interface ChatBubbleProps {
  msg: string;
  msgFiles: string[];
  timestamp: string;
  id: string;
  isRead: boolean;
  connection: SignalRService | null;
}

const ChatBubble: React.FC<ChatBubbleProps> = (props) => {
  const [isHover, setIsHover] = useState(false);
  const [show, setShow] = useState(false); // popup of editing message
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  // context = memberId
  const { currentMember } = useAuth();

  //useQuery
  const queryClient = useQueryClient();

  //route
  const location = useLocation();
  const { pathname } = location;

  // popup of confirming actions of recalling and deleting message
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [title, setTilte] = useState(""); // popup title
  const [context, setContext] = useState(""); // popup context
  const [onClick, setOnclick] = useState<() => void>(() => {});
  const [recallError, setRecallError] = useState(false);

  //context
  const {
    setFetchAgain,
    fetchAgain,
    setMessages,
    receiverId,
    setStopScrolling,
  } = useChat();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  // Avoid scrolling when message popup shows
  // useEffect(() => {
  //   const chatBox = document.querySelector(".chatbox-main-area") as HTMLElement;

  //   if (show) {
  //     chatBox.style.overflowY = "hidden";
  //   } else {
  //     chatBox.style.overflowY = "auto";
  //   }
  // }, [show]);

  // Handler - Recall Message
  const handleUnsendMsg = async () => {
    try {
      const res = await postUnsendMsg(props?.id);

      if (res?.item1 === true) {
        setStopScrolling?.(true);
        setMessages?.([]);
        queryClient.invalidateQueries("/messages");
        setFetchAgain?.(!fetchAgain);
        setConfirmPopup(false);
        setMessages?.((prev) =>
          prev?.filter((message) => message?.id !== props?.id)
        );
        //signalR method - to update chatroom list
        await props?.connection?.UpdateChatroomListInvoke(
          currentMember?.currentMemberId
        );
        await props?.connection?.UpdateChatroomListInvoke(receiverId);
      } else if (res?.item1 === false) {
        setConfirmPopup(false);
        setRecallError(true);
      }
    } catch (error) {
      return error;
    }
  };

  // Handler - Delete Message
  const handleDeleteMsg = async () => {
    try {
      const res = await postDeleteMsg({
        messageId: props?.id,
        currentMemberId: currentMember?.currentMemberId, // current user
      });

      if (res?.success === true) {
        setStopScrolling?.(true);
        setMessages?.([]);
        queryClient.invalidateQueries("/messages");
        setFetchAgain?.(!fetchAgain);
        setConfirmPopup(false);
        setMessages?.((prev) =>
          prev?.filter((message) => message?.id !== props?.id)
        );
        //signalR method - to update chatroom list
        await props?.connection?.UpdateChatroomListInvoke(
          currentMember?.currentMemberId
        );
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <div
        className="chat-bubble-container"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {pathname !== "/support-center" && (
          <div className="edit-message-action" ref={ref}>
            {isHover || show ? (
              <>
                <Button
                  className="edit-message-btn"
                  onClick={(e) => {
                    setShow(!show);
                    setTarget(e.target as HTMLElement);
                  }}
                >
                  <img
                    src={TemplateCardImg.srcDots_menu_icon}
                    alt="dots-icon"
                  />
                </Button>
                <Overlay
                  show={show}
                  target={target}
                  placement="bottom"
                  container={ref}
                >
                  <Popover>
                    <Popover.Body>
                      <div
                        className="message-edit-option sb-14"
                        onClick={() => {
                          setConfirmPopup(true);
                          setTilte("刪除訊息");
                          setContext("您將刪除此訊息，此動作無法復原！");
                          setOnclick(() => handleDeleteMsg);
                        }}
                      >
                        <img
                          src={TemplateCardImg.srcDelete_icon}
                          alt="delete-icon"
                        />
                        <span>刪除</span>
                      </div>
                      <div
                        className="message-edit-option sb-14"
                        onClick={() => {
                          setConfirmPopup(true);
                          setTilte("回收訊息");
                          setContext("您將回收此訊息，此動作無法復原！");
                          setOnclick(() => handleUnsendMsg);
                        }}
                      >
                        <img
                          src={TemplateCardImg.srcUnsend_icon}
                          alt="unsend-icon"
                        />
                        <span>收回</span>
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </>
            ) : (
              ""
            )}
          </div>
        )}
        <div className="bubble-content">
          <div
            className={`msg-files-box ${
              props?.msgFiles && props?.msg && "files-down-space"
            }`}
          >
            {props?.msgFiles?.map(
              (file, i) => file && <FileDisplay key={`file-${i}`} file={file} />
            )}
          </div>
          {props?.msg?.includes("https") ? (
            <a
              className="r-14"
              href={props?.msg}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props?.msg}
            </a>
          ) : (
            <div className="text r-14">{props?.msg}</div>
          )}
          {/* <div className="text r-14">{props?.msg}</div> */}
        </div>
      </div>
      <div className="time-and-read">
        <div className="msg-timestamp r-12">{props?.timestamp}</div>
        {props?.isRead && <div className="is-read r-12">已讀</div>}
      </div>
      {confirmPopup && (
        <AssurePopupModal
          title={title}
          context={context}
          setConfirmPopup={setConfirmPopup}
          onClick={onClick}
        />
      )}
      {recallError && (
        <SystemPopup
          popupMsg={"對方已讀不可回收"}
          popupIcon={TemplateOtherImg.srcFail_icon}
          setShow={setRecallError}
        />
      )}
    </>
  );
};

export default ChatBubble;
