//react
import {
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
//components
import PostPopupModel from "../PostPopupModel/PostPopupModel";
//api
import { postLikePost } from "../../api/posts";
//context
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//type
import { TypePost } from "../../types/posts";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./HouseItemCard.scss";

interface HouseItemCardProps {
  post: TypePost;
}

const HouseItemCardFunction: ForwardRefRenderFunction<
  HTMLDivElement,
  HouseItemCardProps
> = (props, ref) => {
  const [isShow, setIsShow] = useState(false);
  const [isLiked, setIsLiked] = useState(props?.post?.isLiked);
  const [likes, setLikes] = useState(props?.post?.likes);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  // context - memberId
  const { currentMember, setShowAuth, isAuthenticated } = useAuth();

  const { refetchProfile, setRefetchProfile } = usePost();

  const handleLikePostAsync = async () => {
    const res = await postLikePost(
      currentMember?.currentMemberId,
      props?.post?.id
    );

    if (res?.message === "Post Liked.") {
      setIsLiked(true);
      setLikes((prev) => prev + 1);
      pathname.includes("myinfo") && setRefetchProfile?.(!refetchProfile);
    } else {
      setIsLiked(false);
      setLikes((prev) => prev - 1);
      pathname.includes("myinfo") && setRefetchProfile?.(!refetchProfile);
    }
  };

  // 假如使用者彈窗貼文按Like, 貼文牆也可以不透過refetch去即時渲染
  const { isLikedText, id } = usePost();

  useEffect(() => {
    if (id === props?.post?.id) {
      if (isLikedText === "Post Liked.") {
        setIsLiked(true);
        setLikes((prev) => prev + 1);
      } else {
        setIsLiked(false);
        setLikes((prev) => prev - 1);
      }
    }
  }, [id, isLikedText, props?.post?.id]);

  return (
    <>
      {ref ? (
        <div ref={ref} className="house-itemcard-container">
          <div className="house-item-present" onClick={() => setIsShow(true)}>
            <div className="house-card-pic">
              <img
                src={
                  // props?.post?.extraProperties?.CoverImage
                  //   ? props?.post?.extraProperties?.CoverImage
                  //   : props?.post?.extraProperties?.Images?.[0]
                  props?.post?.extraProperties?.MergedVideo ||
                  props?.post?.extraProperties?.Video
                    ? props?.post?.extraProperties?.CompressedCoverImage
                      ? props?.post?.extraProperties?.CompressedCoverImage
                      : props?.post?.extraProperties?.CoverImage
                    : props?.post?.extraProperties?.CompressedImage
                    ? props?.post?.extraProperties?.CompressedImage
                    : props?.post?.extraProperties?.Images?.[0]
                }
                alt="property-pic"
              />
            </div>
            <div className="top-card-info">
              <div
                className="owner-pic"
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    props?.post?.memberId !== currentMember?.currentMemberId
                  ) {
                    navigate(
                      `/userinfo/${props?.post?.memberId.substring(0, 16)}`,
                      {
                        state: { userId: props?.post?.memberId },
                      }
                    );
                  } else {
                    navigate(
                      `/myinfo/${currentMember?.currentMemberId.substring(
                        0,
                        16
                      )}`
                    );
                  }
                }}
              >
                <img src={props?.post?.memberAvatar} alt="post-avatar" />
                <span className="r-14">{props?.post?.memberName}</span>
              </div>
              <div
                className={`likes-action ${isLiked && "liked-by-user"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  isAuthenticated ? handleLikePostAsync() : setShowAuth?.(true);
                }}
              >
                <img
                  src={TemplateCardImg.srcEmpty_heart_icon}
                  alt="heart-icon"
                />
                <span className="r-14">{Number(likes)}</span>
              </div>
            </div>
            {props?.post?.extraProperties?.CoverImage && (
              <div className="playhead-if-video">
                <img
                  src={TemplateCardImg?.srcPlayhead_icon}
                  alt="playhead-icon"
                />
              </div>
            )}
          </div>
          <div
            className="house-item-description"
            onClick={() => setIsShow(true)}
          >
            <span className="description r-14">{props?.post?.title}</span>
          </div>
          {isShow && (
            <PostPopupModel postId={props?.post?.id} setIsShow={setIsShow} />
          )}
        </div>
      ) : (
        <div className="house-itemcard-container">
          <div className="house-item-present" onClick={() => setIsShow(true)}>
            <div className="house-card-pic">
              <img
                src={
                  props?.post?.extraProperties?.CoverImage
                    ? props?.post?.extraProperties?.CoverImage
                    : props?.post?.extraProperties?.Images?.[0]
                }
                alt="property-pic"
              />
            </div>
            <div className="top-card-info">
              <div
                className="owner-pic"
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    props?.post?.memberId !== currentMember?.currentMemberId
                  ) {
                    navigate(
                      `/userinfo/${props?.post?.memberId.substring(0, 16)}`,
                      {
                        state: { userId: props?.post?.memberId },
                      }
                    );
                  } else {
                    navigate(
                      `/myinfo/${currentMember?.currentMemberId.substring(
                        0,
                        16
                      )}`
                    );
                  }
                }}
              >
                <img src={props?.post?.memberAvatar} alt="post-avatar" />
                <span className="r-14">{props?.post?.memberName}</span>
              </div>
              <div
                className={`likes-action ${isLiked && "liked-by-user"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  isAuthenticated ? handleLikePostAsync() : setShowAuth?.(true);
                }}
              >
                <img
                  src={TemplateCardImg.srcEmpty_heart_icon}
                  alt="heart-icon"
                />
                <span className="r-14">{Number(likes)}</span>
              </div>
            </div>
            {props?.post?.extraProperties?.CoverImage && (
              <div className="playhead-if-video">
                <img
                  src={TemplateCardImg?.srcPlayhead_icon}
                  alt="playhead-icon"
                />
              </div>
            )}
          </div>
          <div
            className="house-item-description"
            onClick={() => setIsShow(true)}
          >
            <span className="description r-14">{props?.post?.title}</span>
          </div>
          {isShow && (
            <PostPopupModel
              postId={props?.post?.id}
              setIsShow={(show) => setIsShow(show)}
            />
          )}
        </div>
      )}
    </>
  );
};

const HouseItemCard = forwardRef(HouseItemCardFunction);

export default HouseItemCard;
