//react
import { Dispatch, SetStateAction } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";

interface EditWarningPopupProps {
  title: string;
  context: string;
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
}

const EditWarningPopup: React.FC<EditWarningPopupProps> = (props) => {
  return (
    <div className="assure-popup-model">
      <div className="assure-popup-main">
        <div
          className="close-btn"
          onClick={() => props?.setConfirmPopup(false)}
        >
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="popup-content">
          <div className="title sb-24">{props?.title}</div>
          <div className="context r-16">{props?.context}</div>
        </div>
        <div className="btn-group">
          <button
            className="primary-btn"
            onClick={() => props?.setConfirmPopup(false)}
          >
            了解
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditWarningPopup;
