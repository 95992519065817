//react
import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
//context
import { useNotifi } from "../../context/NotifiProvider";
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//api
import { postIsReadNotifi } from "../../api/users";
//type
import { TypeNotifi } from "../../types/user";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./NotificationCard.scss";

interface NotificationCardProps {
  notifi: TypeNotifi;
  setShow: Dispatch<SetStateAction<boolean>>;
}

// render time gap between the current time and date of notification
function formatTimeDifference(timeString: string): string {
  const currentTime: Date = new Date();
  const eventTime: Date = new Date(timeString);
  const timeDiff: number = currentTime.getTime() - eventTime.getTime();

  const seconds: number = timeDiff / 1000;
  const minutes: number = seconds / 60;
  const hours: number = minutes / 60;
  const days: number = hours / 24;
  const weeks: number = days / 7;

  if (seconds < 5) {
    return `現在`;
  } else if (minutes <= 5) {
    return `剛剛`;
  } else if (hours < 1) {
    return `${Math.floor(minutes)} 分鐘前`;
  } else if (days < 1) {
    return `${Math.floor(hours)} 小時前`;
  } else if (days < 7) {
    return `${Math.floor(days)} 天前`;
  } else {
    return `${Math.floor(weeks)} 周前`;
  }
}

const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  //context
  const {
    setPostId,
    setCommentId,
    setNotifiUsed,
    setFollowerId,
    setChatroom,
    setOpponent,
    setSystemTitle,
    setSystemContent,
    setSystemShow,
  } = useNotifi();

  const { hasContent, triggerPopup } = usePost();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  // context - auth
  const { currentMember } = useAuth();

  // label notification as read message
  const handleUpdateIsRead = async () => {
    try {
      const res = await postIsReadNotifi(props?.notifi?.id);
      if (res) {
        queryClient.invalidateQueries("notifications");
      }
    } catch (error) {
      return error;
    }
  };

  // navigate to related content accordingly
  const handleNvigation = (type: string) => {
    let navPath;

    if (type === "Post") {
      props?.notifi?.receiverMemberId !== currentMember?.currentMemberId
        ? (navPath = "/")
        : (navPath = `/myinfo/${currentMember?.currentMemberId?.substring(
            0,
            16
          )}`);
      setPostId?.(props?.notifi?.postId);
    } else if (type === "Comment") {
      props?.notifi?.receiverMemberId !== currentMember?.currentMemberId
        ? (navPath = "/")
        : (navPath = `/myinfo/${currentMember?.currentMemberId?.substring(
            0,
            16
          )}`);
      setPostId?.(props?.notifi?.postId);
      setCommentId?.(props?.notifi?.commentId);
    } else if (type === "Follow") {
      navPath = `/userinfo/${props?.notifi?.senderMemberId.substring(0, 16)}`;
      setFollowerId?.(props?.notifi?.senderMemberId);
    } else if (type === "Message") {
      navPath = "/message-main";
      setChatroom?.(props?.notifi?.chatroomId);
      setOpponent?.(props?.notifi?.senderMemberId);
    } else if (type === "System Notify") {
      setSystemTitle?.(props?.notifi?.title);
      setSystemContent?.(props?.notifi?.body);
      setSystemShow?.(true);
    } else if (type === "Customer Service") {
      navPath = "/support-center";
    } else {
      return;
    }

    type !== "System Notify" && setNotifiUsed?.(true);
    props?.setShow(false); // close popup box of notificaitons

    !hasContent && navPath && navigate(navPath);
    hasContent && navPath && triggerPopup(navPath);
  };

  return (
    <div
      className={`notif-card-box ${props?.notifi?.isRead && "notif-is-read"}`}
      onClick={() => {
        handleUpdateIsRead();
        handleNvigation(props?.notifi?.notificationType);
      }}
    >
      <div className="notif-user-img">
        <img
          src={
            props?.notifi?.notificationType === "System Notify" ||
            props?.notifi?.body === "貼文影片已串接完成"
              ? TemplateCardImg.srcService_logo
              : props?.notifi?.senderMemberAvatar
          }
          alt="user-img"
        />
      </div>
      <div className="notif-content">
        <span className="user-name sb-14">
          {props?.notifi?.notificationType === "System Notify"
            ? null
            : props?.notifi?.userName}
        </span>
        <span className="r-14">
          {props?.notifi?.notificationType === "System Notify"
            ? props?.notifi?.title
            : props?.notifi?.body}
        </span>
      </div>
      <div className="notif-date r-12">
        {formatTimeDifference(props?.notifi?.time)}
      </div>
    </div>
  );
};

export default NotificationCard;
