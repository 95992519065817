//react
import { Dispatch, SetStateAction, useState } from "react";
//components
import HasPlanPopupModel from "../HasPlanPopupModel/HasPlanPopupModel";
//context
import { useAuth } from "../../context/AuthContext";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//type
import { TypeMemberPlan } from "../../types/membership";
//style
import "./PackageDealCard.scss";

interface PackageDealCardProps {
  plan: TypeMemberPlan;
  setShowTXN: Dispatch<SetStateAction<boolean>>;
  setPlanId: Dispatch<SetStateAction<string>>;
  hasPlan: boolean;
}

const PackageDealCard: React.FC<PackageDealCardProps> = (props) => {
  const [isShow, setIsShow] = useState(false);

  const cardTitle = props?.plan?.orderType;

  // Define className for different styles of cards and paln icons
  let cardStyle = "";
  let planIcon = "";
  switch (cardTitle) {
    case "個人方案":
      cardStyle = "plan-a";
      planIcon = TemplateCardImg.srcPlan_individual_icon;
      break;
    case "入門方案":
      cardStyle = "plan-b";
      planIcon = TemplateCardImg.srcPlan_entry_icon;
      break;
    case "新手方案":
      cardStyle = "plan-c";
      planIcon = TemplateCardImg.srcPlan_rookie_icon;
      break;
    case "黃金方案":
      cardStyle = "plan-e";
      planIcon = TemplateCardImg.srcPlan_gold_icon;
      break;
    default:
      break;
  }

  // context - auth
  const { isAuthenticated, setShowAuth } = useAuth();

  return (
    <>
      <div className={`custom-deal-card-container ${cardStyle}`}>
        <div className="deal-icon">
          <img src={planIcon} alt="deal-icon" />
        </div>
        <div className="deal-content">
          <div className="deal-title sb-24">{props?.plan?.orderType}</div>
          <div className="deal-price">
            <span className="sb-16">
              NT$ {props?.plan?.currentPrice?.toLocaleString("en-US")}
            </span>
            <span className="sb-14">
              NT$ {props?.plan?.originalPrice?.toLocaleString("en-US")}
            </span>
          </div>
          <div className="deal-benefit">
            <ul className="r-14">
              <li>後製筆數： {props?.plan?.availablePostProduction}筆</li>
              <li>廣告筆數： {props?.plan?.availableAds}筆</li>
              <li>廣告單價： {props?.plan?.adsPrice}/筆</li>
              <li>廣告時間： {props?.plan?.days}天</li>
            </ul>
          </div>
          <button
            className="order-btn primary-btn"
            onClick={
              isAuthenticated
                ? () => {
                    props?.setPlanId(props?.plan?.id);
                    !props?.hasPlan && props?.setShowTXN(true);
                    props?.hasPlan && setIsShow(true);
                  }
                : () => setShowAuth?.(true)
            }
          >
            選購
          </button>
        </div>
      </div>
      {isShow && <HasPlanPopupModel setIsShow={setIsShow} />}
    </>
  );
};

export default PackageDealCard;
