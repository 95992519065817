//component
import TitleHeader from "../../components/TitleHeader/TitleHeader";
//style
import "./AboutUsPage.scss";

const AboutUsPage = () => {
  return (
    <>
      <TitleHeader title={"關於我們"} />
      <div className="about-us-main-container main-body-spacing">
        <div className="about-us-main r-16">
          <div className="intro">
            專業能力、執行落地、持續創新、績效達成—是我們HighLight
            House全體夥伴的目標，也是使命，我們保持匠人精神不斷持續精進與優化，並致力於打造台灣房產界最熱門的社群平台
            !
          </div>
          <div className="benefit-of-use">
            <div className="title sb-24">
              完整客製化服務，致力於提供最專業的剪輯服務
            </div>
            <div className="description">
              有多年拍攝經驗的我們，了解客戶需求是非常重要的一環。現今人們休閒模式逐漸改變，FACEBOOK、IG這些各大媒體社群上，出現大量的短影音廣告與影片，而房地產業也不得不從過去的圖文廣告進而去改變。經過團隊不斷努力與測試，我們將統整一套簡潔便利的客製化服務，讓有買賣需求的客戶們都能輕鬆使用事半功倍。
            </div>
            <div className="target-clients">
              <div className="client-des">
                <span>【關於使用者們】</span>
                <span>
                  我們擁有輕鬆簡潔的介面，全齡都合適的操作流程，只要點擊關注和最愛，就能替你篩選鎖定你的需求，主打短影片的呈現方式，讓使用者都能輕鬆賞屋。
                </span>
              </div>
              <div className="client-des">
                <span>【關於售屋的你】</span>
                <span>
                  對於想售屋卻不是房仲的你，照片跟廣告是不是很難上手?影音廣告絕對是你最好的選擇，只要加入會員就能輕鬆使用一套便捷的自動影片剪輯器，或者選擇平台最優惠的影片製作，就能讓你有最豐富的短視頻廣告，售屋不在是難事。
                </span>
              </div>
              <div className="client-des">
                <span>【關於想買屋的你】</span>
                <span>
                  想買屋卻時刻需要留意各大平台上的房屋消息，或者總是受到照騙的引誘讓你白跑一趟，HIGHLIGHT就是你最輕鬆的賞屋選擇，用最直接的呈現方式讓你擁有親臨現場的感受，對於特地區域的喜愛，我們也替你紀錄並鎖定，只給予最想要的第一手資訊。
                </span>
              </div>
              <div className="client-des">
                <span>【關於仲介的你】</span>
                <span>
                  平常開發行銷總是耗時耗力，卻又做不出想要的廣告效果，讓你的物件被埋沒在茫茫人海中，HIGHLIGHT就是要替你省時又省力，加入會員就有專人替你製作高品質的視頻廣告，且不限其餘平台投放，還能直接經營個人品牌，儲存過去的所有物件商品，讓你的品牌形象和廣告，能發揮最大其功效。
                </span>
              </div>
            </div>
          </div>
          <div className="why-choose-us r-24">
            如果你也想透過
            <span className="point-out stress">短影片</span>獲取大量的
            <span className="point-out">流量</span>與
            <span className="point-out">資訊</span>，HIGHLIGHT
            HOUSE絕對是你最好的選擇。
          </div>
          <div className="contact-info r-14">
            <span>【聯絡資訊】</span>
            <span>公司：門澄數位有限公司</span>
            <span>統一編號：94209158</span>
            <span>公司地址：(40854)臺中市南屯區東興路一段369號1樓</span>
            <span>聯絡電話：04-23150633</span>
            <span>公司信箱：highlight11@gmail.com</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
