//react
//react
import { Dispatch, SetStateAction } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./MediaPopupModel.scss";

interface MediaPopupModelProps {
  url: string;
  isVideo: boolean;
  setMediaShow: Dispatch<SetStateAction<boolean>>;
}

const MediaPopupModel: React.FC<MediaPopupModelProps> = (props) => {
  return (
    <div className="media-popup-modal">
      <div className="media-main-container">
        <div className="close-btn" onClick={() => props?.setMediaShow(false)}>
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="media-box">
          {props?.isVideo ? (
            <video
              src={props?.url}
              controls
              disablePictureInPicture
              autoPlay
            ></video>
          ) : (
            <img src={props?.url} alt="popup-img" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaPopupModel;
