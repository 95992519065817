import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA7aBiq8ViJL5d1hik0A7aTeVoAzRnNEsQ",
  authDomain: "highlight-kooco.firebaseapp.com",
  projectId: "highlight-kooco",
  storageBucket: "highlight-kooco.appspot.com",
  messagingSenderId: "791710807296",
  appId: "1:791710807296:web:30ec867803c8007007efe5",
  measurementId: "G-7C8S6GJEH8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
export const messaging = getMessaging(app);

export const generateToken = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      // Request permission and get token
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BCknPb2qNCZTiirq_IsNznq3n1Kb2mmKbOvjrnE3akXRGnCIzwli-qKRtplAE9MYRJrF9aTgVI4Cfa4a4a8Q4Qk",
      });

      if (currentToken) {
        // Handle the token as needed, e.g., send it to your server
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    } else {
      console.log("Notification permission not granted.");
      return null;
    }
  } catch (err) {
    console.log("An error occurred while retrieving token.");
    return null;
  }
};

export const receiveNotification = () => {
  try {
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  } catch (error) {
    //throw error;
  }
};
