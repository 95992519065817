//react
import { Dispatch, SetStateAction, KeyboardEvent } from "react";
//style
import "./ReplyInput.scss";

interface ReplyInputProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  isFocused: boolean;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
  setIsIME: Dispatch<SetStateAction<boolean>>;
  onKeyDown?: (event: KeyboardEvent<Element>) => void;
}

const ReplyInput: React.FC<ReplyInputProps> = (props) => {
  return (
    <div className="reply-input-box">
      <textarea
        className={`reply-input r-16 ${
          props?.isFocused && "reply-input-focused"
        }`}
        value={props.value}
        placeholder={props?.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        onFocus={(e) => {
          props?.setIsFocused(true);
        }}
        onCompositionStart={() => props?.setIsIME(true)}
        onCompositionEnd={() => props?.setIsIME(false)}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
};

export default ReplyInput;
