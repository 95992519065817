//react
import { useEffect } from "react";
import { useInfiniteQuery } from "react-query";
//components
import CommentBubble from "../CommentBubble/CommentBubble";
import NestedComment from "../NestedComment/NestedComment";
//api
import { getCommentsById } from "../../api/posts";
import { getCommentsWithoutLoggedIn } from "../../api/posts";
//cotext
import { useNotifi } from "../../context/NotifiProvider";
import { useAuth } from "../../context/AuthContext";
//type
import { TypeComments } from "../../types/posts";
//style
import "./CommentsBox.scss";

interface CommentsBoxProps {
  postId: string;
  commentSecRef: React.RefObject<HTMLDivElement>;
}

const CommentsBox: React.FC<CommentsBoxProps> = (props) => {
  // context - memberId
  const { currentMember, isAuthenticated } = useAuth();

  const {
    fetchNextPage, //function
    hasNextPage, // boolean
    isFetchingNextPage, // boolean
    data, // response from api
    status,
    error,
  } = useInfiniteQuery<TypeComments[], Error>(
    "/comments",
    ({ pageParam = 0 }) =>
      isAuthenticated
        ? getCommentsById({
            postId: props?.postId,
            currentMember: currentMember?.currentMemberId,
          })
        : getCommentsWithoutLoggedIn(props?.postId)
    // {
    //   getNextPageParam: (lastPage, allPages) => {
    //     return lastPage?.length ? allPages.length + 1 : undefined;
    //   },
    // }
  );

  // tag which user whom this comment is replying to
  let replyTo = "";

  // notification
  const { commentId, notifiUsed } = useNotifi();

  useEffect(() => {
    if (notifiUsed) {
      const element = document.getElementById(commentId);
      if (element) {
        element.scrollIntoView({ block: "start" });
      }
    }
  }, []);

  return (
    <>
      <div ref={props?.commentSecRef} className="comments-box-container">
        {data?.pages[0]?.length ? <div className="sec-divider-line"></div> : ""}
        {data?.pages.map((pg) => {
          return pg?.map((comment) => {
            return (
              <div className="parent-comment">
                <CommentBubble key={comment?.id} comment={comment} />
                {comment?.comments &&
                  comment?.comments?.map((comment) => {
                    if (comment?.comments) {
                      replyTo = comment?.memberName;
                    }
                    return (
                      <div className="child-comment">
                        <CommentBubble key={comment?.id} comment={comment} />
                        {comment?.comments &&
                          comment?.comments?.map((comment) => {
                            return (
                              <NestedComment
                                key={comment?.id}
                                comment={comment}
                                replyTo={replyTo}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            );
          });
        })}
      </div>
    </>
  );
};

export default CommentsBox;
