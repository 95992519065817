//React
import { useState, useEffect } from "react";
//components
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import ServiceRoomMessages from "../../components/ServiceRoomMessages/ServiceRoomMessages";
//context
import { useAuth } from "../../context/AuthContext";
//signalR
import { SignalRService } from "../../signalR/SignalRService";
//api
import { postGetOrCreateRoomId } from "../../api/chat";
//context
import { useChat } from "../../context/ChatProvider";
import { useNotifi } from "../../context/NotifiProvider";
//style
import "./ServiceCenter.scss";

const ServiceCenter = () => {
  const [connection, setConnection] = useState<SignalRService | null>(null);

  //context
  const {
    isReconnected,
    setReceiverId,
    setAllFiles,
    setSelectedRoomId,
    setStopScrolling,
    selectedRoomId,
    setIsReconnected,
  } = useChat();

  //memberId
  const { currentMember } = useAuth();

  useEffect(() => {
    return () => {
      setSelectedRoomId?.(""); // Clean up selectedRoomId when component unmounts
      setReceiverId?.(""); // Clean up receiverId when component unmounts
      setAllFiles?.([]); // Clean up uploaded files when component unmounts
      setStopScrolling?.(false);
    };
  }, []);

  // Set up recivers id with service center id
  useEffect(() => {
    setReceiverId?.("fb7d07d7-2125-ee48-e161-3a12b8c34640");
  }, []);

  // Get or Create chatroom id for Service chatroom
  useEffect(() => {
    const getServiceRoomMsgAsync = async () => {
      try {
        const ids = await postGetOrCreateRoomId({
          senderId: currentMember?.currentMemberId, // current user id
          receiverId: "fb7d07d7-2125-ee48-e161-3a12b8c34640",
        });

        if (ids) {
          setSelectedRoomId?.(ids.chatroomId);
        }
      } catch (error) {
        return error;
      }
    };

    getServiceRoomMsgAsync();
  }, [currentMember?.currentMemberId]);

  // sate to control refetch data as reconnecting
  const isReconnectedToFetch = () => {
    setIsReconnected?.(!isReconnected);
  };

  // Setup SignalR service
  useEffect(() => {
    const signalRService = new SignalRService(
      "https://api.highlight11.com/chathub",
      currentMember?.currentMemberId,
      isReconnectedToFetch
    );
    setConnection(signalRService);

    // Clean up the connection when the component unmounts
    return () => {
      signalRService.stopConnection();
    };
  }, [currentMember?.currentMemberId]);

  // Start connection with ID
  useEffect(() => {
    if (connection && currentMember?.currentMemberId) {
      const connectWithId = async () => {
        try {
          const hasConnected = await connection.startConnection();

          if (hasConnected) {
            await connection.receiveConnectionIds();

            await connection.startConnectionWithId();
          }
        } catch (error) {
          return error;
        }
      };

      connectWithId();
    }
  }, [connection, currentMember?.currentMemberId]);

  //notification
  const { setNotifiUsed } = useNotifi();

  useEffect(() => {
    return () => {
      setNotifiUsed?.(false);
    };
  }, []);

  return (
    <>
      <TitleHeader title="客服中心" />
      <div className="service-center-main-container main-body-spacing">
        <div className="service-answer-sec">
          {selectedRoomId && <ServiceRoomMessages connection={connection} />}
        </div>
      </div>
    </>
  );
};

export default ServiceCenter;
