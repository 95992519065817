//react
import { Dispatch, SetStateAction, useState } from "react";
//components
import HasPlanPopupModel from "../HasPlanPopupModel/HasPlanPopupModel";
//context
import { useAuth } from "../../context/AuthContext";
//api
import { TypeMemberPlan } from "../../types/membership";
//style
import "./CustomDealCard.scss";

interface PackageDealCardProps {
  plan: TypeMemberPlan;
  setShowTXN: Dispatch<SetStateAction<boolean>>;
  setPlanId: Dispatch<SetStateAction<string>>;
  hasPlan: boolean;
}

const CustomDealCard: React.FC<PackageDealCardProps> = (props) => {
  const [isShow, setIsShow] = useState(false);

  const cardTitle = props?.plan?.orderType;

  // Define className for different styles of cards
  let cardStyle = "";
  switch (cardTitle) {
    case "菜鳥入門":
      cardStyle = "plan-a";
      break;
    case "小試身手":
      cardStyle = "plan-b";
      break;
    case "好康優惠":
      cardStyle = "plan-c";
      break;
    case "大展身手":
      cardStyle = "plan-d";
      break;
    case "超級業務":
      cardStyle = "plan-e";
      break;
    default:
      break;
  }

  // context - auth
  const { isAuthenticated, setShowAuth } = useAuth();

  return (
    <>
      <div className={`package-deal-card-container ${cardStyle}`}>
        <div className="deal-content">
          <div className="deal-title sb-24">{props?.plan?.orderType}</div>
          <div className="deal-price">
            <span className="sb-16">
              NT$ {props?.plan?.currentPrice?.toLocaleString("en-US")}
            </span>
            <span className="sb-14">
              NT$ {props?.plan?.originalPrice?.toLocaleString("en-US")}
            </span>
          </div>
          <div className="deal-benefit">
            <ul className="r-14">
              <li>廣告筆數： {props?.plan?.availableAds}筆</li>
              <li>廣告單價： {props?.plan?.adsPrice}/筆</li>
              <li>廣告時間： {props?.plan?.days}天</li>
            </ul>
          </div>
          <button
            className="order-btn primary-btn"
            onClick={
              isAuthenticated
                ? () => {
                    props?.setPlanId(props?.plan?.id);
                    !props?.hasPlan && props?.setShowTXN(true);
                    props?.hasPlan && setIsShow(true);
                  }
                : () => setShowAuth?.(true)
            }
          >
            選購
          </button>
        </div>
      </div>
      {isShow && <HasPlanPopupModel setIsShow={setIsShow} />}
    </>
  );
};

export default CustomDealCard;
