//react
import { useState, useEffect } from "react";
//components
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import PackageDeals from "./TabContent/PackageDeals";
import PurchasedDeals from "./TabContent/PurchasedDeals";
//api
import { getOrderPlanList } from "../../api/membership";
//context
import { useAuth } from "../../context/AuthContext";
//type
import { TypeMemberPlan } from "../../types/membership";
//style
import "./MembershipPage.scss";

const MembershipPage = () => {
  const [plans, setPlans] = useState<TypeMemberPlan[]>([
    {
      adsPrice: 0,
      availableAds: 0,
      availablePostProduction: null,
      currentPrice: 0,
      days: 0,
      id: "",
      orderPlan: 0,
      orderType: "",
      originalPrice: 0,
    },
  ]);
  const [selectedTab, setSlelectedTab] = useState("已購買方案");

  //context - auth
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const getOrderPlanAsync = async () => {
      try {
        const plans = await getOrderPlanList();

        setPlans(plans);
      } catch (error) {
        return error;
      }
    };

    getOrderPlanAsync();
  }, []);

  useEffect(() => {
    !isAuthenticated && setSlelectedTab("享優惠組合方案");
  }, [isAuthenticated]);

  return (
    <>
      <TitleHeader title="會員方案" />
      <div className="membership-main-container main-body-spacing">
        <div className="tabs-btn-group">
          {isAuthenticated && (
            <button
              className={`primary-btn ${
                selectedTab === "已購買方案" ? "secondary-btn" : ""
              }`}
              onClick={() => setSlelectedTab("已購買方案")}
            >
              已購買方案
            </button>
          )}
          <button
            className={`primary-btn ${
              selectedTab === "享優惠組合方案" ? "secondary-btn" : ""
            }`}
            onClick={() => setSlelectedTab("享優惠組合方案")}
          >
            享優惠組合方案
          </button>
          <button
            className={`primary-btn ${
              selectedTab === "享輕鬆客制方案" ? "secondary-btn" : ""
            }`}
            onClick={() => setSlelectedTab("享輕鬆客制方案")}
          >
            享輕鬆客制方案
          </button>
        </div>
        {selectedTab === "已購買方案" ? (
          <PurchasedDeals />
        ) : (
          <PackageDeals selectedTab={selectedTab} plans={plans} />
        )}
      </div>
    </>
  );
};

export default MembershipPage;
