import "./TextArea.scss";

interface textAreaProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}

const TextArea: React.FC<textAreaProps> = (props) => {
  return (
    <>
      <textarea
        className="primary-textarea r-16"
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </>
  );
};

export default TextArea;
