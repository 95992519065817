//react
import { useState, useRef, useEffect } from "react";
//react share
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
  FacebookIcon,
  LineIcon,
  TwitterIcon,
} from "react-share";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
//type
import { TypePostDetail } from "../../types/posts";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./SocialMediaShare.scss";

interface SocialMediaShareProps {
  postDetail: TypePostDetail;
}

const SocialMediaShare: React.FC<SocialMediaShareProps> = (props) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  // Current page url
  const currentPgaeUrl = window.location.href;

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  return (
    <div className="social-media-share-box" ref={ref}>
      <Button
        className="share-popup-btn"
        onClick={(e) => {
          setShow(!show);
          setTarget(e.target as HTMLElement);
        }}
      >
        <img src={TemplateCardImg.srcShare_icon} alt="share-icon" />
      </Button>

      <Overlay show={show} target={target} placement="top" container={ref}>
        <Popover>
          <Popover.Body>
            <EmailShareButton
              url={`https://highlight11.com/post/${props?.postDetail?.id}`}
              subject={props?.postDetail?.title}
            >
              <img src={TemplateCardImg.srcEmail_icon} alt="email-icon" />
            </EmailShareButton>
            <FacebookShareButton
              title={props?.postDetail?.title}
              url={`https://highlight11.com/post/${props?.postDetail?.id}`}
            >
              <FacebookIcon />
            </FacebookShareButton>
            <LineShareButton
              title={props?.postDetail?.title}
              url={`https://highlight11.com/post/${props?.postDetail?.id}`}
            >
              <LineIcon />
            </LineShareButton>
            <TwitterShareButton
              title={props?.postDetail?.title}
              url={`https://highlight11.com/post/${props?.postDetail?.id}`}
            >
              <TwitterIcon />
            </TwitterShareButton>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default SocialMediaShare;
