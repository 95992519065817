//react
import { Dispatch, SetStateAction } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";

interface HasPlanPopupModelProps {
  setIsShow: Dispatch<SetStateAction<boolean>>;
}

const HasPlanPopupModel: React.FC<HasPlanPopupModelProps> = (props) => {
  return (
    <div className="plan-popup-model">
      <div className="assure-popup-main">
        <div className="popup-top-bg">
          <img src={TemplateCardImg?.srcReport_bg} alt="popup-bg" />
        </div>
        <div className="popup-content">
          <div className="title sb-24">尚有未到期方案</div>
          <div className="context r-16">目前方案未到期，無法購買新方案</div>
        </div>
        <div className="btn-group">
          <button
            className="primary-btn"
            onClick={() => props?.setIsShow(false)}
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  );
};

export default HasPlanPopupModel;
