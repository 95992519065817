//react
import {
  createContext,
  useState,
  useRef,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  RefObject,
} from "react";

interface DefaultCommentType {
  replyToCmntId: string;
  tagUser: string;
  inputRef: RefObject<HTMLTextAreaElement> | null;
  setReplyToCmntId?: Dispatch<SetStateAction<string>>;
  setTagUser?: Dispatch<SetStateAction<string>>;
}

const defaultCommentContext: DefaultCommentType = {
  replyToCmntId: "",
  tagUser: "",
  inputRef: null,
};

const CommentContext = createContext<DefaultCommentType>(defaultCommentContext);

export const useComment = () => useContext(CommentContext);

interface CommentProviderProps {
  children: ReactNode; // This is the type for children
}

export const CommentProvider: React.FC<CommentProviderProps> = ({
  children,
}) => {
  const [replyToCmntId, setReplyToCmntId] = useState("");
  const [tagUser, setTagUser] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);

  return (
    <CommentContext.Provider
      value={{
        replyToCmntId,
        setReplyToCmntId,
        tagUser,
        setTagUser,
        inputRef,
      }}
    >
      {children}
    </CommentContext.Provider>
  );
};
