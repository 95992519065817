//react
import { useState, useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
//api
import { getUserInfo } from "../../api/users";
import { postLikeOrDislikeComment, getDeleteComment } from "../../api/posts";
//conext
import { useComment } from "../../context/CommentProvider";
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//type
import { TypeComments } from "../../types/posts";
import { TypeUserInfo } from "../../types/user";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./CommentBubble.scss";

interface CommentBubbleProps {
  comment: TypeComments;
}

const CommentBubble: React.FC<CommentBubbleProps> = (props) => {
  const [isHover, setIsHover] = useState(false);
  const [show, setShow] = useState(false); // popup of editing message
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  // context - report
  const {
    setReportShow,
    setRepostId,
    setReportType,
    setReportedId,
    setContent,
  } = usePost();

  const navigate = useNavigate();

  // context - memberId
  const { currentMember, isAuthenticated, setShowAuth } = useAuth();

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  //context
  const { setReplyToCmntId, setTagUser, inputRef } = useComment();

  // To trigger the focus on a textarea
  const handleButtonClick = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleNavigate = () => {
    if (props?.comment?.memberId !== currentMember?.currentMemberId) {
      navigate(`/userinfo/${props?.comment?.memberId.substring(0, 16)}`, {
        state: { userId: props?.comment?.memberId },
      });
    } else {
      navigate(`/myinfo/${currentMember?.currentMemberId?.substring(0, 16)}`);
    }
  };

  // handle reporting comment
  const handleReportComment = () => {
    setReportShow?.(true);
    setRepostId?.(props?.comment?.id);
    setReportType?.(1);
    setReportedId?.(props?.comment?.memberId);
    setContent?.(props?.comment?.content);
  };

  // function - like and unlike comment
  const queryClient = useQueryClient();

  const CommentLikedByUserMutation = useMutation({
    mutationFn: async (id: string) => {
      return await postLikeOrDislikeComment({
        memberId: currentMember?.currentMemberId,
        commentId: id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("/comments");
    },
  });

  // function - Delete a comment
  const DeleteCommentMutation = useMutation({
    mutationFn: async (id: string) => {
      return await getDeleteComment(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("/comments");
      queryClient.invalidateQueries("postContent");
    },
  });

  return (
    <>
      <div id={props.comment?.id} className="msg-bubble-container">
        <div
          className="msg-bubble-main"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="comment-user-pic" onClick={handleNavigate}>
            <img src={props?.comment?.memberAvatar} alt="talker-pic" />
          </div>
          <div className="user-comment-content">
            <div className="comment-top">
              <span
                className="sb-14 commnet-user-name"
                onClick={handleNavigate}
              >
                {props?.comment?.memberName}
              </span>
              <span className="r-12 comment-date">
                {props?.comment?.creationTime
                  ?.substring(0, 10)
                  ?.replaceAll("-", "/")}
              </span>
            </div>
            <div className="r-14">{props?.comment?.content}</div>
          </div>
          <div className="edit-comment-action" ref={ref}>
            {isHover || show ? (
              <>
                <Button
                  className="edit-comment-btn"
                  onClick={(e) => {
                    setShow(!show);
                    setTarget(e.target as HTMLElement);
                  }}
                >
                  <img
                    src={TemplateCardImg.srcDots_menu_icon}
                    alt="dots-icon"
                  />
                </Button>
                <Overlay
                  show={show}
                  target={target}
                  placement="bottom"
                  container={ref}
                >
                  <Popover>
                    <Popover.Body>
                      {props?.comment?.memberId ===
                      currentMember?.currentMemberId ? (
                        <div
                          className="comment-edit-option sb-14"
                          onClick={() =>
                            DeleteCommentMutation.mutate(props?.comment?.id)
                          }
                        >
                          <img
                            src={TemplateCardImg.srcDelete_icon}
                            alt="delete-icon"
                          />
                          <span>刪除</span>
                        </div>
                      ) : (
                        <div
                          className="comment-edit-option sb-14"
                          onClick={() => {
                            isAuthenticated
                              ? handleReportComment()
                              : setShowAuth?.(true);
                          }}
                        >
                          <img
                            src={TemplateCardImg.srcReport_icon}
                            alt="unsend-icon"
                          />
                          <span>檢舉</span>
                        </div>
                      )}
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="like-reply-action r-12">
          <div
            className={`like-action action ${
              props?.comment?.isLiked && "liked"
            }`}
            onClick={() => {
              isAuthenticated
                ? CommentLikedByUserMutation.mutate(props?.comment?.id)
                : setShowAuth?.(true);
            }}
          >
            <img src={TemplateCardImg.srcEmpty_heart_icon} alt="" />
            <span>{Number(props?.comment?.likes)}</span>
          </div>
          <div
            className="reply-action action"
            onClick={() => {
              // props?.comment?.parentId &&
              setReplyToCmntId?.(props?.comment?.id);

              props?.comment?.memberName &&
                setTagUser?.(props?.comment?.memberName);

              handleButtonClick();
            }}
          >
            <img src={TemplateCardImg.srcChat_icon} alt="" />
            <span>{`回覆(${props?.comment?.comments?.length})`}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommentBubble;
