import axios from "axios";
import { baseUrl } from "./axiosInstance";

// api to get all membership packages
export const getOrderPlanList = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(`${baseUrl}/app/order/get-orderPlanList`);

    return data;
  } catch (error) {
    return error;
  }
};

// api to get users balance of avaliaility to post and video production
export const getBalanceOfPostAndProd = async (id: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/member/get-available-used-postsAndProduction?memberId=${id}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to get users record of purchased plans
export const getOrderHistory = async (id: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/order/get-orderlist-by-memberId?id=${id}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to post information of transaction
export const postTransaction = async ({
  orderId,
  memberId,
  invoiceDetailsJSON,
}: {
  orderId: string;
  memberId: string;
  invoiceDetailsJSON: string;
}) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/ecpay/credit-card-payment-api`,
      {
        orderId,
        memberId,
        invoiceDetailsJSON,
      },
      { headers: { "Content-type": "multipart/form-data" } }
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to check if user has plan active
export const getHasOrderActive = async (id: string) => {
  try {
    const { data } = await axios.get(
      `${baseUrl}/app/order/isMemberOrderActive-api?memberId=${id}`
    );

    return data;
  } catch (error) {
    return error;
  }
};
