//react
import { Dispatch, SetStateAction, useState } from "react";
import { useQueryClient } from "react-query";
//api
import { postReport } from "../../api/posts";
//context
import { useAuth } from "../../context/AuthContext";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./ReportPopupModel.scss";

interface ReportPopupModalProps {
  setReportPopup: Dispatch<SetStateAction<boolean>> | undefined;
  postId: string;
  type: number;
  ownerId: string;
  content: string;
}

const options = [
  {
    option: "垃圾內容",
    key: "report-1",
  },
  {
    option: "惡意軟體",
    key: "report-2",
  },
  {
    option: "網路釣魚",
    key: "report-3",
  },
  {
    option: "暴力內容",
    key: "report-4",
  },
  {
    option: "仇恨言論",
    key: "report-5",
  },
  {
    option: "恐怖主義相關內容",
    key: "report-6",
  },
  {
    option: "騷擾、霸凌和威脅",
    key: "report-7",
  },
  {
    option: "色情內容",
    key: "report-8",
  },
  {
    option: "兒童剝削相關內容",
    key: "report-9",
  },
  {
    option: "冒用他人身分",
    key: "report-10",
  },
  {
    option: "私人和機密資訊",
    key: "report-11",
  },
  {
    option: "非法活動",
    key: "report-12",
  },
  {
    option: "侵權",
    key: "report-13",
  },
  {
    option: "賭博非法行為",
    key: "report-14",
  },
  {
    option: "違規張貼私人資訊",
    key: "report-15",
  },
  {
    option: "無相關商品販售",
    key: "report-16",
  },
  {
    option: "其他",
    key: "report-17",
  },
];

const ReportPopupModal: React.FC<ReportPopupModalProps> = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [complete, setComplete] = useState(false);
  const queryClient = useQueryClient();

  // context - memberId
  const { currentMember } = useAuth();

  const handleReport = async () => {
    try {
      const res = await postReport({
        ReporterId: currentMember?.currentMemberId,
        ReportedId: props?.ownerId,
        ReportedReason: selectedOption,
        Type: props?.type,
        ReportTypeId: props?.postId,
        Content: props.content,
      });

      if (res?.success) {
        setComplete(true);
        queryClient.invalidateQueries("/comments");
        queryClient.setQueryData(["/posts"], (data: any) => {
          const newData = {
            pages: data?.pages?.map((page: any) =>
              page?.filter((post: any) => post?.id !== props?.postId)
            ),
          };
          return newData;
        });
      }
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="report-popup-modal">
      <div className={`report-popup-container ${complete && "compelete"}`}>
        <div className="close-btn">
          <img
            src={TemplateCardImg.srcClose_icon}
            alt="close-icon"
            onClick={() => {
              props.setReportPopup?.(false);
              setSelectedOption("");
              setComplete(false);
            }}
          />
        </div>
        <div className="report-popup-main">
          {complete ? (
            <>
              <div className="title sb-24">已檢舉完成</div>
              <div className="r-16 context">管理員會儘速處理</div>
              <button
                className="primary-btn"
                onClick={() => {
                  props.setReportPopup?.(false);
                  setSelectedOption("");
                  setComplete(false);
                }}
              >
                關閉
              </button>
            </>
          ) : (
            <div className="report-option-buttons">
              {options?.map((option) => {
                return (
                  <div
                    className={`report-option ${
                      selectedOption === option.option && "selected"
                    }`}
                    key={option.key}
                    onClick={() => setSelectedOption(option.option)}
                  >
                    {option.option}
                  </div>
                );
              })}
            </div>
          )}

          {selectedOption && !complete && (
            <div className="confirmation">
              <button
                className="primary-btn"
                onClick={() => {
                  props.setReportPopup?.(false);
                  setSelectedOption("");
                }}
              >
                放棄檢舉
              </button>
              <button className="white-btn" onClick={handleReport}>
                確定檢舉
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportPopupModal;
