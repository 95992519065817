//react
import { useState, useEffect } from "react";
//api
import { getOrderHistory } from "../../api/membership";
//context
import { useAuth } from "../../context/AuthContext";
//type
import { TypeOrderHistory } from "../../types/membership";
//style
import "./HistoryTable.scss";

interface HistoryTableProps {
  historyData: TypeOrderHistory[];
}

const HistoryTable: React.FC<HistoryTableProps> = (props) => {
  return (
    <div className="history-table-container r-14">
      <table>
        <thead>
          <tr>
            <th>方案名稱</th>
            <th>購買日期</th>
            <th>到期日期</th>
            <th>發佈筆數</th>
            <th>後製筆數</th>
            <th>已收取金額</th>
          </tr>
        </thead>
        <tbody>
          {props?.historyData
            ?.map((history: TypeOrderHistory, i: number) => {
              return (
                <tr key={`history-${i}`}>
                  <td>{history?.orderType}</td>
                  <td>
                    {history?.creationTime
                      ?.substring(0, 10)
                      .replaceAll("-", "/")}
                  </td>
                  <td>
                    {history?.expiredDate
                      ?.substring(0, 10)
                      .replaceAll("-", "/")}
                  </td>
                  <td>
                    {history?.totalAvailablePost === null
                      ? 0
                      : history?.totalAvailablePost}
                  </td>
                  <td>
                    {history?.totalAvailablePostProd === null
                      ? 0
                      : history?.totalAvailablePostProd}
                  </td>
                  <td>$ {history?.amount?.toLocaleString("en-US")}</td>
                </tr>
              );
            })
            .reverse()}
        </tbody>
      </table>
    </div>
  );
};

export default HistoryTable;
