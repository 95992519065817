//react
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
//context
import { usePost } from "../context/PostProvider";
import { useAuth } from "../context/AuthContext";

const useConfirmNavigation = () => {
  const { hasContent, triggerPopup, showPopup } = usePost();
  const { currentMember } = useAuth();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return ""; // Show the default browser dialog
    };

    const handlePopState = (event: PopStateEvent) => {
      hasContent ? triggerPopup("prev") : window.history.go(-1);
    };

    hasContent && window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    window.history.pushState(null, "", window.location.pathname);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [hasContent, currentMember?.currentMemberId, showPopup]);
};

export default useConfirmNavigation;
