//react
import { useState, useEffect } from "react";
//components
import CustomDealCard from "../../../components/CustomDealCard/CustomDealCard";
import PackageDealCard from "../../../components/PackageDealCard/PackageDealCard";
import TransactionPopupModel from "../../../components/TransactionPopupModel/TransactionPopupModel";
//context
import { useAuth } from "../../../context/AuthContext";
//api
import { TypeMemberPlan } from "../../../types/membership";
import { getHasOrderActive } from "../../../api/membership";
//style
import "./PackageDeals.scss";

const instructions = [
  {
    key: 1,
    content: "支付完成後，即可刊登使用。",
  },
  {
    key: 2,
    content:
      "受繳費方式的(稅率及外匯匯率變化)影響，實際支付價格可能會出現上調，為避免造成您的困擾，請您支付時多留意系統所顯示的實際價格。",
  },
  {
    key: 3,
    content:
      "方案及組合優惠內物件刊登時間皆為90天，公開刊登後起算，刊登後無法終止刊登時間計算。刊登後可使用編輯，若刪除刊登即視為放棄當次刊登權利。",
  },
  {
    key: 4,
    content:
      "購買平台內享優惠組合方案，以購買日起算會員期限為90天，刊登期限也為90天，如在會員期限後沒有用完該筆數，剩餘即註銷無法再使用。",
  },
  {
    key: 5,
    content:
      "購買平台內享輕鬆組合方案，以購買日起算會員期限為97天(含後製時間)，刊登期限則為90天，如在會員期限後沒有用完該筆數，剩餘即註銷無法再使用。",
  },
  {
    key: 6,
    content:
      "成功購買本平台內任一方案及組合後，不得以任何原因取消及退費，因此請注意有效期限，並於時間內刊登完購買方案的物件。",
  },
  {
    key: 7,
    content:
      "若非本平台之因素造成廣告未及時刊登，不可以其他任何理由要求延長刊登時間。",
  },
  {
    key: 8,
    content:
      "更多繳費相關服務，可聯繫站內客服人員或官方@LINE官方LINE連結: https://lin.ee/Q8KieGl  LINE帳號:@658oyspo",
  },
  {
    key: 9,
    content:
      "平台方案： 享輕鬆客制方案皆為專人幫你後製影片廣告，購買筆數為15筆/25筆/45筆/單筆，購買後將拍攝好的影片上傳平台所提供的雲端，3天內平台將後製影片回傳給客戶，收到完成檔後請務必一次檢查清楚，影片僅接受因本司誤植等責任修改至多1次。享優惠組合方案為可立即刊登物件照片及自製影片，購買筆數為4筆/9筆12筆/25筆/45筆。",
  },
  {
    key: 10,
    content:
      "退換貨說明：「通訊交易解除權合理例外情事適用準則」自105年1月1日開始施行，該準則就部分性質特殊之商品或服務，規定作為不適用消費者保護法第19條第1項前段之合理例外情事，以平衡企業經營者和消費者間之權益。另準則第2條規定，通訊交易之商品或服務有下列情形之一，並經企業經營者告知消費者將排除消保法第19條第1項規定解除權之適用者，屬排除7日解除權之合理例外情事：非以有形媒介提供之數位內容或一經提供即為完成之線上服務，經消費者事先同意始提供。",
  },
];

interface PackageDealsProps {
  selectedTab: string;
  plans: TypeMemberPlan[];
}

const PackageDeals: React.FC<PackageDealsProps> = (props) => {
  const [showTXN, setShowTXN] = useState(false);
  const [planId, setPlanId] = useState("");
  const [hasPlan, setHasPlan] = useState(false);

  //context
  const { currentMember } = useAuth();

  useEffect(() => {
    const checkHasPlanAsync = async () => {
      const res = await getHasOrderActive(currentMember?.currentMemberId);

      setHasPlan(res?.data);
    };

    checkHasPlanAsync();
  }, [currentMember?.currentMemberId]);

  return (
    <>
      <div className="package-deals-container">
        <div className="tab-subtitle r-16">
          <div className="subtitle">
            {props.selectedTab === "享輕鬆客制方案"
              ? "有專人為你後製最流行的影音廣告，你只需要錄好物件影片即可，包裝後製我們替你完成，省時省力就可擁有優質的物件廣告\r\n(上傳原始檔，專人替你後製包裝影片)"
              : "只要你準備好物件廣告，上傳平台就可替你立即曝光您最優質的案件照片及影片(上傳要分享的 照片或影片)"}
          </div>
        </div>
        <div className="deal-combo-list">
          {props.selectedTab === "享輕鬆客制方案"
            ? props?.plans
                ?.filter((plan) => {
                  return plan.orderPlan === 1;
                })
                .map((plan) => {
                  return (
                    <PackageDealCard
                      key={plan.id}
                      plan={plan}
                      setShowTXN={setShowTXN}
                      setPlanId={setPlanId}
                      hasPlan={hasPlan}
                    />
                  );
                })
            : props?.plans
                ?.filter((plan) => {
                  return plan.orderPlan === 0;
                })
                .map((plan) => {
                  return (
                    <CustomDealCard
                      key={plan.id}
                      plan={plan}
                      setShowTXN={setShowTXN}
                      setPlanId={setPlanId}
                      hasPlan={hasPlan}
                    />
                  );
                })}
        </div>
        <div className="pay-instruction">
          <div className="title sb-24">繳費說明</div>
          <div className="inst-list">
            <ul className="r-14">
              {instructions.map((descr) => {
                return (
                  <li key={descr.key}>
                    {descr.key}. {descr.content}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {showTXN && (
        <TransactionPopupModel
          setShowTXN={setShowTXN}
          planId={planId}
          plans={props?.plans}
        />
      )}
    </>
  );
};

export default PackageDeals;
