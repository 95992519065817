import axios from "axios";
import { baseUrl } from "./axiosInstance";

// api to fecth posts accroding to seleted options in filter
export const getPosts = async ({
  skipCount,
  currentMember,
  purpose,
  region,
  price,
  layout,
  houseType,
  propertyAge,
  keyword,
}: {
  skipCount: number;
  currentMember: string;
  purpose: number | string;
  region: string[];
  price: string[];
  layout: string[];
  houseType: string[];
  propertyAge: string[];
  keyword: string;
}) => {
  const regionArr = region.map((item) => `&region=${item}`);
  const priceArr = price.map((item) => `&price=${item}`);
  const layoutArr = layout.map((item) => `&layout=${item}`);
  const houseTypeArr = houseType.map((item) => `&houseType=${item}`);
  const propertyAgeArr = propertyAge.map((item) => `&propertyAge=${item}`);

  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/post/get-Posts?skipCount=${skipCount}&currentMember=${currentMember}&maxResultCount=20&purpose=${purpose}${regionArr?.join(
        ""
      )}${priceArr?.join("")}${layoutArr?.join("")}${houseTypeArr?.join(
        ""
      )}${propertyAgeArr?.join("")}&search=${keyword}`
    );

    return data.items;
  } catch (error) {
    return error;
  }
};

// api to fetch random posts for homepage
export const getRandomPosts = async ({
  pageParam,
  currentUser,
  isRefreshed,
  postIds,
}: {
  pageParam: number;
  currentUser: string;
  isRefreshed: boolean;
  postIds: string;
}) => {
  const ids =
    postIds === ""
      ? []
      : postIds?.split(", ")?.map((id) => `alreadyFetchedPosts=${id}&`);

  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/post/get-RandomPosts?${ids?.join(
        ""
      )}maxResultCount=20&currentUser=${currentUser}&isRefreshed=${isRefreshed}}`
    );

    return data.item2.items;
  } catch (error) {
    return error;
  }
};

// api to fetch trending posts
export const getTrendingPosts = async ({
  currentMemberId,
  skipCount,
}: {
  currentMemberId: string;
  skipCount: number;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/post/get-Past7DaysTrendingPostOnLikes?currentMemberId=${currentMemberId}&skipCount=${skipCount}&maxResultCount=20`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to fetch posts in order of time
export const getStandardOrderPosts = async ({
  currentMemberId,
  skipCount,
}: {
  currentMemberId: string;
  skipCount: number;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/post/get-StandardRandomPosts?skipCount=${skipCount}&currentUser=${currentMemberId}&maxResultCount=20`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to fecth posts accroding to user id
export const getMemberPosts = async ({
  id,
  currentMember,
  skipCount,
  isMyPosts,
}: {
  id: string;
  currentMember: string;
  skipCount: number;
  isMyPosts: boolean;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/post/get-memberPosts?Id=${id}&currentMember=${currentMember}&skipCount=${skipCount}&maxResultCount=20&isMyPosts=${isMyPosts}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to fecth posts of users followed by current user
export const getFollowedMemberPosts = async ({
  id,
  skipCount,
}: {
  id: string;
  skipCount: number;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/follower/get-FollowedMembersPosts?SkipCount=${skipCount}&MaxResultCount=20&followerId=${id}&IsRefreshed=true`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to fecth posts liked by current user
export const getLikedPosts = async ({
  id,
  skipCount,
}: {
  id: string;
  skipCount: number;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/member/getList-post-liked-by-member?SkipCount=${skipCount}&MaxResultCount=20&memberId=${id}&IsRefreshed=false`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to like particular post
export const postLikePost = async (memberId: string, postId: string) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/member/post-liked-by-member?memberId=${memberId}&postId=${postId}`
    );

    return data;
  } catch (error) {
    //throw error;
    return error;
  }
};

// api to create a new post
export const postCreatePost = async (formData: FormData) => {
  try {
    const res = await axios.post(`${baseUrl}/app/post/create-post`, formData, {
      headers: { "Content-type": "multipart/form-data" },
    });

    return res;
  } catch (error) {
    //throw error;
    return error;
  }
};

// api to create a new post
export const postEditPost = async (formData: any) => {
  try {
    const res = await axios.post(
      `${baseUrl}/app/post/edit-Post-api`,
      formData,
      {
        headers: { "Content-type": "multipart/form-data" },
      }
    );

    return res;
  } catch (error) {
    //throw error;
    return error;
  }
};

// api to get detail of specific post by posts id
export const getPostDetail = async ({
  postId,
  currentMember,
}: {
  postId: string;
  currentMember: string;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/post/get-Post-Details-api?postId=${postId}&currentMember=${currentMember}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to create a new post
export const postViewCount = async (id: string) => {
  try {
    const res = await axios.post(
      `${baseUrl}/app/post/post-Views-Count?postId=${id}`
    );

    return res;
  } catch (error) {
    //throw error;
    return error;
  }
};

// api to delete a post
export const deletePost = async (id: string) => {
  try {
    const data = await axios.post(
      `${baseUrl}/app/post/delete-PostsAndRelatedComments?postId=${id}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to report disturbing content
export const postReport = async ({
  ReporterId,
  ReportedId,
  ReportedReason,
  Type,
  ReportTypeId,
  Content,
}: {
  ReporterId: string;
  ReportedId: string;
  ReportedReason: string;
  Type: number;
  ReportTypeId: string;
  Content: string;
}) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/report/report-api`,
      {
        ReporterId,
        ReportedId,
        ReportedReason,
        Type,
        ReportTypeId,
        Content,
      },
      {
        headers: { "Content-type": "multipart/form-data" },
      }
    );

    return data;
  } catch (error) {
    //throw error;
    return error;
  }
};

// api to get comments by posts id and user id
export const getCommentsById = async ({
  postId,
  currentMember,
}: {
  postId: string;
  currentMember: string;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/comment/get-commentsByPostId?postId=${postId}&currentMember=${currentMember}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to get comments by posts id
export const getCommentsWithoutLoggedIn = async (postId: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/comment/get-commentsByPostIdWithoutLoggedIn?postId=${postId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to create comment
export const postComment = async ({
  ParentId,
  MemberId,
  PostId,
  Content,
}: {
  ParentId: string;
  MemberId: string;
  PostId: string;
  Content: string;
}) => {
  try {
    const res = await axios.post(
      `${baseUrl}/app/comment/create-comment`,
      {
        ParentId,
        MemberId,
        PostId,
        Content,
      },
      {
        headers: { "Content-type": "multipart/form-data" },
      }
    );

    return res;
  } catch (error) {
    //throw error;
    return error;
  }
};

// api to like or dislike comment
export const postLikeOrDislikeComment = async ({
  memberId,
  commentId,
}: {
  memberId: string;
  commentId: string;
}) => {
  try {
    const res = await axios.post(
      `${baseUrl}/app/member/comment-liked-by-member?memberId=${memberId}&commentId=${commentId}`
    );

    return res;
  } catch (error) {
    //throw error;
    return error;
  }
};

// api to delete comment
export const getDeleteComment = async (commentId: string) => {
  try {
    const res = await axios.get(
      `${baseUrl}/app/comment/delete-comment?commentId=${commentId}`
    );

    return res;
  } catch (error) {
    return error;
  }
};
