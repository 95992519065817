//react
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
//components
import ReportPopupModal from "../ReportPopupModel/ReportPopupModel";
import MediaPopupModel from "../MediaPoupupModel/MediaPopupModel";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
// file formats
import { imageFormats, videoFormats } from "../../assets/data/fileFormats";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./SenderChatBubble.scss";

interface FileDisplayProps {
  file: string;
}

const FileDisplay: React.FC<FileDisplayProps> = ({ file }) => {
  const [mediaShow, setMediaShow] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [url, setUrl] = useState("");

  // Determine how to render based on the file type
  const renderFile = () => {
    if (imageFormats.some((format) => file.endsWith(format))) {
      return (
        <div
          onClick={() => {
            setMediaShow(true);
            setUrl(file);
            setIsVideo(false);
          }}
        >
          <img src={file} alt="embeded-img" />
        </div>
      );
    } else if (videoFormats.some((format) => file.endsWith(format))) {
      return (
        <div
          className="msg-file-video"
          onClick={() => {
            setMediaShow(true);
            setUrl(file);
            setIsVideo(true);
          }}
        >
          <video>
            <source src={file} />
            Your browser does not support the video tag.
          </video>
          <img src={TemplateCardImg.srcPlayhead_icon} alt="playhead-icon" />
        </div>
      );
    } else {
      return (
        <div className="file-to-download r-14">
          <a href={file} download={file}>
            {file?.length > 20 ? `...${file?.slice(-20)}` : file}
          </a>
        </div>
      );
    }
  };

  return (
    <>
      <div className="file-content">{renderFile()}</div>
      {mediaShow && (
        <MediaPopupModel
          url={url}
          isVideo={isVideo}
          setMediaShow={setMediaShow}
        />
      )}
    </>
  );
};

interface SenderChatBubbleProps {
  id: string;
  msg: string;
  msgFiles: string[];
  avatar: string;
  timestamp: string;
  ownerId: string;
}

const SenderChatBubble: React.FC<SenderChatBubbleProps> = (props) => {
  const [isHover, setIsHover] = useState(false);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [reportPopup, setReportPopup] = useState(false);

  //route
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  return (
    <>
      <div
        className="sender-bubble-container"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div className="sender-pic">
          <img src={props?.avatar} alt="talker-pic" />
        </div>
        <div className="sender-chat-content">
          <div
            className={`msg-files-box ${
              props?.msgFiles && props?.msg && "files-down-space"
            }`}
          >
            {props?.msgFiles?.map(
              (file, i) => file && <FileDisplay key={`file-${i}`} file={file} />
            )}
          </div>
          {props?.msg?.includes("https") ? (
            <a
              className="r-14"
              href={props?.msg}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props?.msg}
            </a>
          ) : (
            <span className="text r-14">{props?.msg}</span>
          )}
          {/* <span className="text r-14">{props?.msg}</span> */}
        </div>
        {pathname !== "/support-center" && (
          <div className="report-message-action" ref={ref}>
            {isHover || show ? (
              <>
                <Button
                  className="report-action-btn"
                  onClick={(e) => {
                    setShow(!show);
                    setTarget(e.target as HTMLElement);
                  }}
                >
                  <img
                    src={TemplateCardImg.srcDots_menu_icon}
                    alt="dots-icon"
                  />
                </Button>
                <Overlay
                  show={show}
                  target={target}
                  placement="bottom"
                  container={ref}
                >
                  <Popover>
                    <Popover.Body>
                      <div
                        className="message-edit-option sb-14"
                        onClick={() => setReportPopup(true)}
                      >
                        <img
                          src={TemplateCardImg.srcReport_icon}
                          alt="delete-icon"
                        />
                        <span>檢舉</span>
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {props?.timestamp && (
        <div className="sender-msg-timestamp r-12">{props?.timestamp}</div>
      )}
      {reportPopup && (
        <ReportPopupModal
          setReportPopup={setReportPopup}
          ownerId={props?.ownerId}
          postId={props?.id}
          type={2}
          content={props?.msg ? props?.msg : "檢舉檔案"}
        />
      )}
    </>
  );
};

export default SenderChatBubble;
