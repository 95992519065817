//react
import { useState, useEffect } from "react";
//componens
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import Input from "../../components/Input/Input";
import TextArea from "../../components/TextArea/TextArea";
import SystemPopup from "../../components/SystemPopup/SystemPopup";
import DeleteAccountPopup from "../../components/DeleteAccountPopup/DeleteAccountPopup";
//api
import { getUserInfo } from "../../api/users";
import { postUpdateUserInfo } from "../../api/users";
import { postDeleteUserAccount } from "../../api/users";
//context
import { useAuth } from "../../context/AuthContext";
//type
import { TypeUserInfoSubmit } from "../../types/user";
//img
import {
  TemplateOtherImg,
  TemplateCardImg,
} from "../../assets/data/imagesData";
//style
import "./EditInfoPage.scss";

const EditInfoPage = () => {
  //context
  const { currentMember, setNavAvatar, navAvatar, logout } = useAuth();

  //system popup
  const [popupIcon, setPopupIcon] = useState("");
  const [show, setShow] = useState(false);
  const [popupMsg, setPopup] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);

  //input Validation
  const [nameError, setNameError] = useState("");
  const [descrError, setDescrError] = useState("");
  const [nameValid, setNameValid] = useState(true);
  const [descrValid, setDescrValid] = useState(true);

  // useState - to store user info data
  const [avatar, setAvatar] = useState("");
  const [name, setName] = useState("");
  const [descr, setDescr] = useState("");
  const [background, setBackground] = useState("");
  const [accountType, setAccountType] = useState("");
  const [appBg, setAppBg] = useState("");
  const [nameCard, setNameCard] = useState("");

  // useState - to store uploaded image for the use of api
  const [selectedAvatar, setSelectedAvatar] = useState<File | null>(null);
  const [selectedBg, setSelectedBg] = useState<File | null>(null);
  const [selectedAppBg, setSelectedAppBg] = useState<File | null>(null);
  const [selectedNameCard, setSelectedNameCard] = useState<File | null>(null);

  const handleAvatarUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      setSelectedAvatar(file);
      setAvatar(URL.createObjectURL(file));
    }
  };

  const handleBgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      setSelectedBg(file);
      setBackground(URL.createObjectURL(file));
    }
  };

  const handleAppUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      setSelectedAppBg(file);
      setAppBg(URL.createObjectURL(file));
    }
  };

  const handleNameCardUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = target.files?.[0];
    if (file) {
      setSelectedNameCard(file);
      setNameCard(URL.createObjectURL(file));
    }
  };

  const handleNameInput = (nameInputValue: string) => {
    if (nameInputValue.length > 20) {
      setNameError("名稱不可超過20個字");
      setNameValid(false);
    } else if (nameInputValue.length <= 0) {
      setNameError("名稱至少為一個字");
      setNameValid(false);
    } else {
      setNameError("");
      setNameValid(true);
    }

    setName(nameInputValue);
  };

  const handleDescrInput = (textInputValue: string) => {
    if (textInputValue.length > 100) {
      setDescrError("內容不可超過100個字");
      setDescrValid(false);
    } else {
      setDescrError("");
      setDescrValid(true);
    }

    setDescr(textInputValue);
  };

  const handleUserInfoSubmit = async () => {
    if (nameValid && descrValid) {
      const formData: TypeUserInfoSubmit = {
        id: currentMember?.currentMemberId,
        UserName: name,
        PersonalIntroduction: descr,
        personalAvatar: selectedAvatar,
        backgroundImage: selectedBg,
        backgoundImageMobile: selectedAppBg,
        businessCard: selectedNameCard,
      };

      try {
        const res = await postUpdateUserInfo(formData);
        if (res) {
          setShow(true);
          setPopup("儲存成功");
          setPopupIcon(TemplateOtherImg.srcSuccess_icon);
          setNavAvatar?.(!navAvatar);
        } else {
          setShow(true);
          setPopup("儲存失敗");
          setPopupIcon(TemplateOtherImg.srcFail_icon);
        }
      } catch (error) {
        return error;
      }
    } else {
      return;
    }
  };

  const handleDeleteAccount = async () => {
    if (currentMember?.currentMemberId) {
      const res = (await postDeleteUserAccount(
        currentMember?.currentMemberId
      )) as { status: number };

      if (res?.status === 200) {
        logout();
      }
    }
  };

  useEffect(() => {
    const getUserInfoAsync = async () => {
      try {
        const info = await getUserInfo(currentMember?.currentMemberId);
        setAvatar(info?.personalAvatar);
        setName(info?.userName);
        setDescr(info?.personalIntroduction);
        setBackground(info?.backgroundImage);
        //setAccountType(info.accountType);
        setAppBg(info?.backgroundImageMobile);
        setNameCard(info?.businessCard);

        if (info.accountType === "Facebook") {
          setAccountType(TemplateCardImg.srcFb_round);
        } else if (info.accountType === "Apple") {
          setAccountType(TemplateCardImg.srcApple_logo);
        } else {
          setAccountType(TemplateCardImg.srcLine_logo);
        }
      } catch (error) {
        return error;
      }
    };

    getUserInfoAsync();
  }, [currentMember?.currentMemberId]);

  return (
    <>
      <TitleHeader title="編輯資訊" />
      <div className="editinfo-main-container main-body-spacing">
        <div className="user-edit-main">
          <div className="edit-top">
            <label className="pic-edit-sec" htmlFor="avatar-99">
              <img className="photo" src={avatar} alt="user-pic" />
              <img
                className="camera-icon"
                src={TemplateOtherImg.srcEdit_photo_icon}
                alt="camera-icon"
              />
              <input
                type="file"
                id="avatar-99"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                onChange={handleAvatarUpload}
              />
            </label>
            <div className="info-edit-sec">
              <div className="info-top">
                <div>
                  <Input
                    type="text"
                    name=""
                    value={name}
                    placeholder={"用戶名稱"}
                    onChange={(nameInputValue) =>
                      handleNameInput(nameInputValue)
                    }
                  />
                  {!nameValid && (
                    <div className="name-error r-14" style={{ color: "red" }}>
                      {nameError}
                    </div>
                  )}
                </div>
                <div className="associated r-16">
                  <div>綁定帳號</div>
                  <div className="media-icon">
                    <img src={accountType} alt="media-icon" />
                  </div>
                </div>
              </div>
              <div
                className={`descr-area ${!nameValid ? "name-error-gap" : ""}`}
              >
                <TextArea
                  value={descr}
                  placeholder={"個人簡介"}
                  onChange={(textInputValue) =>
                    handleDescrInput(textInputValue)
                  }
                />
              </div>
            </div>
          </div>
          {!descrValid && (
            <div className="descr-error r-14" style={{ color: "red" }}>
              {descrError}
            </div>
          )}
          <div
            className={`edit-bottom ${!descrValid ? "descr-error-gap" : ""}`}
          >
            <label className="bg-edit-sec" htmlFor="bg-99">
              <img src={background} alt="user-bg" />
              <input
                type="file"
                id="bg-99"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                onChange={handleBgUpload}
              />
            </label>
          </div>
          <div className="lower-upload-area">
            {/* <label className="app-bg-edit-sec" htmlFor="app-bg-99">
              <img src={appBg} alt="user-bg" />
              <input
                type="file"
                id="app-bg-99"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                onChange={handleAppUpload}
              />
            </label> */}
            {/* <label
              className="business-card-edit-sec"
              htmlFor="business-card-99"
            >
              <img src={nameCard} alt="user-bg" />
              <input
                type="file"
                id="business-card-99"
                accept="image/jpeg, image/png"
                style={{ display: "none" }}
                onChange={handleNameCardUpload}
              />
            </label> */}
            <div className="btn-group">
              <button
                className="primary-btn delete-btn sb-16"
                onClick={() => setDeleteShow(true)}
              >
                刪除帳戶
              </button>
              <button
                className="white-btn save-btn sb-16"
                onClick={() => handleUserInfoSubmit()}
              >
                儲存
              </button>
            </div>
          </div>
        </div>
        {show && (
          <SystemPopup
            popupMsg={popupMsg}
            popupIcon={popupIcon}
            setShow={setShow}
          />
        )}
        {deleteShow && (
          <DeleteAccountPopup
            setConfirmPopup={setDeleteShow}
            onClick={() => handleDeleteAccount()}
          />
        )}
      </div>
    </>
  );
};

export default EditInfoPage;
