export const imageFormats = [
  "jpg",
  "jpeg",
  "png",
  "svg",
  "gif",
  "webp",
  "tiff",
];

export const videoFormats = ["mp4", "mov", "webm", "ogg", "avi", "flv", "mkv"];
