//react
import { Dispatch, SetStateAction } from "react";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./AssurePopupModel.scss";

interface AssurePopupModalProps {
  title: string;
  context: string;
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
  onClick: () => void;
}

const AssurePopupModal: React.FC<AssurePopupModalProps> = (props) => {
  return (
    <div className="assure-popup-model">
      <div className="assure-popup-main">
        <div
          className="close-btn"
          onClick={() => props?.setConfirmPopup(false)}
        >
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="popup-content">
          <div className="title sb-24">{props?.title}</div>
          <div className="context r-16">{props?.context}</div>
        </div>
        <div className="btn-group">
          {props?.title !== "訊息封鎖" && (
            <>
              <button
                className="primary-btn"
                onClick={() => props?.setConfirmPopup(false)}
              >
                取消
              </button>
              <button className="white-btn" onClick={() => props?.onClick()}>
                確定
              </button>
            </>
          )}
          {props?.title === "訊息封鎖" && (
            <button
              className="primary-btn"
              onClick={() => props?.setConfirmPopup(false)}
            >
              關閉
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssurePopupModal;
