//react
import { useEffect, useState } from "react";
//component
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import MemberPosts from "../../components/MemberPosts/MemberPosts";
import FollowedMemberPosts from "../../components/FollowedMemberPosts/FollowedMemberPosts";
import LikedPosts from "../../components/LikedPosts/LikedPosts";
import BackToTopArrow from "../../components/BackToTopArrow/BackToTopArrow";
import PostPopupModel from "../../components/PostPopupModel/PostPopupModel";
//api
import { getUserInfo, getUserCountsInfo } from "../../api/users";
//context
import { useAuth } from "../../context/AuthContext";
import { useNotifi } from "../../context/NotifiProvider";
import { usePost } from "../../context/PostProvider";
//type
import { TypeUserInfo, TypeCountsInfo } from "../../types/user";
//style
import "./MyInfoPage.scss";

const MyInfoPage = () => {
  //context - memberId
  const { currentMember } = useAuth();

  //context- to refetch post count from user info as post is deleted
  const { refetchProfile } = usePost();

  const [userInfo, setUserInfo] = useState<TypeUserInfo>({
    accountType: "",
    availablePost: 0,
    availablePostProduction: 0,
    backgroundImage: "",
    personalAvatar: "",
    personalIntroduction: "",
    userName: "",
  });

  const [countsInfo, setCountsInfo] = useState<TypeCountsInfo>({
    memberFans: 0,
    memberPosts: 0,
    postLikes: 0,
  });

  const [currentTab, setCurrentTab] = useState("貼文");

  // popup post triggered by notification
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const getUserInfoAsync = async () => {
      try {
        const info = await getUserInfo(currentMember?.currentMemberId);
        setUserInfo(info);
      } catch (error) {
        return error;
      }
    };

    const getUserCountsAsyc = async () => {
      try {
        const counts = await getUserCountsInfo({
          memberId: currentMember?.currentMemberId,
          includePrivate: true,
        });

        setCountsInfo(counts);
      } catch (error) {
        return error;
      }
    };

    getUserInfoAsync();
    getUserCountsAsyc();
  }, [currentMember?.currentMemberId, refetchProfile]);

  // notification
  const { postId, notifiUsed } = useNotifi();

  useEffect(() => {
    if (notifiUsed) {
      setIsShow(true);
    }
  }, [notifiUsed]);

  return (
    <>
      <TitleHeader title="我的資訊" />
      <div className="myinfo-main-container main-body-spacing">
        {currentMember?.currentMemberId && (
          <ProfileCard
            userInfo={userInfo}
            userId={currentMember?.currentMemberId}
            countsInfo={countsInfo}
          />
        )}
        <div className="mine-post-btn-group">
          <button
            className={`primary-btn ${
              currentTab === "貼文" && `secondary-btn`
            }`}
            onClick={() => setCurrentTab("貼文")}
          >
            貼文
          </button>
          <button
            className={`primary-btn ${
              currentTab === "關注" && `secondary-btn`
            }`}
            onClick={() => setCurrentTab("關注")}
          >
            關注
          </button>
          <button
            className={`primary-btn ${
              currentTab === "喜歡" && `secondary-btn`
            }`}
            onClick={() => setCurrentTab("喜歡")}
          >
            喜歡
          </button>
        </div>
        <div className="my-posts-container">
          {currentMember?.currentMemberId && currentTab === "貼文" && (
            <MemberPosts
              memberId={currentMember?.currentMemberId}
              isMyPosts={true}
            />
          )}
          {currentTab === "關注" && <FollowedMemberPosts />}
          {currentTab === "喜歡" && <LikedPosts />}
          <BackToTopArrow />
        </div>
      </div>
      {isShow && (
        <PostPopupModel postId={postId} setIsShow={(show) => setIsShow(show)} />
      )}
    </>
  );
};

export default MyInfoPage;
