//react
import { useEffect, useState, Dispatch, SetStateAction, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
//components
import HouseDetailCard from "../../components/HouseDetailCard/HouseDetailCard";
import CommentInput from "../CommentInput/CommentInput";
import SocialMediaShare from "../SocialMediaShare/SocialMediaShare";
//bootstrap
import Carousel from "react-bootstrap/Carousel";
import { CarouselRef } from "react-bootstrap/Carousel";
//api
import { getPostDetail, postViewCount, postLikePost } from "../../api/posts";
//utility
import closePopup from "../../utils/closePopup";
//hooks
import usePreventScrolling from "../../hooks/usePreventScrolling";
//context
import { useComment } from "../../context/CommentProvider";
import { useNotifi } from "../../context/NotifiProvider";
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
//style
import "./PostPoppModel.scss";

interface PostPopupModelProps {
  setIsShow: Dispatch<SetStateAction<boolean>>;
  postId: string;
}

const PostPopupModel: React.FC<PostPopupModelProps> = (props) => {
  const [message, setMessage] = useState("");
  const commentSecRef = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  // context - memberId
  const { currentMember, isAuthenticated, setShowAuth } = useAuth();

  //useQuery
  const queryClient = useQueryClient();

  const { data: postDetail, isError } = useQuery(
    ["postContent", props?.postId],
    async () =>
      await getPostDetail({
        postId: props?.postId,
        currentMember: currentMember?.currentMemberId,
      }),
    {
      onSuccess: async () => {
        await postViewCount(props?.postId);
      },
    }
  );

  const scrollToCommentSecRef = () => {
    if (commentSecRef && commentSecRef.current) {
      commentSecRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  //context
  const { setTagUser, setReplyToCmntId } = useComment();
  const { setNotifiUsed, setPostError } = useNotifi(); // notification

  // make sure navigating to homepage when closing popup of a post triggered by shared link from third party site or app
  const handleBackToHome = () => {
    const currentPgaeUrl = window.location.href;

    currentPgaeUrl?.includes("/post/") && navigate("/");
  };

  //context - post
  const { setIsLikedText, setId, refetchProfile, setRefetchProfile } =
    usePost();

  const handleLikePostAsync = async () => {
    const res = await postLikePost(
      currentMember?.currentMemberId,
      postDetail?.id
    );

    if (res) {
      queryClient.invalidateQueries("postContent");
      setId?.(postDetail?.id);
      setIsLikedText?.(res?.message);
      pathname.includes("myinfo") && setRefetchProfile?.(!refetchProfile);
    }
  };

  useEffect(() => {
    return () => {
      setTagUser?.("");
      setReplyToCmntId?.("");
      setNotifiUsed?.(false); //notification
    };
  }, []);

  useEffect(() => {
    if (postDetail?.response?.status === 404 || postDetail === null) {
      props?.setIsShow(false);
      setPostError?.(true);
    }
  }, [postDetail]);

  // make carousel controllable once post pops up
  const carouselRef = useRef<CarouselRef | null>(null);

  useEffect(() => {
    if (carouselRef.current) {
      const firstItem = carouselRef.current?.element?.querySelector(
        ".carousel-indicators button"
      );

      if (firstItem) {
        (firstItem as HTMLElement).focus();
      }
    }
  }, [postDetail?.extraProperties?.Images]);

  // prevent content behind popup from scrolling
  usePreventScrolling();

  return (
    <>
      <div
        className="post-popup-model"
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          closePopup(e, "post-popup-model", props.setIsShow);
          handleBackToHome();
        }}
      >
        <div
          className="close-btn"
          onClick={() => {
            props.setIsShow(false);
            handleBackToHome();
          }}
        >
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="post-main-container">
          <div className="post-main-content">
            <div
              className={`post-presentation ${
                (postDetail?.extraProperties?.Images?.length === 1 ||
                  postDetail?.extraProperties?.MergedVideo ||
                  postDetail?.extraProperties?.Video?.[0]) &&
                "single-item"
              }`}
            >
              <Carousel
                interval={null}
                ref={carouselRef}
                tabIndex={0}
                style={{ outline: "none" }}
              >
                {postDetail?.extraProperties?.Images &&
                  postDetail?.extraProperties?.Images?.map(
                    (file: string, i: number) => {
                      return (
                        <Carousel.Item key={`slide-${i}`}>
                          <img
                            src={file}
                            alt={file}
                            draggable="false"
                            // onLoad={(e) => {
                            //   const img = e.target as HTMLImageElement;
                            //   if (img.naturalHeight > img.naturalWidth) {
                            //     img.classList.add("img-fit-height");
                            //   } else {
                            //     img.classList.add("img-fit-width");
                            //   }
                            // }}
                          />
                        </Carousel.Item>
                      );
                    }
                  )}
                {postDetail?.extraProperties?.MergedVideo && (
                  <Carousel.Item>
                    {/* <video
                      src={postDetail?.extraProperties?.MergedVideo[0]}
                      // onLoadedMetadata={(e) => {
                      //   const video = e.target as HTMLVideoElement;
                      //   if (video.videoHeight > video.videoWidth) {
                      //     video.classList.add("img-fit-height");
                      //   } else {
                      //     video.classList.add("img-fit-width");
                      //   }
                      // }}
                      autoPlay
                      controls
                    /> */}
                    <video
                      src={postDetail?.extraProperties?.MergedVideo[0]}
                      controls
                      controlsList="nodownload"
                      disablePictureInPicture
                      autoPlay
                      poster={postDetail.extraProperties.CoverImage}
                      width="100%"
                    ></video>
                  </Carousel.Item>
                )}
                {postDetail?.extraProperties?.Video && (
                  <Carousel.Item>
                    {/* <video
                      src={postDetail?.extraProperties?.Video[0]}
                      // onLoadedMetadata={(e) => {
                      //   const video = e.target as HTMLVideoElement;
                      //   if (video.videoHeight > video.videoWidth) {
                      //     video.classList.add("img-fit-height");
                      //   } else {
                      //     video.classList.add("img-fit-width");
                      //   }
                      // }}
                      autoPlay
                      controls
                    /> */}
                    <video
                      src={postDetail?.extraProperties?.Video[0]}
                      autoPlay
                      controlsList="nodownload"
                      disablePictureInPicture
                      controls
                      poster={postDetail.extraProperties.CoverImage}
                      width="100%"
                    ></video>
                  </Carousel.Item>
                )}
              </Carousel>
            </div>
            <div className="post-interaction-content">
              <HouseDetailCard
                commentSecRef={commentSecRef}
                postId={props?.postId}
                setIsShow={props.setIsShow}
                postDetail={postDetail}
              />
              <div className="social-main-sec">
                <div className="social-action-group r-16">
                  <CommentInput
                    postId={props?.postId}
                    value={message}
                    placeholder="留言..."
                    onChange={(msgInputValue) => setMessage(msgInputValue)}
                  />
                  <button
                    className={`like-record record-btn ${
                      postDetail?.isLiked && "post-liked"
                    }`}
                    onClick={() => {
                      isAuthenticated
                        ? handleLikePostAsync()
                        : setShowAuth?.(true);
                    }}
                  >
                    <img
                      src={TemplateCardImg.srcEmpty_heart_icon}
                      alt="heart-icon"
                    />
                    <span>
                      {postDetail?.likes >= 1000
                        ? (Number(postDetail?.likes) / 1000).toFixed(1) + "K"
                        : Number(postDetail?.likes)}
                    </span>
                  </button>
                  <button
                    className="msg-record record-btn"
                    onClick={scrollToCommentSecRef}
                  >
                    <img src={TemplateCardImg.srcChat_icon} alt="msg-icon" />
                    <span>
                      {postDetail?.comments >= 1000
                        ? (Number(postDetail?.comments) / 1000).toFixed(1) + "K"
                        : Number(postDetail?.comments)}
                    </span>
                  </button>
                  <SocialMediaShare postDetail={postDetail} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostPopupModel;
