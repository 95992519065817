//react
import { KeyboardEvent } from "react";
//style
import "./Input.scss";

interface InputProps {
  type: string;
  name: string;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent<Element>) => void;
}

const Input: React.FC<InputProps> = (props) => {
  return (
    <div>
      <input
        className="primary-input r-16"
        type={props.type}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(e.target.value)}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
};

export default Input;
