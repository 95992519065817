export const filterData = {
  privacy: [
    {
      option: "私人",
      key: "status-1",
    },
    {
      option: "公開",
      key: "status-2",
    },
  ],
  regions: [
    {
      option: "西屯 / 西區",
      key: "district-1",
    },
    {
      option: "北屯 / 北區",
      key: "district-2",
    },
    {
      option: "南屯 / 南區",
      key: "district-3",
    },
    {
      option: "東區 / 中區",
      key: "district-4",
    },
    {
      option: "太平 / 霧峰區",
      key: "district-5",
    },
    {
      option: "大里 / 烏日區",
      key: "district-6",
    },
    {
      option: "大雅 / 豐原 / 潭子 / 神岡區",
      key: "district-7",
    },
    {
      option: "沙鹿 / 清水 / 梧棲 / 龍井區",
      key: "district-8",
    },
    {
      option: "其他",
      key: "district-9",
    },
  ],
  houseType: [
    {
      option: "大樓",
      key: "type-1",
    },
    {
      option: "公寓",
      key: "type-2",
    },
    {
      option: "華廈",
      key: "type-3",
    },
    {
      option: "透天",
      key: "type-4",
    },
    {
      option: "別墅",
      key: "type-5",
    },
    {
      option: "土地",
      key: "type-6",
    },
    {
      option: "其他",
      key: "type-7",
    },
  ],
  rentPrice: [
    {
      option: "0-5000",
      key: "price-1",
    },
    {
      option: "5000-15000",
      key: "price-2",
    },
    {
      option: "15000-25000",
      key: "price-3",
    },
    {
      option: "25000-35000",
      key: "price-4",
    },
    {
      option: "35000-45000",
      key: "price-5",
    },
    {
      option: "45000以上",
      key: "price-6",
    },
    {
      option: "其他",
      key: "price-7",
    },
  ],
  sellPrice: [
    {
      option: "500萬以下",
      key: "price-1",
    },
    {
      option: "501萬-1000萬",
      key: "price-2",
    },
    {
      option: "1001萬-1500萬",
      key: "price-3",
    },
    {
      option: "1501萬-2000萬",
      key: "price-4",
    },
    {
      option: "2001萬-3000萬",
      key: "price-5",
    },
    {
      option: "3001萬-4000萬",
      key: "price-6",
    },
    {
      option: "4001萬以上",
      key: "price-7",
    },
    {
      option: "其他",
      key: "price-8",
    },
  ],
  houseLayout: [
    {
      option: "1房",
      key: "layout-1",
    },
    {
      option: "2房",
      key: "layout-2",
    },
    {
      option: "3房",
      key: "layout-3",
    },
    {
      option: "4房",
      key: "layout-4",
    },
    {
      option: "4房以上",
      key: "layout-5",
    },
    {
      option: "其他",
      key: "layout-6",
    },
  ],
  houseAge: [
    {
      option: "5年以下",
      key: "age-range-1",
    },
    {
      option: "5-10年",
      key: "age-range-2",
    },
    {
      option: "10-20年",
      key: "age-range-3",
    },
    {
      option: "20-30年",
      key: "age-range-4",
    },
    {
      option: "30-40年",
      key: "age-range-5",
    },
    {
      option: "其他",
      key: "age-range-6",
    },
  ],
};
