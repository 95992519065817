//type
import { TypeFollower } from "../../types/user";
import { useNavigate } from "react-router-dom";
//img
import { TemplateCardImg } from "../../assets/data/imagesData";
// context
import { useAuth } from "../../context/AuthContext";
//style
import "./FollowerPopupModel.scss";

interface FollowerPopupModalProps {
  setIsShow: (show: boolean) => void;
  followers: TypeFollower[];
}

const FollowerPopupModal: React.FC<FollowerPopupModalProps> = (props) => {
  const navigate = useNavigate();

  // context - memberId
  const { currentMember } = useAuth();

  return (
    <div className="follower-popup-modal">
      <div className="follower-popup-container">
        <div className="close-btn" onClick={() => props.setIsShow(false)}>
          <img src={TemplateCardImg.srcClose_icon} alt="close-icon" />
        </div>
        <div className="popup-title sb-24">{`粉絲 (${props?.followers?.length})`}</div>
        <div className="follower-list-container">
          {props?.followers?.map((follower) => {
            return (
              <div
                key={follower?.id}
                className="followr-card"
                onClick={() => {
                  if (follower?.id !== currentMember?.currentMemberId) {
                    navigate(`/userinfo/${follower?.id?.substring(0, 16)}`, {
                      state: { userId: follower?.id },
                    });
                  } else {
                    navigate(
                      `/myinfo/${currentMember?.currentMemberId?.substring(
                        0,
                        16
                      )}`
                    );
                  }

                  props.setIsShow(false);
                }}
              >
                <div className="follower-img">
                  <img src={follower?.personalAvatar} alt="follower-img" />
                </div>
                <div>
                  <span className="sb-14">{follower?.userName}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FollowerPopupModal;
