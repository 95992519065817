//react
import { useState, useRef, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
//components
import NotificationCard from "../NotificationCard/NotificationCard";
import NewNotifiPopup from "../NewNotifiPopup/NewNotifiPopup";
//firebase
import { generateToken, receiveNotification } from "../../firebase-config";
//bootstrap
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
//api
import { getUserNotification, postUpateDeviceToken } from "../../api/users";
//context
import { useAuth } from "../../context/AuthContext";
//type
import { TypeNotifi } from "../../types/user";
//img
import {
  TemplateOtherImg,
  TemplateCardImg,
} from "../../assets/data/imagesData";
//style
import "./Notification.scss";

const Notification: React.FC = () => {
  const [show, setShow] = useState(false); // popup box
  const [withinOneDay, setWithinOneDay] = useState(false);

  const [newNotification, setNewnotification] = useState<TypeNotifi>({
    body: "",
    chatroomId: "",
    commentId: "",
    id: "",
    isRead: false,
    notificationType: "",
    postId: "",
    receiverMemberId: "",
    senderMemberAvatar: "",
    senderMemberId: "",
    time: "",
    title: "",
    userName: "",
  });
  const [hasNewNotifi, setHasNewNotifi] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  // context - memberId
  const { currentMember, setDeviceToken } = useAuth();

  //navigation
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      // Check if the click event target is outside the popover
      if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
        setShow(false); // Close the popover
      }
    };

    // Attach event listener when the popover is open
    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up the event listener
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show]);

  const { data: notifications } = useQuery<TypeNotifi[]>(
    ["notifications"],
    async () => await getUserNotification(currentMember?.currentMemberId)
  );

  // Handle Notification with firebase
  useEffect(() => {
    const getFirebaseToken = async () => {
      const token = await generateToken();

      if (token) {
        postUpateDeviceToken({
          memberId: currentMember?.currentMemberId,
          token: token,
        });
        setDeviceToken?.(token);
      }
    };

    getFirebaseToken();
  }, [currentMember?.currentMemberId]);

  const ReceiveNewNotifi = async () => {
    try {
      const newNotifi = await receiveNotification();

      const newArrival = {
        body: newNotifi?.notification?.body
          ? newNotifi?.notification?.body
          : "",
        chatroomId: newNotifi?.data?.chatroomId
          ? newNotifi?.data?.chatroomId
          : "",
        commentId: newNotifi?.data?.commentId ? newNotifi?.data?.commentId : "",
        id: newNotifi?.messageId,
        notificationType: newNotifi?.data?.notificationType
          ? newNotifi?.data?.notificationType
          : "",
        isRead: false,
        postId: newNotifi?.data?.postId ? newNotifi?.data?.postId : "",
        receiverMemberId: newNotifi?.data?.receiverMemberId
          ? newNotifi?.data?.receiverMemberId
          : "",
        senderMemberAvatar:
          newNotifi?.data?.notificationType !== "System Notify"
            ? newNotifi?.data?.senderMemberAvatar
            : TemplateCardImg?.srcService_logo,
        senderMemberId: newNotifi?.data?.senderMemberId
          ? newNotifi?.data?.senderMemberId
          : "",
        time: newNotifi?.data?.time ? newNotifi?.data?.time : "",
        title: newNotifi?.notification?.title
          ? newNotifi?.notification?.title
          : "",
        userName: newNotifi?.data?.userName ? newNotifi?.data?.userName : "",
      };

      if (newArrival) {
        queryClient.invalidateQueries("notifications");
        setNewnotification(newArrival);
        setHasNewNotifi(true);

        if (
          newArrival?.body === "貼文影片已串接完成" &&
          pathname.includes("myinfo")
        ) {
          queryClient.invalidateQueries("/posts");
        }
      }
    } catch (error) {
      return error;
    }
  };

  ReceiveNewNotifi();

  // check if there is a notification that arrives today in order to put on the light of notificaiton
  const isToday = (someDate: Date) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  useEffect(() => {
    const todayNotfis = notifications?.filter((notifi) =>
      isToday(new Date(notifi?.time))
    );

    const hasUnread = todayNotfis?.some((notifi) => notifi?.isRead === false);

    hasUnread ? setWithinOneDay(true) : setWithinOneDay(false);
  }, [notifications]);

  return (
    <>
      <div className="notif-box" ref={ref}>
        {withinOneDay && (
          <div className="notifi-light">
            <span></span>
          </div>
        )}
        <Button
          className="notif-btn"
          onClick={(e) => {
            setShow(!show);
            setTarget(e.target as HTMLElement);
            queryClient.invalidateQueries("notifications"); // refetch as clicking
          }}
        >
          <div className="nitifi-icon">
            <img src={TemplateOtherImg.srcBell_icon} alt="notif-icon" />
          </div>
        </Button>

        <Overlay show={show} target={target} placement="bottom" container={ref}>
          <Popover>
            <Popover.Body>
              {notifications?.length === 0 && (
                <div className="empty-notification-box">目前沒有通知</div>
              )}
              {notifications?.map((notifi) => (
                <NotificationCard
                  key={notifi?.id}
                  notifi={notifi}
                  setShow={setShow}
                />
              ))}
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
      {hasNewNotifi && (
        <NewNotifiPopup
          setHasNewNotifi={setHasNewNotifi}
          newNotification={newNotification}
        />
      )}
    </>
  );
};

export default Notification;
