import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
//style
import "./LinearProgressWithLabel.scss";

interface LinearProgressWithLabelProps {
  showProgress: boolean;
  value: number;
}

const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = (
  props
) => {
  useEffect(() => {
    if (props?.showProgress) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup the class when the component is unmounted
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [props?.showProgress]);

  return (
    <div className="linear-progress-with-label">
      <div className="linear-progress-inner-container">
        <Box display="flex" alignItems="center">
          <Box width="100%">
            <LinearProgress
              sx={{
                //height: "6px",
                borderRadius: "12px",
                backgroundColor: "#1A1A1A",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "white",
                  borderRadius: "12px",
                },
              }}
              variant="determinate"
              {...props}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="white"
              sx={{ position: "absolute", top: "-26px", right: "0" }}
            >{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="body2"
            color="white"
            sx={{ position: "absolute", top: "-26px", left: "0" }}
          >{`檔案上傳中`}</Typography>
        </Box>
      </div>
    </div>
  );
};

export default LinearProgressWithLabel;
