//react
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//api
import { getHasOrderActive } from "../../api/membership";
//context
import { useAuth } from "../../context/AuthContext";
import { usePost } from "../../context/PostProvider";
//components
import TitleHeader from "../../components/TitleHeader/TitleHeader";
import NoPlanPopupModel from "../../components/NoPlanPopupModel/NoPlanPopupModel";
//hooks
import useConfirmNavigation from "../../hooks/useConfirmNavigation";
//img
import {
  TemplateOtherImg,
  TemplateCardImg,
} from "../../assets/data/imagesData";
//style
import "./PublishPage.scss";

const PublishPage = () => {
  const [hasPlan, setHasPlan] = useState(false);
  const [postType, setPostType] = useState("image");
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [fileWarning, setFileWarning] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const maxImgSizeBytes = 500 * 1024 * 1024;
  const maxVideoSizeBytes = 500 * 1024 * 1024;
  const maxVideoDuration = 120;

  // Store Files fetched from api for img and video editing
  const [imgFiles, setImgFiles] = useState<string[]>([]);
  const [videoFile, setVideoFile] = useState("");

  //archive removed files in api
  const [removedFiles, setRemoveFiles] = useState<string[]>([]);

  // implement navigation and bring data onto next page
  const navigate = useNavigate();

  // Async function to calculate duration of files for api constraint
  const durationOfFiles = async (passedFiles: File[]) => {
    try {
      // sort out to get only files of video
      const videoFiles = passedFiles?.filter((file) =>
        file.type.includes("video")
      );

      // sort out to get only files of image
      const imgFiles = passedFiles?.filter((file) =>
        file.type.includes("image")
      );

      // Array to store promises for loading video metadata
      const promises = videoFiles.map(
        (file: File) =>
          new Promise<number>((resolve) => {
            const video = document.createElement("video");
            video.preload = "metadata";

            video.onloadedmetadata = () => {
              resolve(video.duration);
              URL.revokeObjectURL(video.src); // Clean up object URL
            };

            video.onerror = () => {
              resolve(0); // Resolve with 0 on error to not break the Promise.all
              URL.revokeObjectURL(video.src); // Clean up object URL
            };

            video.src = URL.createObjectURL(file);
          })
      );

      // Wait for all promises to resolve (array of durations of videos)
      const durations = await Promise.all(promises);

      // Calculate the total duration
      const videoDuration = durations.reduce(
        (acc, duration) => acc + duration,
        0
      );

      // Calculate duration of images
      const imgDuration = imgFiles.length * 4;

      const totalDuration = videoDuration + imgDuration;

      return totalDuration;
    } catch (error) {
      console.error("Error Processing Duration of Videos");
      return 0; // Return 0 as default duration in case of error
    }
  };

  // function to calculate ratios of images and videos
  // const calculateRatios = async (files: File[]) => {
  //   const ratioPromises = files?.map((file) => {
  //     return new Promise<number>((resolve) => {
  //       if (file.type.includes("image")) {
  //         const img = new Image();
  //         img.onload = () => {
  //           resolve(img.naturalWidth / img.naturalHeight);
  //         };
  //         img.onerror = () => resolve(0);
  //         img.src = URL.createObjectURL(file);
  //       } else {
  //         const video = document.createElement("video");
  //         video.onloadedmetadata = () => {
  //           resolve(video.videoWidth / video.videoHeight);
  //         };
  //         video.onerror = () => resolve(0);
  //         video.src = URL.createObjectURL(file);
  //       }
  //     });
  //   });

  //   const ratios = await Promise.all(ratioPromises);

  //   const filesAndRatios = ratios?.map((ratio, i) => {
  //     return { file: files[i], ratio };
  //   });

  //   return filesAndRatios;
  // };

  // function to calculate dimensions of images and videos
  const calculateRatios = async (files: File[]) => {
    const ratioPromises = files?.map((file) => {
      return new Promise<{ width: number; height: number }>((resolve) => {
        if (file.type.includes("image")) {
          const img = new Image();
          img.onload = () => {
            resolve({ width: img.naturalWidth, height: img.naturalHeight });
          };
          img.onerror = () => resolve({ width: 0, height: 0 });
          img.src = URL.createObjectURL(file);
        } else {
          const video = document.createElement("video");
          video.onloadedmetadata = () => {
            resolve({ width: video.videoWidth, height: video.videoHeight });
          };
          video.onerror = () => resolve({ width: 0, height: 0 });
          video.src = URL.createObjectURL(file);
        }
      });
    });

    const ratios = await Promise.all(ratioPromises);

    const filesAndRatios = ratios?.map((size, i) => {
      return { file: files[i], dimension: size };
    });

    return filesAndRatios;
  };

  // check if same file already exists
  const isFileExists = (newFile: File) => {
    return uploadedFiles.some(
      (file) => file.name === newFile.name && file.size === newFile.size
    );
  };

  const generateRandomFileName = (file: File) => {
    // Extract the original file extension
    const extension = file?.name?.trim()?.substring(0, 2);

    // Generate a random string for the new file name
    const randomName = Math.random().toString(36).substring(2, 11);

    // Create the new file name with the original extension
    const newFileName = `${randomName}${extension}`;

    // Create a new File object with the updated name
    const updatedFile = new File([file], newFileName, { type: file.type });

    return updatedFile;
  };

  // Prevent Default from drop event
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const preventDragHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // Drop images or one video - handler
  const handleImgDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (videoFile) {
      setFileWarning(true);
      setErrorMsg("須先把目前影片刪除，才能重新上傳串接內容");
      return;
    }

    const droppedFiles = Array.from(e.dataTransfer.files); // as File[]
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "video/mp4",
      "video/quicktime",
    ];

    //prevent duplicated file
    const noneDuplicatedFiles = droppedFiles.filter(
      (file) => !isFileExists(file)
    );

    // Control error
    setFileWarning(false);
    setErrorMsg("");

    noneDuplicatedFiles.map((file) => {
      if (!allowedTypes.includes(file.type)) {
        setFileWarning(true);
        setErrorMsg("只支援上傳JPG、PNG、MP4、MOV");
      }
      // else if (file.size > maxImgSizeBytes && file?.type?.includes("image")) {
      //   setFileWarning(true);
      //   setErrorMsg("不可以有檔案大小超過 100mb");
      // } else if (
      //   file.size > maxVideoSizeBytes &&
      //   file?.type?.includes("video")
      // ) {
      //   setFileWarning(true);
      //   setErrorMsg("影片大小超過不可超過 500mb");
      // }

      return file;
    });

    // Filter dropped files to include only JPG, PNG, MP4, MOV formats
    const validFiles = noneDuplicatedFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    // Limit combination of video and image
    const oneformat = noneDuplicatedFiles?.[0]?.type?.slice(
      0,
      noneDuplicatedFiles?.[0]?.type?.indexOf("/")
    );
    const isOneFormat = noneDuplicatedFiles?.every(
      (file) => oneformat === file?.type?.slice(0, file?.type?.indexOf("/"))
    );

    if (!isOneFormat) {
      setFileWarning(true);
      setErrorMsg((prev) =>
        prev ? prev + "，不能同時上傳圖片及影片" : "不能同時上傳圖片及影片"
      );
      return;
    }

    // Prevent uploading more than two videos
    if (
      noneDuplicatedFiles?.[0]?.type.includes("video") &&
      noneDuplicatedFiles?.[1]?.type.includes("video")
    ) {
      setFileWarning(true);
      setErrorMsg("只能上傳單一影片，請至發佈串接上傳多部影片");
      return;
    }

    // Limit the size of uploaded files
    const existingFileSize =
      uploadedFiles?.reduce((acc, file) => acc + file.size, 0) || 0;

    const validFilesUnderMaxImgSize = validFiles.filter((file) => {
      const newTotalSize = existingFileSize + file.size;

      if (newTotalSize > maxImgSizeBytes) {
        setFileWarning(true);
        setErrorMsg("上傳上限不可超過 500mb");
      }

      return newTotalSize <= maxImgSizeBytes;
    });

    let oneFormatFiles: File[] = [];

    if (uploadedFiles?.[0]?.type.includes("image")) {
      oneFormatFiles = validFilesUnderMaxImgSize?.filter((file) => {
        if (file?.type?.includes("video")) {
          setFileWarning(true);
          setErrorMsg("圖片貼文不能包含影片");
        }
        return file.type.includes("image");
      });
    } else if (uploadedFiles?.[0]?.type.includes("video")) {
      oneFormatFiles = validFilesUnderMaxImgSize?.filter((file) => {
        if (file?.type?.includes("image")) {
          setFileWarning(true);
          setErrorMsg("影片貼文不能包含圖片");
        }
        return file.type.includes("video");
      });
    } else {
      oneFormatFiles = validFilesUnderMaxImgSize;
    }

    let acceptedFiles: File[] = [];

    if (
      oneFormatFiles?.[0]?.type.includes("video") &&
      uploadedFiles.length >= 1 &&
      uploadedFiles?.[0]?.type.includes("video")
    ) {
      setFileWarning(true);
      setErrorMsg("只能上傳單一影片，請至發佈串接上傳多部影片");
      //acceptedFiles = oneFormatFiles;
      //acceptedFiles = [uploadedFiles[0]];
    } else {
      acceptedFiles = oneFormatFiles;
    }

    // Filter dropped files to include only JPG, PNG, MP4, MOV formats
    // const validFiles = acceptedFiles.filter((file) =>
    //   allowedTypes.includes(file.type)
    // );

    // Filter valid files to include only image under 10 MB and video under 128 MB
    // const validFilesUnderMaxSize = acceptedFiles.filter((file) =>
    //   file?.type?.includes("image")
    //     ? file.size <= maxImgSizeBytes
    //     : file.size <= maxVideoSizeBytes
    // );

    // Control error if files are more than 12 images
    if (uploadedFiles.length + acceptedFiles.length + imgFiles?.length > 12) {
      setFileWarning(true);
      setErrorMsg("上傳上限為12張圖片");
    }

    // Push files into state to store images
    uploadedFiles.length + acceptedFiles.length + imgFiles?.length < 13 &&
      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };

  // click to upload images or video - handler
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoFile) {
      setFileWarning(true);
      setErrorMsg("須先把目前影片刪除，才能重新上傳串接內容");
      return;
    }

    const target = e.target as HTMLInputElement;
    const files = target.files; // file list or null

    if (files) {
      const uploadPostFiles = Array.from(files);

      //prevent duplicated file
      const noneDuplicatedFiles = uploadPostFiles.filter(
        (file) => !isFileExists(file)
      );

      // Control error if files have image above 10 MB
      setFileWarning(false);

      // Limit combination of video and image
      const oneformat = noneDuplicatedFiles?.[0]?.type?.slice(
        0,
        noneDuplicatedFiles?.[0]?.type?.indexOf("/")
      );
      const isOneFormat = noneDuplicatedFiles?.every(
        (file) => oneformat === file?.type?.slice(0, file?.type?.indexOf("/"))
      );

      if (!isOneFormat) {
        setFileWarning(true);
        setErrorMsg("不能同時上傳圖片及影片");
        return;
      }

      // Prevent uploading more than two videos
      if (
        noneDuplicatedFiles?.[0]?.type.includes("video") &&
        noneDuplicatedFiles?.[1]?.type.includes("video")
      ) {
        setFileWarning(true);
        setErrorMsg("只能上傳單一影片，請至發佈串接上傳多部影片");
        return;
      }

      // Limit the size of uploaded files
      const existingFileSize =
        uploadedFiles?.reduce((acc, file) => acc + file.size, 0) || 0;

      const validFilesUnderMaxImgSize = noneDuplicatedFiles.filter((file) => {
        const newTotalSize = existingFileSize + file.size;

        if (newTotalSize > maxImgSizeBytes) {
          setFileWarning(true);
          setErrorMsg("上傳上限不可超過 500mb");
        }

        return newTotalSize <= maxImgSizeBytes;
      });

      let oneFormatFiles: File[] = [];

      if (uploadedFiles?.[0]?.type.includes("image")) {
        oneFormatFiles = validFilesUnderMaxImgSize?.filter((file) => {
          if (file?.type?.includes("video")) {
            setFileWarning(true);
            setErrorMsg("圖片貼文不能包含影片");
          }
          return file.type.includes("image");
        });
      } else if (uploadedFiles?.[0]?.type.includes("video")) {
        oneFormatFiles = validFilesUnderMaxImgSize?.filter((file) => {
          if (file?.type?.includes("image")) {
            setFileWarning(true);
            setErrorMsg("影片貼文不能包含圖片");
          }
          return file.type.includes("video");
        });
      } else {
        oneFormatFiles = validFilesUnderMaxImgSize;
      }

      let acceptedFiles: File[] = [];

      if (
        oneFormatFiles?.[0]?.type.includes("video") &&
        uploadedFiles.length >= 1 &&
        uploadedFiles?.[0]?.type.includes("video")
      ) {
        setFileWarning(true);
        setErrorMsg("只能上傳單一影片，請至發佈串接上傳多部影片");
        //acceptedFiles = oneFormatFiles;
        //acceptedFiles = [uploadedFiles[0]];
      } else {
        acceptedFiles = oneFormatFiles;
      }

      // Control error if files are more than 12 images
      if (uploadedFiles.length + acceptedFiles.length + imgFiles?.length > 12) {
        setFileWarning(true);
        setErrorMsg("上傳上限為12張圖片");
      }

      // Push files into state to store images
      uploadedFiles.length + acceptedFiles.length + imgFiles?.length < 13 &&
        setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
    }

    //clean value to enable same files to be uploaded again
    target.value = "";
  };

  // Drop Videos - handler
  const handleVideoDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (videoFile) {
      setFileWarning(true);
      setErrorMsg("須先把目前影片刪除，才能重新上傳串接內容");
      return;
    }

    const droppedFiles = Array.from(e.dataTransfer.files); // as File[]
    const allowedTypes = ["video/mp4", "video/quicktime"];

    //prevent duplicated file
    const noneDuplicatedFiles = droppedFiles.filter(
      (file) => !isFileExists(file)
    );

    setFileWarning(false);

    noneDuplicatedFiles.map((file) => {
      if (!allowedTypes.includes(file.type)) {
        setFileWarning(true);
        setErrorMsg("只支援上傳MP4、MOV");
      }
      return file;
    });

    // Filter dropped files to include only MP4、MOV、JPG、PNG formats
    const validTypeFiles = noneDuplicatedFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    // Limit the size of uploaded files
    const existingFileSize =
      uploadedFiles?.reduce((acc, file) => acc + file.size, 0) || 0;

    const validFilesUnderMaxVideoSize = validTypeFiles.filter((file) => {
      const newTotalSize = existingFileSize + file.size;

      if (newTotalSize > maxVideoSizeBytes) {
        setFileWarning(true);
        setErrorMsg("上傳上限不可超過 500mb");
      }

      return newTotalSize <= maxVideoSizeBytes;
    });

    // if (validFilesUnderMaxVideoSize.length !== validTypeFiles.length) {
    //   setFileWarning(true);
    //   setErrorMsg("上傳上限不可超過 128mb");
    // }

    // generate new names to prevent error of video name from FFmpeg on sever side
    const filesWithNewNames = validFilesUnderMaxVideoSize?.map((file) =>
      generateRandomFileName(file)
    );

    // Make sure duration of videos and images is not more than 2 mins and ratios are the same
    const calculateDurationAndRatio = async () => {
      try {
        // calculate rations of files
        const filesWithRatios = await calculateRatios([
          ...uploadedFiles,
          ...filesWithNewNames,
        ]);

        // fire error if there is a file at a different ratio
        filesWithRatios?.forEach((file) => {
          if (
            file?.dimension?.width !== filesWithRatios[0]?.dimension?.width ||
            file?.dimension?.height !== filesWithRatios[0]?.dimension?.height
          ) {
            setFileWarning(true);
            setErrorMsg("上傳尺寸需一致");
          }
        });

        // filter out file at a different ratio
        const validFilesWithSameRatio = filesWithRatios?.filter(
          (file) =>
            file?.dimension?.width === filesWithRatios[0]?.dimension?.width &&
            file?.dimension?.height === filesWithRatios[0]?.dimension?.height
        );

        const validFilesArr = validFilesWithSameRatio?.map(
          (file) => file?.file
        );

        const validFileDuration = await durationOfFiles(validFilesArr);

        if (validFileDuration <= maxVideoDuration) {
          setUploadedFiles([...validFilesArr]);
        } else {
          setFileWarning(true);
          setErrorMsg("總上傳長度不得超過 2分鐘");
        }
      } catch (error) {
        return error;
      }
    };

    calculateDurationAndRatio();
  };

  // click to upload vidoes - handler
  const handleVideoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (videoFile) {
      setFileWarning(true);
      setErrorMsg("須先把目前影片刪除，才能重新上傳串接內容");
      return;
    }

    const target = e.target as HTMLInputElement;
    const files = target.files; // file list or null

    if (files) {
      const uploadVideoFiles = Array.from(files);

      //prevent duplicated file
      const noneDuplicatedFiles = uploadVideoFiles.filter(
        (file) => !isFileExists(file)
      );

      // Control error if files are above 128 MB
      setFileWarning(false);

      // Limit the size of uploaded files
      const existingFileSize =
        uploadedFiles?.reduce((acc, file) => acc + file.size, 0) || 0;

      const validFilesUnderMaxVideoSize = noneDuplicatedFiles.filter((file) => {
        const newTotalSize = existingFileSize + file.size;

        if (newTotalSize > maxVideoSizeBytes) {
          setFileWarning(true);
          setErrorMsg("上傳上限不可超過 500mb");
        }

        return newTotalSize <= maxVideoSizeBytes;
      });

      // if (validFilesUnderMaxVideoSize.length !== uploadVideoFiles.length) {
      //   setFileWarning(true);
      //   setErrorMsg("上傳上限不可超過 128mb");
      // }

      // generate new names to prevent error of video name from FFmpeg on sever side
      const filesWithNewNames = validFilesUnderMaxVideoSize?.map((file) =>
        generateRandomFileName(file)
      );

      // Make sure duration of videos and images is not more than 2 mins and ratios are the same
      const calculateDurationAndRatio = async () => {
        try {
          // calculate rations of files
          const filesWithRatios = await calculateRatios([
            ...uploadedFiles,
            ...filesWithNewNames,
          ]);

          // fire error if there is a file at a different ratio
          filesWithRatios?.forEach((file) => {
            if (
              file?.dimension?.width !== filesWithRatios[0]?.dimension?.width ||
              file?.dimension?.height !== filesWithRatios[0]?.dimension?.height
            ) {
              setFileWarning(true);
              setErrorMsg("上傳尺寸需一致");
            }
          });

          // filter out file at a different ratio
          const validFilesWithSameRatio = filesWithRatios?.filter(
            (file) =>
              file?.dimension?.width === filesWithRatios[0]?.dimension?.width &&
              file?.dimension?.height === filesWithRatios[0]?.dimension?.height
          );

          const validFilesArr = validFilesWithSameRatio?.map(
            (file) => file?.file
          );

          const validFileDuration = await durationOfFiles(validFilesArr);

          if (validFileDuration <= maxVideoDuration) {
            setUploadedFiles([...validFilesArr]);
          } else {
            setFileWarning(true);
            setErrorMsg("總上傳長度不得超過 2分鐘");
          }
        } catch (error) {
          return error;
        }
      };

      calculateDurationAndRatio();
    }

    //clean value to enable same files to be uploaded again
    target.value = "";
  };

  // re-edit files of post
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.status === "creating") {
      setUploadedFiles(location?.state?.uploadedFiles);
      setPostType(location?.state?.postType);
    } else if (location?.state?.status === "editing") {
      setUploadedFiles(location?.state?.uploadedFiles);
      setImgFiles(location?.state?.imgFiles);
      setPostType(location?.state?.postType);
      setVideoFile(location?.state?.videoFile);
      setRemoveFiles(location?.state?.removedFiles);
    }
  }, []);

  // check if user has active plan and show popup if not
  const { currentMember } = useAuth();

  useEffect(() => {
    if (location.pathname === "/publish") {
      const checkHasPlanAsync = async () => {
        const res = await getHasOrderActive(currentMember?.currentMemberId);

        setHasPlan(res?.data);
      };

      checkHasPlanAsync();
    }

    location.pathname === "/re-publish" && setHasPlan(true);
  }, [currentMember?.currentMemberId, location.pathname]);

  // control popup whether there is uploaded files
  useConfirmNavigation();

  const { setHasContent, setPath } = usePost();

  useEffect(() => {
    uploadedFiles?.length && setHasContent?.(true);
    location?.state?.status === "editing" && setHasContent?.(true);

    if (!uploadedFiles?.length && !imgFiles?.length && !videoFile) {
      setHasContent?.(false);
      setPath?.("");
    }
  }, [uploadedFiles, imgFiles, videoFile]);

  useEffect(() => {
    return () => {
      setHasContent?.(false);
      setPath?.("");
    };
  }, []);

  return (
    <>
      <TitleHeader title="發佈" />
      <div className="publish-main-container main-body-spacing">
        <div className="publish-btn-group">
          {uploadedFiles.length >= 1 || imgFiles.length >= 1 || videoFile ? (
            <div className="btn-group-next">
              {fileWarning && (
                <div className="size-warning sb-14" style={{ color: "red" }}>
                  {errorMsg}
                </div>
              )}
              <label className="upload-btn primary-btn" htmlFor="files-99">
                上傳更多
              </label>
              <button
                className="white-btn"
                onClick={() => {
                  if (!location?.state) {
                    navigate("/editpost", {
                      state: {
                        uploadedFiles: uploadedFiles,
                        status: "creating",
                        postType: postType,
                      },
                    });
                  } else {
                    const currentState =
                      location?.state?.status === "creating"
                        ? {
                            uploadedFiles: uploadedFiles,
                            thumbnail: location?.state?.thumbnail,
                            status: location?.state?.status,
                            postType: postType,
                            content: location?.state?.content,
                            isReEdit: true,
                          }
                        : {
                            uploadedFiles: uploadedFiles,
                            thumbnail: location?.state?.thumbnail,
                            imgFiles: imgFiles,
                            videoFile: videoFile,
                            coverImg: location?.state?.coverImg,
                            removedFiles: removedFiles,
                            removedCover: location?.state?.removedCover,
                            status: location?.state?.status,
                            postType: postType,
                            content: location?.state?.content,
                            isReEdit: true,
                            postId: location?.state?.postId,
                          };

                    navigate("/editpost", {
                      state: currentState,
                    });
                  }
                }}
              >
                下一步
              </button>
            </div>
          ) : (
            <div className="btn-group-publish">
              {!location?.state?.isReEdit && (
                <button
                  className={`primary-btn ${
                    postType === "image" && "secondary-btn"
                  }`}
                  onClick={() => {
                    setPostType("image");
                    setFileWarning(false);
                  }}
                >
                  發佈貼文
                </button>
              )}
              {!location?.state?.isReEdit && (
                <button
                  className={`primary-btn ${
                    postType === "video" && "secondary-btn"
                  }`}
                  onClick={() => {
                    setPostType("video");
                    setFileWarning(false);
                  }}
                >
                  發佈串接
                </button>
              )}
              {fileWarning && (
                <div className="size-warning sb-14" style={{ color: "red" }}>
                  {errorMsg}
                </div>
              )}
            </div>
          )}
        </div>
        <label
          htmlFor={`${
            uploadedFiles.length < 1 && imgFiles.length < 1 ? "files-99" : ""
          }`}
          className={`${uploadedFiles.length >= 1 ? "has-img-label" : ""}`}
        >
          <div
            className={`upload-area ${
              uploadedFiles.length >= 1 ||
              imgFiles.length >= 1 ||
              videoFile !== ""
                ? "has-img"
                : ""
            }`}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={postType === "image" ? handleImgDrop : handleVideoDrop}
          >
            <div className="upload-file-group">
              {uploadedFiles.length < 1 &&
              imgFiles?.length < 1 &&
              videoFile === "" ? (
                <div className="upload-text">
                  <img
                    src={TemplateOtherImg.srcUpload_icon}
                    alt="upload-icon"
                  />
                  <span className="sb-24">點擊或拖移上傳檔案</span>
                  <span className="sb-16 reminder">
                    {postType === "image"
                      ? "支援檔案格式為：JPG、PNG、MP4、MOV，上傳總檔案大小不超過 500mb，影片只能上傳ㄧ部"
                      : "支援檔案格式為：MP4、MOV，上傳總檔案大小不得超過 500mb，上傳需比例一致，不超過 2分鐘"}
                  </span>
                </div>
              ) : (
                uploadedFiles.map((file, i) => (
                  <div
                    key={`upload-${i}`}
                    className="upload-file"
                    onDragStart={preventDragHandler}
                  >
                    <div className="delete-box">
                      <img
                        src={TemplateCardImg.srcClose_icon}
                        alt="delete-icon"
                        onClick={() => {
                          setUploadedFiles((prev) =>
                            prev.filter((img, index) => i !== index)
                          );
                          setFileWarning(false);
                        }}
                      />
                    </div>
                    {file?.type.includes("video") ? (
                      <video
                        src={URL.createObjectURL(file)}
                        //controls
                        //width="200"
                      />
                    ) : (
                      <img
                        src={URL.createObjectURL(file)}
                        alt={`Uploaded ${file.name}`}
                      />
                    )}
                  </div>
                ))
              )}
              {imgFiles?.map((img, i) => (
                <div key={`re-upload-${i}`} className="upload-file">
                  <div className="delete-box">
                    <img
                      src={TemplateCardImg.srcClose_icon}
                      alt="delete-icon"
                      onClick={() => {
                        setImgFiles((prev) =>
                          prev?.filter((_, index) => i !== index)
                        );
                        setRemoveFiles([...removedFiles, img]);
                        setFileWarning(false);
                      }}
                    />
                  </div>
                  <img src={img} alt={`Uploaded-${img}`} />
                </div>
              ))}
              {videoFile && (
                <div className="upload-file">
                  <div className="delete-box">
                    <img
                      src={TemplateCardImg.srcClose_icon}
                      alt="delete-icon"
                      onClick={() => {
                        setVideoFile("");
                        setRemoveFiles([videoFile]);
                        setFileWarning(false);
                      }}
                    />
                  </div>
                  <video src={videoFile} />
                </div>
              )}
            </div>
            <input
              type="file"
              id="files-99"
              accept={
                postType === "image"
                  ? "image/jpeg, image/png, video/mp4, video/quicktime"
                  : "video/mp4, video/quicktime"
              }
              style={{ display: "none" }}
              multiple
              onChange={postType === "image" ? handleChange : handleVideoChange}
            />
          </div>
        </label>
      </div>
      {!hasPlan && <NoPlanPopupModel />}
    </>
  );
};

export default PublishPage;
