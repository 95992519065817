import axios from "axios";
import { baseUrl } from "./axiosInstance";

// api to get chatroom list by current users id
export const getChatRoomList = async (id: string) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/chat/get-chatroomList-by-memberId?memberId=${id}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to get messages of a chatroom by its room id
export const getChatRoomMsg = async ({
  chatroomId,
  currentMemberId,
  skipCount,
}: {
  chatroomId: string;
  currentMemberId: string;
  skipCount: number;
}) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/chat/get-Chatroom-Messages?chatroomId=${chatroomId}&currentMemberId=${currentMemberId}&skipCount=${skipCount}&maxResultCount=15`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to send message
export const postSendMessage = async (formData: FormData) => {
  try {
    const {
      data: { data },
    } = await axios.post(`${baseUrl}/app/chat/send-Message`, formData, {
      headers: { "Content-type": "multipart/form-data" },
    });

    return data;
  } catch (error) {
    return error;
  }
};

// api to get chatroom ids or create if there is no chatroom yet
export const postGetOrCreateRoomId = async ({
  senderId,
  receiverId,
}: {
  senderId: string;
  receiverId: string;
}) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${baseUrl}/app/chat/getOrCreate-Chatroom?senderId=${senderId}&receiverId=${receiverId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to mark all the messages in the chatroom as status of read
export const postMsgIsRead = async ({
  chatroomId,
  memberId,
}: {
  chatroomId: string;
  memberId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/chat/update-IsRead-Messages?chatroomId=${chatroomId}&memberId=${memberId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to recall message
export const postUnsendMsg = async (messageId: string) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/chat/recall-message/${messageId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to delete message
export const postDeleteMsg = async ({
  messageId,
  currentMemberId,
}: {
  messageId: string;
  currentMemberId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/chat/delete-message-api?messageId=${messageId}&currentMemberId=${currentMemberId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};

// api to create and update connection id for signalR connection
export const postCreateUpdateConnectionId = async ({
  connectionId,
  isExistsInDb,
  memberId,
}: {
  connectionId: string;
  isExistsInDb: boolean;
  memberId: string;
}) => {
  try {
    const { data } = await axios.post(
      `${baseUrl}/app/signalRConnectionId/create-update-connectionId?memberId=${memberId}&connectionId=${connectionId}&IsExistsInDb=${isExistsInDb}`
    );

    data && console.log("connection ID is updated in db!");
    return data;
  } catch (error) {
    return error;
  }
};

// api to update Messges as isRead
export const postUpdateIsReadByMessageId = async (messageId: string) => {
  try {
    const {
      data: { data },
    } = await axios.post(
      `${baseUrl}/app/chat/update-IsRead-by-messageId?messageId=${messageId}`
    );

    return data;
  } catch (error) {
    return error;
  }
};
