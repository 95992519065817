import axios from "axios";
import { baseUrl } from "./axiosInstance";

// api to get status of maintenance
export const getStatusOfMaintenance = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${baseUrl}/app/websiteMaintenance/IsWebsiteOnMaintenance`
    );

    return data;
  } catch (error) {
    return error;
  }
};
