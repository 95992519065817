//type
import { TypeChatRoom } from "../../types/chats";
//context
import { useChat } from "../../context/ChatProvider";
//style
import "./ChatListCard.scss";

// render time gap between the current time and date of notification
function formatTimeDifference(timeString: string): string {
  const currentTime: Date = new Date();
  const eventTime: Date = new Date(timeString);
  const timeDiff: number = currentTime.getTime() - eventTime.getTime();

  const seconds: number = timeDiff / 1000;
  const minutes: number = seconds / 60;
  const hours: number = minutes / 60;
  const days: number = hours / 24;
  const weeks: number = days / 7;

  if (seconds < 5) {
    return `現在`;
  } else if (minutes <= 5) {
    return `剛剛`;
  } else if (hours < 1) {
    return `${Math.floor(minutes)} 分鐘前`;
  } else if (days < 1) {
    return `${Math.floor(hours)} 小時前`;
  } else if (days < 7) {
    return `${Math.floor(days)} 天前`;
  } else if (days < 30) {
    return `${Math.floor(weeks)} 周前`;
  } else {
    return `${
      timeString?.substring(0, 10)?.replaceAll("-", "/") +
      " " +
      timeString?.substring(11, 16)
    }`;
  }
}

interface ChatListCardProps {
  room: TypeChatRoom;
}

const ChatListCard: React.FC<ChatListCardProps> = (props) => {
  const {
    setSelectedRoomId,
    selectedRoomId,
    setReceiverId,
    setChat,
    setMessages,
    setFetchAgain,
    fetchAgain,
    setAllFiles,
    setStopScrolling,
  } = useChat();

  const minimizeString = (words: string) => {
    return `${words.slice(0, 23)}${words.length > 23 ? "..." : ""}`;
  };

  return (
    <div
      className={`chat-main-container ${
        props?.room?.unreadMessages && "unread"
      } ${props?.room?.chatroomId === selectedRoomId && "selected"}`}
      onClick={() => {
        // set up selected room
        setSelectedRoomId?.(props?.room?.chatroomId);
        // set up opponents id(receivers id)
        setReceiverId?.(props?.room?.oppositeUserId);
        setChat?.("");
        setMessages?.([]);
        setAllFiles?.([]);
        // to update status of chatroom list
        setFetchAgain?.(!fetchAgain);
        setStopScrolling?.(false);
      }}
    >
      <div className="speaker-pic">
        <img src={props?.room?.oppositeUserAvatar} alt="user-avatar" />
      </div>
      <div className="chat-main">
        <div className="speaker-name b-16">
          <span>{props?.room?.oppositeUserName}</span>
        </div>
        <div className="chat-content r-14">
          <span>
            {props?.room?.message && minimizeString(props?.room?.message)}
          </span>
        </div>
      </div>
      <span className="latest-date r-14">
        {formatTimeDifference(props?.room?.timeStamp)}
      </span>
    </div>
  );
};

export default ChatListCard;
